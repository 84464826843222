export default
{
  scheduleSucess: ['Agenda atualizada com sucesso!', 'Appointment book updated', 'Programa actualizado con éxito'],
  errorCreate: ['Algum erro ocorreu ao tenrar criar o encontro!', 'Something went wrong!', '¡Algún error ocurrió al intentar crear el encuentro!'],
  updateSucess: ['Agenda atualizada com sucesso!', 'Update successfully', '¡Agenda actualizada con éxito!'],
  deleteError: ['Algum erro ocorreu ao deletar o encontro!', 'Something wrent wrong while deleting the meeting', '¡Algún error ocurrió al suprimir el encuentro!'],
  schedMeeting: ['Agendar Próximo Encontro', 'Schedule next Meeting', 'Programación Siguiente Encuentro'],
  place: ['Local', 'Place', 'Local'],
  time: ['Horário', 'Time', 'Horario'],
  date: ['Data', 'Date', 'Fecha'],
  notScheduled: ['Próximo Encontro não agendado', 'Next meeting not scheduled', 'Siguiente Encuentro no programado'],
  scheduleMeeting: ['Agendar Próximo Encontro', 'Schedule next meeting', 'Marcar Siguiente Encuentro'],
  schedule: ['Agendar', 'Schedule', 'Marcar el'],
  update: ['Atualizar', 'Update', 'Actualizar'],
  nxtMeeting: ['Próximo Encontro', 'Next meeting', 'Siguiente encuentro'],
  cancel: ['Cancelar/Finalizar', 'Cancel', 'Cancelar']

}
