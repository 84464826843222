export default
{
  loadError: ['Erro ao carregar dados', 'Load Data Error', 'Error al cargar datos'],
  descLoadError: ['Algum erro ocorreu ao carregar os dados do cliente!', 'Some error occurred while loading client data!', 'Algún error ocurrió al cargar los datos del cliente!'],
  perfil: ['Perfil', 'Profile', 'Perfil'],
  color: ['Coloração Pessoal', 'Personal Coloring', 'Colores Personales'],
  moodboard: ['MoodBoard', 'MoodBoard', 'MoodBoard'],
  closet: ['Closet', 'Closet', 'Armario Virtual'],
  list: ['Lista de Compras', 'Shopping list', 'Listado de Compras'],
  schedule: ['Agenda', 'Schedule', 'Programa'],
  settings: ['Configurações', 'Settings', 'Ajustes']
}
