import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import registerServiceWorker from './registerServiceWorker'

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'

import reducers from './redux/reducers'
import Router from './services/Router'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['adminReducer']
}

const store = createStore(persistReducer(persistConfig, reducers))
const persistor = persistStore(store)
// persistor.purge()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router />
    </PersistGate>
  </Provider>
  , document.getElementById('root'))

registerServiceWorker()
