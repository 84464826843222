import React, { Component } from 'react'
import { ButtonToolbar, Form, FormControl, FormGroup, Col, Button, Modal, ControlLabel } from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import rp from 'request-promise-native'
import moment from 'moment'
import { connect } from 'react-redux'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'

import * as actions from '../redux/actions'
import { uri } from '../services/defaultProps'
import { selectedUser } from '../services/selectors'

import text from '../constants/ConfigCliente'

import sendMessageWPP from '../services/sendMessageWPP'

class Config extends Component {
  constructor (props) {
    super(props)

    let clientMode = 'national'
    
    // COMING SOON
    // let clientMode = this.props.selectedUser.mode

    if (this.props.selectedUser.state === '') clientMode = 'foreign'
    if (this.props.selectedUser.phone.length > 11) clientMode = 'foreign'
    if (this.props.selectedUser.zipCode.length > 8) clientMode = 'foreign'

    if (clientMode === 'national') {
      var userPhone = (this.props.selectedUser.phone || '').replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/)
      var phoneString = !userPhone[2] ? userPhone[1] : '(' + userPhone[1] + ') ' + userPhone[2] + (userPhone[3] ? '-' + userPhone[3] : '')
    } else var phoneString = (this.props.selectedUser.phone || '')
    
    if (clientMode === 'national') {
      var userZipCode = (this.props.selectedUser.zipCode || '').replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/)
      var zipCodeString = !userZipCode[2] ? userZipCode[1] : userZipCode[1] + '-' + userZipCode[2]
    } else var zipCodeString = (this.props.selectedUser.zipCode || '')

    this.state = {
      showModal: false,
      newClient: {
        name: this.props.selectedUser.name,
        birth: this.props.selectedUser.birth,
        phone: phoneString,
        streetName: this.props.selectedUser.streetName,
        streetNumber: this.props.selectedUser.streetNumber || '',
        complement: this.props.selectedUser.complement || '',
        zipCode: zipCodeString,
        city: this.props.selectedUser.city || '',
        state: this.props.selectedUser.state
      },
      loading: false,
      loadingDelete: false,
      loadingInvitation: false,
      deleteConfirmText: '',
      language: 1,
      clientMode: clientMode
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') - '0' })
  }

  handleModal = () => { this.setState({ showModal: !this.state.showModal }) }

  handleUpdate = () => {
    const { clientMode } = this.state

    if (this.state.loading) return

    if (!this.state.newClient.name ||
      !this.state.newClient.streetName ||
      !this.state.newClient.streetNumber ||
      !this.state.newClient.city ||
      (clientMode === 'national' && !this.state.newClient.state) ||
      !this.state.newClient.zipCode ||
      !this.state.newClient.phone) {
      toast(text.fillFields[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    const body = {
      ...this.state.newClient,
      birth: moment.utc(this.state.newClient.birth).format('YYYY-MM-DD'),
      phone: this.state.newClient.phone.replace(/\D+/g, ''),
      zipCode: this.state.newClient.zipCode.replace(/\D+/g, '')
    }

    this.setState({ loading: true })
    rp({
      uri: `${uri}/user?id=${this.props.selectedUser.id}`,
      method: 'PATCH',
      headers: {
        Authorization: this.props.token
      },
      body: body,
      json: true
    })
      .then(updatedUser => {
        this.props.setUserAttribute(this.props.selectedUser.id, updatedUser)
        this.setState({ loading: false })
        toast(text.updateSucess[this.state.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error when updating user config', err)
        this.setState({ loading: false })
        toast(text.someError[this.state.language], {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
  }

  handleDelete = () => {
    if (this.props.selectedUser.name.toLowerCase() !== this.state.deleteConfirmText.toLowerCase()) {
      toast(text.writeName[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    this.setState({ loadingDelete: true })
    rp({
      uri: `${uri}/user`,
      method: 'DELETE',
      headers: {
        Authorization: this.props.token
      },
      body: {
        userId: this.props.selectedUser.id
      },
      json: true
    })
      .then(() => {
        this.setState({ loading: false })
        this.props.removeUser(this.props.selectedUser.id)
        toast(text.deleteSucess[this.state.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
        setTimeout(() => this.props.history.push('/'), 500)
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error when deleting user', err)
        this.setState({ loading: false })
      })
  }

  handleFormAttribute = (e, attribute) => {
    const obj = this.state.newClient
    obj[attribute] = e.target.value
    this.setState({ obj })
  }

  handleFormPhone = (value) => {
    const { clientMode } = this.state

    if (clientMode === 'national') {
      const x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/)

      this.setState((state) => ({
        newClient: {
          ...state.newClient, phone: !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
        }
      }))
    } else {
      this.setState((state) => ({
        newClient: {
          ...state.newClient,
          phone: value
        }
      }))
    }
  }

  handleFormZipCode = (value) => {
    const { clientMode } = this.state

    if (clientMode === 'national') {
      const x = value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/)

      this.setState((state) => ({
        newClient: {
          ...state.newClient, zipCode: !x[2] ? x[1] : x[1] + '-' + x[2]
        }
      }))
    } else {
      this.setState((state) => ({
        newClient: {
          ...state.newClient,
          zipCode: value
        }
      }))
    }
    
  }

  handleClientMode(mode) {
    this.setState({
      clientMode: mode
    }, () => {
      this.handleFormZipCode(this.state.newClient.zipCode)
      this.handleFormPhone(this.state.newClient.phone)
    })
  }

  handleDeleteConfirmText = (e) => {
    this.setState({ deleteConfirmText: e.target.value })
  }

  sendInvitationByWhatsApp = () => {
    const { name, email, phone } = this.props.selectedUser
    const invitationMessage = (link) => ([
      `${name}, nesta mensagem tem o link para você criar sua senha e instalar o aplicativo MOSTY que vamos utilizar na sua Consultoria de Imagem. O seu email para fazer login é ${email}, depois de criar sua senha basta você adicionar o aplicativo na tela inicial e curtir.\n\n${link}`,
      `${name}, this message has the link for you to create your password and install the MOSTY application that we will use in your Image Consulting. Your login email is ${email}, after you create your password just add the app on the home screen and enjoy.\n\n${link}`,
      `${name}, este mensaje tiene el enlace para que crees tu contraseña e instales la aplicación MOSTY que usaremos en tu Asesoría de imagen. Su correo electrónico de inicio de sesión es ${email}, después de crear su contraseña, simplemente agregue la aplicación en la pantalla de inicio y disfrute.\n\n${link}`
    ])

    rp({
      uri: `${uri}/auth/reset/request`,
      method: 'POST',
      body: {
        email: this.props.selectedUser.email,
        mode: 'WHATSAPP'
      },
      json: true
    }).then(({link}) => {
      sendMessageWPP(phone, invitationMessage(link)[this.state.language])
      this.setState({loadingInvitation: false})
    }).catch(() => {
      toast(text.wppError[this.state.language], {
        type: toast.TYPE.INFO,
        position: toast.POSITION.BOTTOM_RIGHT
      })

      this.setState({loadingInvitation: false})
    })

    this.setState({loadingInvitation: true})
  }

  render () {
    const { clientMode } = this.state

    if (this.state.loading) {
      return (
        <div id='centralSpinnerHolder'>
          <Loader
            type='TailSpin'
            color='#000'
            height='50'
            width='50'
          />
        </div>
      )
    }

    const birthDate = new Date(this.state.newClient.birth)
    const correctedBirthDay = new Date(birthDate.getTime() + birthDate.getTimezoneOffset() * 60000)
    const { loadingInvitation } = this.state;

    return (
      <Form id='clientConfig' horizontal onSubmit={(e) => e.preventDefault()} style={{ padding: 20 }}>
        {this.state.loadingAddClient
          ? <div id='centralSpinnerHolder'>
            <Loader
              type='TailSpin'
              color='#000'
              height='50'
              width='50'
            />
          </div>
          : <div>
            <FormGroup>
            <ButtonToolbar>
              <Button bsStyle="success" disabled={clientMode === 'national'} onClick={() => this.handleClientMode('national')}>
                {text.national[this.state.language]}
              </Button>
              <Button bsStyle="danger" disabled={clientMode === 'foreign'} onClick={() => this.handleClientMode('foreign')}>
                {text.foreign[this.state.language]}
              </Button>
            </ButtonToolbar>
            </FormGroup>
            <FormGroup controlId='addNome'>
              <ControlLabel>{text.name[this.state.language]} *</ControlLabel>
              <FormControl type='text' placeholder='Nome' onChange={(e) => this.handleFormAttribute(e, 'name')} value={this.state.newClient.name} />
            </FormGroup>
            <FormGroup controlId='addEmail'>
              <ControlLabel>E-mail *</ControlLabel>
              <FormControl readOnly type='email' value={this.props.selectedUser.email} />
            </FormGroup>
            <ControlLabel>{text.adress[this.state.language]} *</ControlLabel>{' '}
            <FormGroup controlId='addEndereco'>
              <Col sm={8}>
                <FormControl type='text' placeholder={text.street[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'streetName')} value={this.state.newClient.streetName} />
              </Col>
              <Col sm={4}>
                <FormControl type='text' placeholder={text.number[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'streetNumber')} value={this.state.newClient.streetNumber} />
              </Col>
            </FormGroup>
            <FormGroup controlId='addEndereco'>
              <Col sm={12}>
                <FormControl type='text' placeholder={text.complement[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'complement')} value={this.state.newClient.complement} />
              </Col>
            </FormGroup>
            <FormGroup controlId='addEndereco'>
              <Col sm={5}>
                <FormControl type='text' placeholder={text.city[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'city')} value={this.state.newClient.city} />
              </Col>
              <Col sm={5}>
                <FormControl type='text' placeholder={text.zip[this.state.language]} onChange={(e) => this.handleFormZipCode(e.target.value)} value={this.state.newClient.zipCode} />
              </Col>
              <Col sm={2}>
                {
                  clientMode === 'foreign' ? 
                  <FormGroup hidden={this.state.language === 0 ? 0 : 1}>
                    <FormControl type='text' placeholder={text.state[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'state')} value={this.state.newClient.state}/>
                  </FormGroup> :
                  <FormGroup controlId='addEndereco' hidden={this.state.language === 0 ? 0 : 1}>
                    <FormControl componentClass='select' placeholder='Estado' onChange={(e) => this.handleFormAttribute(e, 'state')} value={this.state.newClient.state}>
                      <option value='AC'>AC</option>
                      <option value='AL'>AL</option>
                      <option value='AP'>AP</option>
                      <option value='AM'>AM</option>
                      <option value='BA'>BA</option>
                      <option value='CE'>CE</option>
                      <option value='DF'>DF</option>
                      <option value='ES'>ES</option>
                      <option value='GO'>GO</option>
                      <option value='MA'>MA</option>
                      <option value='MT'>MT</option>
                      <option value='MS'>MS</option>
                      <option value='MG'>MG</option>
                      <option value='PA'>PA</option>
                      <option value='PB'>PB</option>
                      <option value='PR'>PR</option>
                      <option value='PE'>PE</option>
                      <option value='PI'>PI</option>
                      <option value='RJ'>RJ</option>
                      <option value='RN'>RN</option>
                      <option value='RS'>RS</option>
                      <option value='RO'>RO</option>
                      <option value='RR'>RR</option>
                      <option value='SC'>SC</option>
                      <option value='SP'>SP</option>
                      <option value='SE'>SE</option>
                      <option value='TO'>TO</option>
                    </FormControl>
                  </FormGroup>
                }
              </Col>
            </FormGroup>
            <FormGroup controlId='addAniversario'>
              <ControlLabel>{text.birthday[this.state.language]}</ControlLabel><br />
              <DatePicker value={correctedBirthDay} onChange={(birth) => this.setState({ newClient: { ...this.state.newClient, birth } })} />
            </FormGroup>
            <FormGroup controlId='addCell'>
              <ControlLabel>{text.cell[this.state.language]}</ControlLabel>
              <FormControl type='text' placeholder='(99) 99999-9999' onChange={(e) => this.handleFormPhone(e.target.value)} value={this.state.newClient.phone} />
            </FormGroup>
            <ButtonToolbar>
              <Button bsStyle='danger' onClick={this.handleModal}>{text.deleteClient[this.state.language]}</Button>
              <Button bsStyle='primary' type='submit' onClick={this.handleUpdate}>{text.update[this.state.language]}</Button>
              <Button bsStyle='success' disabled={loadingInvitation} onClick={!loadingInvitation ? this.sendInvitationByWhatsApp : null}>
                {loadingInvitation ? text.sendingInvite[this.state.language] : text.sendInvite[this.state.language]}
              </Button>
            </ButtonToolbar>
            <Modal show={this.state.showModal} onHide={this.handleModal}>
              <Modal.Header closeButton>
                <Modal.Title>{text.deleteClient[this.state.language]}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{text.deleteConfirm[this.state.language]} <strong>{this.props.selectedUser.name}</strong> {text.deleteConfirm2[this.state.language]} </p>
                <p>{text.enterName[this.state.language]}</p>
                <FormGroup controlId='addCell'>
                  <FormControl type='text' placeholder={this.props.selectedUser.name} onChange={this.handleDeleteConfirmText} value={this.state.deleteConfirmText} />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                {this.state.loadingDelete
                  ? <div id='centralSpinnerHolder'>
                    <Loader
                      type='TailSpin'
                      color='#000'
                      height='50'
                      width='50'
                    />
                  </div>
                  : <Button bsStyle='danger' onClick={this.handleDelete}>{text.deleteClient[this.state.language]}</Button>}
              </Modal.Footer>
            </Modal>
          </div>}
      </Form>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer,
    ...state.usersReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(Config)
