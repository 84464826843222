export default
{
  sizeError: ['O arquivo enviado é maior que 10MB! Comprima o mesmo para que fique abaixo do limite.', 'The uploaded file is larger than 10mb! Please compress to make it below the limit.', 'El archivo enviado es mayor que 10MB! Comprima el mismo para que quede por debajo del límite.'],
  error: ['Erro', 'Error', 'Error'],
  wrongFormat: ['Formato inválido! Utilize imagens em formato .JPG ou .PNG', 'Invalid format! Use images in .JPG or .PNG format'],
  updatedSuc: ['Closet atualizado com sucesso!', 'Closet updated successfully', '¡Closet actualizado con éxito!'],
  uploadError: ['Algum erro ocorreu ao fazer upload da imagem!', 'Some error occurred while uploading the image', '¡Algún error ocurrió al subir la imagen!'],
  deleteSuc: ['Imagem excluída com sucesso!', 'Image deleted successfully!', 'Imagen eliminada con éxito!'],
  deleteAllSuc: ['Imagens excluídas com sucesso!', 'Images deleted successfully!', 'Imágenes eliminadas con éxito!'],
  description1: ['Esse espaço irá armazenar as fotos de todos os looks criados com a cliente.', 'This space will store the photos of all the looks created with the client.', 'Este espacio guardará las fotos de todos los looks creado para tu cliente.'],
  description2: ['Caso queira colocar no template da sua empresa, é possível só não esqueça de salvar a imagem na vertical, em formato JPG', 'If you want to save in the template of your company, it is possible just do not forget to save the image vertically, in JPG format', 'Si deseas poner la plantilla de tu empresa, deberás guardar la imagen en posición vertical y en formato JPG.'],
  noPhoto: ['Não há fotos no Closet Virtual do Cliente', "There are no photo in the client's Virtual Closet", 'No hay fotos en el Armario Virtual del Cliente'],
  confim: ['Confirmar', 'Confirm', 'Confirmar'],
  deleteConfirm: ['Tem certeza que deseja excluir essa foto? Esta ação não pode ser desfeita.', 'Are you sure you want to delete this picture? This action can not be undone.', '¿Está seguro de que desea eliminar esta foto? Esta acción no se puede deshacer.'],
  deleteAllConfirm: ['Tem certeza que deseja excluir essas fotos? Esta ação não pode ser desfeita.', 'Are you sure you want to delete these pictures? This action can not be undone.', '¿Está seguro de que desea eliminar estas fotos? Esta acción no se puede deshacer.'],
  delete: ['Excluir Imagem', 'Delete Image', 'Eliminar Imagen'],
  deleteAll: ['Excluir Imagens', 'Delete Images', 'Eliminar Imágenes']
}
