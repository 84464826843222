const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV 


export const uri = ENV === 'production'
  ? 'https://mosty-gateway-7txfdm6rba-uw.a.run.app/api/v1'
  : 'https://mosty-gateway-dev-7txfdm6rba-uw.a.run.app/api/v1'
  //: 'http://localhost:3000/api/v1'

export const uriV2 = ENV === 'production'
  ? 'https://mosty-gateway-7txfdm6rba-uw.a.run.app/api/v2'
  : 'https://mosty-gateway-dev-7txfdm6rba-uw.a.run.app/api/v2'
  //: 'http://localhost:3000/api/v1'

console.log('ENVIRONMENT = ', ENV)

export const auth = {
  unverified: 5,
  user: 10,
  waitingConsultant: 25,
  consultant: 50,
  admin: 100
}

export const maxImageSize = 10
export const maxDocumentSize = 10

export const s3Bucket = ENV === 'production' ? 'mosty' : 'mostydev'
export const s3Region = ENV === 'production' ? 'sa-east-1' : 'us-west-2'

export const freeMonthCoupons = ENV === 'production' 
  ? ['AICI', 'MOSTYON', 'LAISMACHADO', 'BRUNELLA'] 
  : ['MOSTY1MESGRATIS']