import React, { Component } from 'react'
import { Modal, FormControl, ControlLabel, HelpBlock, ToggleButton, ToggleButtonGroup, Alert, FormGroup, Button } from 'react-bootstrap'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import rp from 'request-promise-native'
import Loader from 'react-loader-spinner'
import Lightbox from 'react-image-lightbox'
import { toast } from 'react-toastify'
import 'react-image-lightbox/style.css'

import * as actions from '../redux/actions'
import { uri, maxImageSize } from '../services/defaultProps'
import { selectedUser } from '../services/selectors'

import translations from '../constants/ListaDeCompras'

class ListaDeCompras extends Component {
  constructor (props) {
    super(props)
    this.state = {
      render: 1,
      isLightBoxOpen: 0,
      error: 0,
      imageToUpload: null,
      textToUpload: '',
      loadingUpload: false,
      deletingImage: null,
      loadingDelete: false,
      language: 1
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') })
  }

  handleRender = (value) => {
    this.setState({ render: value })
  }

  alertError = () => {
    switch (this.state.error) {
      case 1:
        return (
          <Alert bsStyle='danger' onDismiss={() => this.setState({ error: 0 })}>
            <p>Faça o Upload do arquivo para Atualizar a Lista de Compras do Usuário! (Error Message Test)</p>
          </Alert>
        )
    }
  }

  showError = () => { this.setState({ error: 1 }) }

  handleModal = (id) => {
    this.setState({ deletingImage: id })
  }

  handleUploadImage = (event) => {
    const imageToUpload = event.target.files[0]

    if (!imageToUpload) return

    // Size in MB
    const size = imageToUpload.size / (1024 * 1024)
    if (size > maxImageSize) {
      toast(translations.sizeError[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    const type = imageToUpload.type.split('/').pop()

    // Unwanted type
    if (!['jpeg', 'png'].includes(type)) {
      this.props.setError(translations.error[this.state.language], translations.wrongFormat[this.state.language])
      return
    }
    this.setState({ imageToUpload })
  }

  handleTextChange = (e) => {
    this.setState({ textToUpload: e.target.value })
  }

  handleUpdateClick = () => {
    if (this.state.imageToUpload) {
      this.setState({ loadingUpload: true })
      fetch(`${uri}/media/shoplist/image?id=${this.props.selectedUser.id}`, {
        method: 'POST',
        headers: {
          'content-type': this.state.imageToUpload.type,
          Authorization: this.props.token
        },
        body: this.state.imageToUpload
      })
        .then(res => {
          if (!res.ok && res.status === 401) {
            this.props.setToken(null)
            return res.json().then((err) => Promise.reject(err)) // eslint-disable-line prefer-promise-reject-errors
          } else {
            return res
          }
        })
        .then(res => res.json())
        .then(shopList => {
          this.props.setUserMediaAttribute(this.props.selectedUser.id, shopList)
          this.setState({ loadingUpload: false, imageToUpload: null })
        })
        .catch(err => {
          console.log('Error posting shoplist image', err)
          this.setState({ loadingUpload: false, imageToUpload: null })
          toast(translations.uploadError[this.state.language], {
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
    }
  }

  handleDeleteClick = () => {
    if (this.state.deletingImage) {
      this.setState({ loadingDelete: true })
      rp({
        uri: `${uri}/media/shoplist/image`,
        method: 'DELETE',
        headers: {
          Authorization: this.props.token
        },
        body: {
          id: this.state.deletingImage
        },
        json: true
      })
        .then(shopList => {
          this.props.setUserMediaAttribute(this.props.selectedUser.id, {
            shopListImage: this.props.selectedUser.media.shopListImage.filter(image => image.id !== this.state.deletingImage)
          })
          this.setState({ loadingDelete: false, deletingImage: null })
          toast(translations.updatedSuc[this.state.language], {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.props.setToken(null)
          }
          throw err
        })
        .catch(err => {
          console.log('Error deleting shoplist image', err)
          this.setState({ loadingDelete: false, deletingImage: null })
          this.props.setError(translations.error[this.state.language], translations.deleteError[this.state.language])
        })
    }
  }

  handleUpdateText = () => {
    console.log('this.state.textToUpload', this.state.textToUpload)
    this.setState({ loadingUpload: true })
    rp({
      uri: `${uri}/media/shoplist/text?id=${this.props.selectedUser.id}`,
      method: 'POST',
      headers: {
        Authorization: this.props.token
      },
      body: {
        text: this.state.textToUpload
      },
      json: true
    })
      .then(shopList => {
        console.log('shopList', shopList)
        this.props.setUserMediaAttribute(this.props.selectedUser.id, shopList)
        this.setState({ loadingUpload: false, textToUpload: '' })
        toast(translations.updatedSuc[this.state.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error posting shoplist text', err)
        this.setState({ loadingUpload: false })
        this.props.setError(translations.error[this.state.language], translations.textError[this.state.language])
      })
  }

  imageOrText = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => this.setState({ page: index })
    }
    const pictures = this.props.selectedUser.media.shopListImage
    const text = this.props.selectedUser.media.shopListText

    switch (this.state.render) {
      case 1:
        return <div id='perfilImage'>
          <Slider {...settings}>
            {pictures.length ? pictures.map((item, index) => <div key={index} className='sliderItem'>
              <img src={item.image} id='sliderImage' alt={`Imagem ${index}`} /> <br />
              <Button bsStyle='danger' bsSize='small' className='delFoto' onClick={() => this.handleModal(item.id)}>{translations.delete[this.state.language]}</Button>
            </div>)
              : <div><h3>{translations.noPhoto[this.state.language]}</h3></div>}
          </Slider>
          {this.state.loadingUpload
            ? <div id='centralSpinnerHolderWithLessPadding'>
              <Loader
                type='TailSpin'
                color='#000'
                height='50'
                width='50'
              />
            </div>
            : <div id='shopListForm'>
              <input type='file' accept='image/*' onChange={this.handleUploadImage} multiple />
              <Button
                type='submit'
                bsStyle='primary'
                onClick={this.handleUpdateClick}
                disabled={!this.state.imageToUpload}
              > Upload
              </Button>
            </div>}
        </div>
      case 2:
        return <div>
          <div id='perfilText'>
            {text ? <div id='currentText'><h4>{translations.currentText[this.state.language]}</h4><br />{`${text}`}</div> : ''}
            <FormGroup controlId='formControlsTextarea'>
              <ControlLabel id='textAreaLabel'>{translations.text[this.state.language]}</ControlLabel>
              <FormControl className='textArea' componentClass='textarea' placeholder={translations.listExample[this.state.language]} onChange={this.handleTextChange} value={this.state.textToUpload} />
            </FormGroup>
          </div>
          {this.state.loadingUpload
            ? <div id='centralSpinnerHolderWithLessPadding'>
              <Loader
                type='TailSpin'
                color='#000'
                height='50'
                width='50'
              />
            </div>
            : <Button type='submit' id='formSubmit' onClick={this.handleUpdateText}>{translations.updateList[this.state.language]}</Button>}
        </div>
    }
  }

  render () {
    console.log('this.state.imageToUpload', this.state.imageToUpload)
    return (
      <form className='clientForm' onSubmit={(e) => e.preventDefault()}>
        <div style={{ width: '90%', margin: 'auto', marginTop: 30 }}>
          <Alert bsStyle='info'>
            <div style={{ textAlign: 'left', paddingHorizontal: 10 }}>
              <strong>{translations.desc1[this.state.language]}</strong>
              <div>{translations.desc2[this.state.language]}</div>
              <ol>
                <li>{translations.desc3[this.state.language]}</li>
                <li>{translations.desc4[this.state.language]}</li>
              </ol>
            </div>
          </Alert>
        </div>
        <FormGroup id='radioTextOrImage'>
          <ToggleButtonGroup type='radio' name='textOrImage' defaultValue={1} onChange={this.handleRender}>
            <ToggleButton value={1}>{translations.image[this.state.language]}</ToggleButton>
            <ToggleButton value={2}>{translations.text[this.state.language]}</ToggleButton>
          </ToggleButtonGroup>
        </FormGroup>
        {this.imageOrText()}
        {this.state.isLightBoxOpen
          ? <Lightbox mainSrc={this.props.currentPerfil.image} onCloseRequest={() => this.setState({ isLightBoxOpen: 0 })} />
          : ''}
        {this.alertError()}
        <HelpBlock>{translations.choose[this.state.language]}</HelpBlock>
        <Modal show={this.state.deletingImage !== null} onHide={() => this.handleModal(null)}>
          <Modal.Header closeButton>
            <Modal.Title>{translations[this.state.language]}</Modal.Title>
          </Modal.Header>
          {this.state.loadingDelete
            ? <div id='centralSpinnerHolderWithLessPadding'>
              <Loader
                type='TailSpin'
                color='#000'
                height='50'
                width='50'
              />
            </div>
            : <Modal.Body>
              <p>{translations.confirmRemotion[this.state.language]}</p>
            </Modal.Body>}
          <Modal.Footer>
            <Button bsStyle='danger' onClick={this.handleDeleteClick}>{translations.deleteImage[this.state.language]}</Button>
          </Modal.Footer>
        </Modal>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.usersReducer,
    ...state.adminReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(ListaDeCompras)
