import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'

import * as actions from '../redux/actions'

import Interface from '../containers/Interface'
import Configuracoes from './ConfiguracoesConsultant'

class Consultants extends Component {
  render () {
    return (
      <Interface>
        <Grid>
          <Row>
            <Col xs={12} sm={12} md={10} mdOffset={1} className='text-center'>
              <Configuracoes userId={this.props.match.params.id} history={this.props.history} />
            </Col>
          </Row>
        </Grid>
      </Interface>
    )
  }
}

export default connect(null, actions)(Consultants)
