import React, { Component } from 'react'

import { ToggleButton, ToggleButtonGroup, Panel, Grid, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import rp from 'request-promise-native'
import Loader from 'react-loader-spinner'

import * as actions from '../redux/actions'

import Interface from '../containers/Interface'
import Perfil from './Perfil.js'
import Coloracao from './Coloracao.js'
import MoodBoard from './Moodboard'
import Closet from './Closet.js'
import ListaDeCompras from './ListaDeCompras.js'
import Agenda from './Agenda'
import Drive from './Drive'
import Configuracoes from './ConfiguracoesCliente'
import { uri } from '../services/defaultProps'
import { selectedUser } from '../services/selectors'

import text from '../constants/Cliente'

class Cliente extends Component {
  constructor (props) {
    super(props)
    this.state = {
      renderValue: 1,
      userId: this.props.match.params.id,
      loadingMedia: true,
      language: 1
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') })
  }

  componentDidMount () {
    // Load everything about this user!
    rp({
      uri: `${uri}/user?id=${this.state.userId}`,
      method: 'GET',
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(async user => {
        if (!user) {
          this.props.history.push('/')
          return
        }

        const profileResponse = rp({
          uri: `${uri}/media/profile?id=${this.state.userId}`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })

        const profileImageResponse = rp({
          uri: `${uri}/media/profile/image?id=${this.state.userId}`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })

        const personalColorResponse = rp({
          uri: `${uri}/media/personalcoloring/image?id=${this.state.userId}`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })

        const closetResponse = rp({
          uri: `${uri}/media/closet?id=${this.state.userId}`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })

        const moodboardResponse = rp({
          uri: `${uri}/media/moodboard?id=${this.state.userId}`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })

        const shopListResponse = rp({
          uri: `${uri}/media/shoplist?id=${this.state.userId}`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })

        const userDriveResponse = rp({
          uri: `${uri}/media/drive?userId=${this.state.userId}`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })
        const [profile, userProfile, personalColor, userCloset, moodboard, shopList, userDrive] = await Promise.all([
          profileResponse,
          profileImageResponse,
          personalColorResponse,
          closetResponse,
          moodboardResponse,
          shopListResponse,
          userDriveResponse
        ])

        console.log('personalColor', personalColor)

        const reduxUser = this.props.users.find(({ id }) => parseInt(this.state.userId) === id)

        if (reduxUser && reduxUser.media && reduxUser.media.moodboard === 'uploading' && (moodboard.moodboard == null || moodboard.moodboard.length === 0)) {
          moodboard.moodboard = 'uploading'
        }

        this.props.putUser({
          ...user,
          media: {
            ...profile,
            ...userProfile,
            ...personalColor,
            ...shopList,
            ...userCloset,
            ...moodboard,
            ...userDrive
          }
        })
        // this.props.putUser(user)
        this.props.setSelectedUserId(user.id)

        this.setState({ loadingMedia: false })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error loading media', err)
        this.setState({ loadingMedia: false })
        this.props.setError(text.loadError[this.state.language], text.descLoadError[this.state.language])
      })
  }

  handleRender = (render) => {
    this.setState({ renderValue: render })
  }

  content = () => {
    if (this.state.loadingMedia) {
      return (
        <div id='centralSpinnerHolder'>
          <Loader
            type='TailSpin'
            color='#000'
            height='50'
            width='50'
          />
        </div>
      )
    }
    console.log('selectedUser', this.props.selectedUser)
    if (!this.props.selectedUser || !this.props.selectedUser.media) {
      return (<div />)
    }

    switch (this.state.renderValue) {
      case 1:
        return (<Perfil />)
      case 2:
        return (<Coloracao />)
      case 3:
        return (<MoodBoard />)
      case 4:
        return (<Closet />)
      case 5:
        return (<ListaDeCompras />)
      case 6:
        return (<Agenda />)
      case 7:
        return (<Drive />)
      case 8:
        return (<Configuracoes history={this.props.history} />)
    }
  }

  render () {
    return (
      <Interface>
        <Grid>
          <Row>
            <Col xs={12} sm={12} md={10} mdOffset={1} className='text-center'>
              <Panel id='clientPanel'>
                <Panel.Heading>
                  <h4>{this.props.selectedUser ? this.props.selectedUser.name : ''}</h4>
                  <ToggleButtonGroup onChange={this.handleRender} bsSize='sm' type='radio' name='options' defaultValue={1}>
                    <ToggleButton value={1}>{text.perfil[this.state.language]}</ToggleButton>
                    <ToggleButton value={2}>{text.color[this.state.language]}</ToggleButton>
                    <ToggleButton value={3}>{text.moodboard[this.state.language]}</ToggleButton>
                    <ToggleButton value={4}>{text.closet[this.state.language]}</ToggleButton>
                    <ToggleButton value={5}>{text.list[this.state.language]}</ToggleButton>
                    <ToggleButton value={6}>{text.schedule[this.state.language]}</ToggleButton>
                    <ToggleButton value={7}>Drive</ToggleButton>
                    <ToggleButton value={8}>{text.settings[this.state.language]}</ToggleButton>
                  </ToggleButtonGroup>
                </Panel.Heading>
                {this.content()}
              </Panel>
            </Col>
          </Row>
        </Grid>
      </Interface>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.usersReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(Cliente)
