import React, { Component } from 'react'
import Slider from 'react-slick'
import { Button, Modal, Alert } from 'react-bootstrap'

import { connect } from 'react-redux'
import rp from 'request-promise-native'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'

import * as actions from '../redux/actions'
import { uri, maxImageSize } from '../services/defaultProps'
import { selectedUser } from '../services/selectors'

import text from '../constants/Closet'

class Closet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 0,
      imagesToUpload: [],
      deletingImage: null,
      loadingUpload: false,
      loadingDelete: false,
      language: 1
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') })
  }

    handleModal = (ids) => {
      this.setState({ deletingImage: ids })
    }

    handleUploadImage = (event) => {
      const imagesToUpload = []
      for (let i = 0; i < event.target.files.length; i++) {
        const imageToUpload = event.target.files[i]
        const type = imageToUpload.type.split('/').pop()
        // Size in MB
        const size = imageToUpload.size / (1024 * 1024)
        if (size > maxImageSize) {
          toast(text.sizeError[this.state.language], {
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT
          })
          return
        }
        // Unwanted type
        if (!['jpeg', 'png'].includes(type)) {
          this.props.setError(text.error[this.state.language], text.wrongFormat[this.state.language])
          return
        }

        imagesToUpload.push(imageToUpload)
      }

      this.setState({ imagesToUpload })
    }

    handleUpdateClick = () => {
      if (this.state.imagesToUpload.length > 0) {
        this.setState({ loadingUpload: true })

        Promise.all(this.state.imagesToUpload.map(imageToUpload => {
          return fetch(`${uri}/media/closet?id=${this.props.selectedUser.id}`, {
            method: 'POST',
            headers: {
              'content-type': imageToUpload.type,
              Authorization: this.props.token
            },
            body: imageToUpload
          })
            .then(res => {
              if (!res.ok && res.status === 401) {
                this.props.setToken(null)
                return res.json().then((err) => Promise.reject(err)) // eslint-disable-line prefer-promise-reject-errors
              } else {
                return res
              }
            })
            .then(res => res.json())
            .then(userCloset => {
              this.props.setUserMediaAttribute(this.props.selectedUser.id, userCloset)
              toast(text.updatedSuc[this.state.language], {
                type: toast.TYPE.INFO,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
        }))
          .then(() => {
            this.setState({ loadingUpload: false, imagesToUpload: [] })
          })
          .catch(err => {
            console.log('Error posting closet image', err)
            this.setState({ loadingUpload: false, imagesToUpload: [] })
            toast(text.uploadError[this.state.language], {
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_RIGHT
            })
          })
      }
    }

    handleDeleteClick = () => {
      if (this.state.deletingImage) {
        this.setState({ loadingDelete: true })

        rp({
          uri: `${uri}/media/closet`,
          method: 'DELETE',
          headers: {
            Authorization: this.props.token
          },
          body: {
            ids: this.state.deletingImage
          },
          json: true
        })
          .then(userCloset => {
            const deletingImage = this.state.deletingImage

            this.props.setUserMediaAttribute(this.props.selectedUser.id, {
              userCloset: this.props.selectedUser.media.userCloset.filter(closetImage => deletingImage.indexOf(closetImage.id) === -1)
            })

            this.setState({ loadingDelete: false, deletingImage: null })

            toast(
              deletingImage !== null && deletingImage.length === 1
                ? text.deleteSuc[this.state.language]
                : text.deleteAllSuc[this.state.language], {
                type: toast.TYPE.INFO,
                position: toast.POSITION.BOTTOM_RIGHT
              })
          })
          .catch(err => {
            if (err.statusCode === 401) {
              this.props.setToken(null)
            }
            throw err
          })
          .catch(err => {
            console.log('Error posting closet image', err)
            this.setState({ loadingDelete: false, deletingImage: null })
          })
      }
    }

    render () {
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => this.setState({ page: index })
      }
      const pictures = this.props.selectedUser.media.userCloset

      return (
        <div>
          <div style={{ width: '90%', margin: 'auto', marginTop: 30 }}>
            <Alert bsStyle='info'>
              <div style={{ textAlign: 'left', paddingHorizontal: 10 }}>
                <div>{text.description1[this.state.language]}</div>
                <div>{text.description2[this.state.language]}</div>
              </div>
            </Alert>
          </div>
          <Slider {...settings}>
            {pictures && pictures.length ? pictures.map((item, index) => <div key={index} className='sliderItem'>
              <img src={item.image} id='sliderImage' alt={`Imagem ${index}`} /> <br />
              <Button bsStyle='danger' bsSize='small' className='delFoto' onClick={() => this.handleModal([item.id])}>Deletar Foto</Button>
            </div>)
              : <div><h3>{text.noPhoto[this.state.language]}</h3></div>}
          </Slider>
          {pictures.length ? <p id='page'>Foto {this.state.page + 1} de {pictures.length}</p> : ''}
          <Modal show={this.state.deletingImage !== null} onHide={() => this.handleModal(null)}>
            <Modal.Header closeButton>
              <Modal.Title>{text.confim[this.state.language]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.loadingDelete
                ? <div id='centralSpinnerHolderWithLessPadding'>
                  <Loader
                    type='TailSpin'
                    color='#000'
                    height='50'
                    width='50'
                  />
                </div>

                : <p>
                  {
                    this.state.deletingImage !== null && this.state.deletingImage.length === 1
                      ? text.deleteConfirm[this.state.language]
                      : text.deleteAllConfirm[this.state.language]
                  }
                </p>}
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle='danger' onClick={this.handleDeleteClick}>
                {
                  this.state.deletingImage !== null && this.state.deletingImage.length === 1
                    ? text.delete[this.state.language]
                    : text.deleteAll[this.state.language]
                }
              </Button>
            </Modal.Footer>
          </Modal>
          {this.state.loadingUpload
            ? <div id='centralSpinnerHolderWithLessPadding'>
              <Loader
                type='TailSpin'
                color='#000'
                height='50'
                width='50'
              />
            </div>
            : <form id='closetForm' onSubmit={(e) => e.preventDefault()}>
              <input type='file' accept='image/*' onChange={this.handleUploadImage} multiple /> {/* UPLOAD MULTIPLE FILES!! */}
              <Button
                type='submit'
                bsStyle='primary'
                onClick={this.handleUpdateClick}
                disabled={this.state.imagesToUpload.length === 0}
              > Upload
              </Button>
            </form>}
          {
            pictures.length > 0 &&
              <div style={{ padding: '10px' }}>
                <Button
                  type='button'
                  bsStyle='primary'
                  onClick={() => this.handleModal(pictures.map(item => item.id))}
                >
                  Deletar Tudo
                </Button>
              </div>
          }
          <br />
        </div>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    ...state.usersReducer,
    ...state.adminReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(Closet)
