import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Navbar, Nav, NavDropdown, MenuItem, Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap'
import { BrowserRouter, Link } from 'react-router-dom'
import * as actions from '../redux/actions'
import Loader from 'react-loader-spinner'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import detectBrowserLanguage from 'detect-browser-language'

import rp from 'request-promise-native'
import { uri } from '../services/defaultProps'
import text from '../constants/Interface'

class Interface extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showConfig: false,
      fetchingReset: false,
      password1: '',
      password2: '',
      language: 1
    }
  }

  componentDidMount () {
    if (!localStorage.getItem('language')) {
      if (detectBrowserLanguage() === 'pt-BR' || detectBrowserLanguage() === 'pt-PT' || detectBrowserLanguage() === 'pt') {
        this.setState({ language: 0 })
        localStorage.setItem('language', '0')
      }
      if (detectBrowserLanguage().slice(0, 1) === 'es') {
        this.setState({ language: 2 })
        localStorage.setItem('language', '2')
      }
    } else {
      this.setState({ language: localStorage.getItem('language') - '0' })
    }
  }

  setSpanish = () => {
    this.setState({ language: 2 })
    localStorage.setItem('language', '2')
    window.location.reload()
  }

  setPt = () => {
    this.setState({ language: 0 })
    localStorage.setItem('language', '0')
    window.location.reload()
  }

  setEn = () => {
    this.setState({ language: 1 })
    localStorage.setItem('language', '1')
    window.location.reload()
  }

  handleShowConfig = () => {
    this.setState({ showConfig: !this.state.showConfig })
  }

  handleLogout = () => {
    this.props.setAdmin(null)
    this.props.setToken(null)
  }

  handlePassword1 = (event) => this.setState({ password1: event.target.value })
  handlePassword2 = (event) => this.setState({ password2: event.target.value })
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.requestPasswordChange()
    }
  }

  requestPasswordChange = () => {
    if (this.state.password1 !== this.state.password2) {
      return // Error
    }

    this.setState({ fetchingReset: true })

    rp({
      uri: `${uri}/auth/reset/submit`,
      method: 'POST',
      body: {
        password: this.state.password1
      },
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(() => {
        console.log('Success')
        this.setState({ fetchingReset: false })
        this.handleLogout()
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error on reset password', err)
        this.setState({ fetchingReset: false })
      })
  }

  dismissError = () => {
    this.props.dismissError()
  }

  render () {
    return (
      <BrowserRouter>
        <div>
          <ToastContainer />
          <Navbar collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link onClick={() => this.props.history.push('/')} to='/'><span id='header-title'>Mosty Painel</span></Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Nav pullRight>
              <div id='lang_selection-i'>
                <div id='lang_flag-i' onClick={this.setPt}><img src='/iconfinder_flag-brazil_748057.png' alt='Português' /></div>
                <div id='lang_flag-i' onClick={this.setEn}><img src='/iconfinder_flag-united-kingdom_748024.png' alt='English' /></div>
                <div id='lang_flag-i' onClick={this.setSpanish}><img src='/iconfinder_flag-spain_748120.png' alt='Español' /></div>
              </div>
            </Nav>
            <Navbar.Collapse>
              <Nav pullRight>
                <NavDropdown eventKey={3} title={`${text.hi[this.state.language]}, ${this.props.user.name || 'usuário'}`} id='basic-nav-dropdown' pullRight>
                  <MenuItem eventKey={3.1} onClick={this.handleShowConfig}>{text.settings[this.state.language]}</MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={3.4} onClick={this.handleLogout}>Logout</MenuItem>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {React.cloneElement(this.props.children, { language: this.state.language })}
          {this.props.error &&
            <div id='errorHolder'>
              <div id='errorClose' />
              <div id='errorContents'>
                <h4>{this.props.error.title}</h4>
                <h5 id='errorMessage'>{this.props.error.message}</h5>
              </div>
              <h3 id='errorClose' onClick={this.dismissError}>X</h3>
            </div>}
          <Modal show={this.state.showConfig} onHide={this.handleShowConfig}>
            <Modal.Header>
              <h3>{text.settings[this.state.language]}</h3>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                  <ControlLabel>{text.changePass[this.state.language]}</ControlLabel>
                  <FormControl type='password' className='newPassword' placeholder={text.newPass[this.state.language]} value={this.state.password1} onChange={this.handlePassword1} />
                  <FormControl type='password' className='newPassword' placeholder={text.repeatNewPass[this.state.language]} value={this.state.password2} onChange={this.handlePassword2} />
                </FormGroup>
                {!this.state.fetchingReset
                  ? <Button type='submit' bsStyle='primary' onClick={this.requestPasswordChange}>{text.update[this.state.language]}</Button>
                  : <Loader
                    type='TailSpin'
                    color='#000'
                    height='30'
                    width='30'
                  />}
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer
  }
}

export default connect(mapStateToProps, actions)(Interface)
