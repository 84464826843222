export default
{
  fillFields: ['Preencha todos os campos obrigatórios para adicionar o usuário!', 'Fill all required fields to add a new user!', '¡Llene todos los campos obligatorios para agregar el usuario!'],
  userAdded: ['Usuário adicionado com sucesso!', 'User added successfully!', 'Usuario agregado con éxito!'],
  emailRegisteredError: ['Já existe um usuário com o email informado!', 'There is already a user with the email informed!', 'Ya existe un usuario con el email informado!'],
  emailInvalidError: ['O email informado está inválido.', 'The email you entered is invalid.', 'El email que ingresó no es válido.'],
  emailNotExistsError: ['O email informado não existe.', 'The email you entered does not exist.', 'El email que ingresó no existe.'],
  someError: ['Algum erro ocorreu ao adicionar o usuário!', 'Some error occurred while adding the user ', '¡Algún error ocurrió al agregar el usuario!'],
  searchConsultant: ['Pesquisar Consultora', 'Search Consultant', 'Buscar Consultora'],
  searchClient: ['Pesquisar Cliente', 'Search Client', 'Buscar Cliente'],
  add: ['Adicionar', 'Add', 'Añadir'],
  export: ['Exportar', 'Export', 'Exportar'],
  consultant: ['Consultora', 'Consultant', 'Consultora'],
  cliente: ['Cliente', 'Client', 'Cliente'],
  select: ['Selecione um', 'Select a ', 'Seleccione un'],
  aConsultant: ['a consultora', ' consultant', 'a consultora'],
  clienteMin: [' cliente', 'client', ' cliente'],
  interect: [' para interagir', ' to interect', ' para intercactuar'],
  nVerified: [' (Não verificado)', ' (Not Verified)', ' (No verificado)'],
  noOne: ['Nenhum', 'No ', 'Ningún'],
  conFound: ['a Consultora Encontrada', 'consultant found', 'a consultora encontrada'],
  cltFound: [' Cliente Encontrado', 'client found', ' cliente encontrado'],
  name: ['Nome', 'Name', 'Nombre'],
  email: ['E-mail', 'E-mail', 'E-mail'],
  adress: ['Endereço', 'Adress', 'Dirección'],
  street: ['Rua *', 'Street *', 'Calle *'],
  number: ['Número *', 'Number *', 'Número *'],
  complement: ['Complemento', 'Complement', 'Complement'],
  city: ['Cidade *', 'City *', 'Ciudad *'],
  state: ['Estado', 'State', 'Estado'],
  zip: ['CEP *', 'ZIP *', 'Código postal *'],
  birthday: ['Aniversário *', 'Birthday *', 'Cumpleaños *'],
  coloring: ['Coloração', 'Coloring', 'Coloración'],
  silhouette: ['Silhueta', 'Silhouette', 'Silueta'],
  cell: ['Celular *', 'Phone *', 'Celular *'],
  close: ['Fechar', 'Close', 'Cerrar'],
  national: ['Nacional', 'National', 'Nacional'],
  foreign: ['Estrangeiro', 'Foreign', 'Extranjero']

}
