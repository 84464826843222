import { combineReducers } from 'redux'
import adminReducer from './adminReducer'
import usersReducer from './usersReducer'

const rootReducer = combineReducers({
  adminReducer,
  usersReducer
})

export default rootReducer
