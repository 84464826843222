export default
{
  updatedSuc: ['Coloração atualizada com sucesso!', 'Coloring updated successfully!', 'Coloración actualizada con éxito!'],
  updateError: ['Algum erro ocorreu ao atualizar a coloração pessoal do cliente!', 'Some error was occurred while updating personal coloring!', '¡Algún error ocurrió al actualizar la coloración personal del cliente!'],
  descriptio: [`Basta selecionar o resultado do teste de coloração pessoal da sua cliente ou adicionar imagens referente ao teste que logo essa informação será atualizada.
    Caso a cliente não tenha realizado o Teste de Cor, irá aparecer para ela uma mensagem sugerindo que faça, para saber quais cores irão deixá-la mais bonita.`, 'Just select the result of your client\'s personal coloring test that will soon show the online color chart and the personality characteristics of the chosen station. If the customer did not perform the Color Test, a message will appear for her suggesting to do, to know which colors will make her look better.', `Sólo tienes que seleccionar el resultado de la prueba de coloración personal de tu cliente que pronto aparecerá la cartela de color en línea y las características sobre la personalidad de la estación elegida.
    Si el cliente no ha realizado la prueba de color, aparecerá un mensaje que le sugiere que haga, para saber qué colores la dejará más bonita.`],
  seasons: [['Primavera Quente', 'Primavera Brilhante', 'Primavera Clara', 'Outono Suave', 'Outono Escuro', 'Outono Quente', 'Inverno Brilhante', 'Inverno Frio', 'Inverno Escuro', 'Verão Frio', 'Verão Claro', 'Verão Suave'], ['Hot Spring', 'Shiny Spring', 'Light Spring', 'Soft Fall', 'Dark Fall', 'Hot Fall', 'Shiny Winter', 'Cold Winter', 'Dark Winter', 'Cold Summer', 'Light Summer', 'Soft Summer'], ['Primavera Caliente', 'Primavera Brillante', 'Primavera Clava', 'Otoño Suave', 'Otoño Oscuro', 'Otoño Caliente', 'Invierno Brillante', 'Invierno frío', 'Invierno Oscuro', 'Verano frío', 'Verano Claro', 'Verano Suave']],
  updateColor: ['Atualizar Coloração Pessoal', 'Update Personal Coloring', 'Actualizar Coloración Personal'],
  currentColor: ['Coloração Atual:', 'Current Coloring:', 'Coloración actual:'],
  seeColoring: ['Ver Coloração', 'See Coloring', 'Ver Coloración'],
  changeColoring: ['Alterar Coloração', 'Update Coloring', 'Cambio de coloración'],
  colection: ['Sazonal Expandido', 'Colection', 'Coleccion'],
  imgs: ['Upload images', 'Upload images', 'Sube imágenes'],
  noImage: ['Coloração Sem Imagem', 'Profile without image', 'Coloração sin imagen'],
  confim: ['Confirmar', 'Confirm', 'Confirmar'],
  deleteConfirm: ['Tem certeza que deseja excluir essa foto? Esta ação não pode ser desfeita.', 'Are you sure you want to delete this picture? This action can not be undone.', '¿Está seguro de que desea eliminar esta foto? Esta acción no se puede deshacer.'],
  delete: ['Excluir Imagem', 'Delete Image', 'Eliminar Imagen'],
  chooseImage: ['Escolha um arquivo de Imagem para o coloração do Cliente', 'Choose a image for the client profile', 'Seleccione un archivo de imagen para el coloración del cliente'],
  resolution: ['Importante: Opte por imagens na resolução 720x1280', 'Important: Choose images at 720x1280 resolution ', 'Importante: Opte por imágenes en la resolución 720x1280'],
  uploadError: ['Algum erro ocorreu ao fazer upload da imagem!', 'Some error occurred while uploading the image', '¡Algún error ocurrió al subir la imagen!'],
  imgsSelected: ['Imagens da consultora', 'Consultant images', 'Imágenes de consultora'],
  deleteSuc: ['Imagem excluída com sucesso!', 'Image deleted successfully!', '¡Imagen eliminada con éxito!']
}
