
const usersReducer = (state = { users: [], usersFilter: '', consultantsFilter: '', selectedUserId: null }, action) => {
  switch (action.type) {
    case 'SET_USERS':
      const users = action.users.reduce((acc, user) => {
        const oldUser = state.users.find(({ id }) => id === user.id) || {}
        return [...acc, { ...user, media: oldUser.media }]
      }, [])

      return {
        ...state,
        users
      }
    case 'SET_USERS_FILTER':
      return {
        ...state,
        usersFilter: action.filter
      }
    case 'PUT_USER':
      const userIndex = state.users.findIndex(user => user.id === action.user.id)

      return {
        ...state,
        users: [
          ...state.users.slice(0, userIndex !== -1 ? userIndex : 0),
          action.user,
          ...state.users.slice(userIndex !== -1 ? userIndex + 1 : 0)
        ]
      }
    case 'SET_SELECTED_USER_ID':
      return {
        ...state,
        selectedUserId: action.selectedUserId
      }
    case 'SET_USER_ATTRIBUTE':
      return {
        ...state,
        users: state.users.map(user => {
          return (user.id === action.userId) ? { ...user, ...action.attribute } : user
        })
      }
    case 'SET_USER_MEDIA_ATTRIBUTE':
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id !== action.userId) return user

          return {
            ...user,
            media: {
              ...user.media,
              ...action.attribute
            }
          }
        })
      }
    case 'REMOVE_USER':
      const body = {
        ...state,
        users: state.users.filter(user => user.id !== action.userId)
      }

      if (action.userId === state.selectedUserId) {
        body.selectedUserId = null
      }

      return body

    case 'SET_CONSULTANTS_FILTER':
      return {
        ...state,
        consultantsFilter: action.filter
      }
    
    case 'SET_SUBSCRIPTIONS':
      // const subscriptions = action.users.reduce((subscriptions) => {
        // const oldUser = state.users.find(({ id }) => id === user.id) || {}
      if (!action.filters) {
        return {
          ...state, 
          subscriptions: action.subscriptions.map(subscription => {
            const subUser = action.users.find(user => user.id === subscription.userId)
            if (subUser) {
              subscription.consultantName = subUser.name
            }
            return subscription
          })
        }
      } else {
        const subscriptions = action.subscriptions.map(subscription => {
          const subUser = action.users.find(user => user.id === subscription.userId)
          if (subUser) {
            subscription.consultantName = subUser.name
          }
          return subscription
        })

        const filteredSubscriptions = subscriptions.filter(subscription => {
          if (action.filters.cancelledFilter && subscription.status === 'cancelled') {
            return subscription
          }
          if (action.filters.activeFilter && subscription.status === 'active' && subscription.nextBillingDay !== null) {
            return subscription
          }
          if (action.filters.manualActiveFilter && subscription.status === 'active' && subscription.nextBillingDay === null) {
            return subscription
          }
          if (action.filters.testFilter && subscription.status === 'awaitingCancel' && subscription.planIdentifier === 1 ) {
            return subscription
          }
          if (action.filters.awaitingCancelFilter && subscription.status === 'awaitingCancel' && subscription.planIdentifier !== 1){
            return subscription
          }
        })

        let sortedSubscriptions = []
        if (action.filters.sortField === 'couponCode') {
          sortedSubscriptions = filteredSubscriptions.sort((a, b) => a[action.filters.sortField] - b[action.filters.sortField])
        } else {
          if (action.filters.sortOrientation === 'ASC') {
            sortedSubscriptions = filteredSubscriptions.sort((a, b) => {return new Date(a[action.filters.sortField]) - new Date(b[action.filters.sortField])})
          } else {
            sortedSubscriptions = filteredSubscriptions.sort((b, a) => {return new Date(a[action.filters.sortField]) - new Date(b[action.filters.sortField])})
          }
        }
        

        return {
          ...state, 
          subscriptions: sortedSubscriptions
        }
        
      }
      

    default:
      return state
  }
}

export default usersReducer
