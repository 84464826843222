import { s3Region, s3Bucket } from "./defaultProps"

export const s3KeyToURI = key => {
  return `https://${s3Bucket}.s3.${s3Region}.amazonaws.com/${key}`
}

export const resolvePlanName = planId => {
  switch (planId) {
    case 1:
      return 'Teste Grátis'
    case 2:
      return '4 Estações'
    case 3:
      return 'Futurista'
    case 4:
      return '[LEGADO] Minimalista'
    case 5:
      return 'Completo Gratuito'
    case 7:
      return '[LEGADO] Minimalista 10'
    case 8:
      return '4 Estações 10'
    case 9:
      return '[LEGADO] Minimalista Semestral'
    case 10:
      return '4 Estações Semestral'
    case 11:
      return '4 Estações 5'
    case 12:
      return 'Cores'
    case 13:
      return 'Minimalista Mensal'
    case 14:
      return 'Minimalista Trimestral'
    case 15:
      return 'Minimalista Semestral'
    case 16:
      return '4 Estações Trimestral'
    default:
      return ''
  }
}

export const plans = [
  { 
    planId: 1, 
    planName: 'Teste Grátis',
    duration: '5 dias'
  },
  {
    planId: 5,
    planName: 'Completo Gratuito',
    duration: '1 mês'
  },
  {
    planId: 2,
    planName: '4 Estações',
    duration: '1 mês'
  },
  {
    planId: 16,
    planName: '4 Estações Trimestral',
    duration: '3 meses'
  },
  {
    planId: 10,
    planName: '4 Estações Semestral',
    duration: '6 meses'
  },
  {
    planId: 12,
    planName: 'Cores',
    duration: '1 mês'
  },
  {
    planId: 13,
    planName: 'Minimalista Mensal',
    duration: '1 mês'
  },
  {
    planId: 14,
    planName: 'Minimalista Trimestral',
    duration: '3 meses'
  },
  {
    planId: 15,
    planName: 'Minimalista Semestral',
    duration: '6 meses'
  },
  {
    planId: 3,
    planName: 'Futurista',
    duration: '1 mês'
  },
  {
    planId: 4,
    planName: '[LEGADO] Minimalista',
    duration: '1 mês'
  },
  {
    planId: 7,
    planName: '[LEGADO] Minimalista 10 Clientes',
    duration: '1 mês'
  },
  {
    planId: 8,
    planName: '4 Estações 10 Clientes',
    duration: '1 mês'
  },
  {
    planId: 9,
    planName: '[LEGADO] Minimalista Semestral',
    duration: '6 meses'
  },
  {
    planId: 11,
    planName: '4 Estações 5 Clientes',
    duration: '1 mês'
  }
]