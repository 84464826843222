import { createSelector } from 'reselect'
import moment from 'moment'
import { auth } from './defaultProps'

const getAllUsers = state => state.usersReducer.users
const getAllUsersFilter = state => state.usersReducer.usersFilter

export const filteredUsers = createSelector(
  [getAllUsers, getAllUsersFilter],
  (allUsers, allUsersFilter) => {
    return allUsers
      // .filter(user => user.auth === auth.user || user.auth === auth.unverified)
      // .filter(user => user.name.toUpperCase().indexOf(allUsersFilter.toUpperCase()) > -1)
      // .sort((userA, userB) => moment(userB.createdAt).diff(userA.createdAt)) // Sort by createdAt desc
  }
)
const getAllConsultantsFilter = state => state.usersReducer.consultantsFilter
export const filteredConsultants = createSelector(
  [getAllUsers, getAllConsultantsFilter],
  (allUsers, allConsultantsFilter) => {
    return allUsers
      .filter(user => user.auth === auth.consultant || user.auth === auth.waitingConsultant)
      .filter(user => {
        // filters consultant by name or email
        return user.name.toUpperCase().indexOf(allConsultantsFilter.toUpperCase()) > -1 || user.email.toUpperCase().indexOf(allConsultantsFilter.toUpperCase()) > -1
      }
        )
      .sort((userA, userB) => moment(userB.createdAt).diff(userA.createdAt)) // Sort by createdAt desc
  }
)

const getSelectedUserId = state => state.usersReducer.selectedUserId
export const selectedUser = createSelector(
  [getAllUsers, getSelectedUserId],
  (allUsers, selectedUserId) => {
    const user = allUsers.find(user => user.id === selectedUserId)
    return user
  }
)
