import React, { Component } from 'react'

import { FormControl, FormGroup, Button, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Slider from 'react-slick'
import rp from 'request-promise-native'
import Loader from 'react-loader-spinner'

import * as actions from '../redux/actions'
import { selectedUser } from '../services/selectors'
import { uri, maxDocumentSize } from '../services/defaultProps'

import text from '../constants/moodboard'

class Moodboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pdfToUpload: null,
      loading: false,
      language: 1
    }
  }

  componentDidMount () {
    this.mounted = true
    const backgroundRefresh = () => {
      if (!this.mounted || this.props.selectedUser.media.moodboard !== 'uploading') return

      fetch(`${uri}/media/moodboard?id=${this.props.selectedUser.id}`, {
        method: 'GET',
        headers: {
          Authorization: this.props.token
        }
      })
        .then(res => {
          if (!res.ok && res.status === 401) {
            this.props.setToken(null)
            return res.json().then((err) => Promise.reject(err)) // eslint-disable-line prefer-promise-reject-errors
          } else {
            return res
          }
        })
        .then(res => res.json())
        .then(moodboard => {
          if (this.props.selectedUser && this.props.selectedUser.media && this.props.selectedUser.media.moodboard === 'uploading' && (moodboard.moodboard == null || moodboard.moodboard.length === 0)) {
          } else {
            this.props.setUserMediaAttribute(this.props.selectedUser.id, moodboard)
          }
        })
        .catch((err) => {
          console.log('Error fetching moodboard', err)
        })
        .finally(() => {
          setTimeout(backgroundRefresh, 1000 * 30)
        })
    }
    setTimeout(backgroundRefresh, 1000 * 30)
  }

  componentWillUnmount () {
    this.mounted = false
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') })
  }

  handleUploadPdf = (event) => {
    const pdfToUpload = event.target.files[0]

    if (!pdfToUpload) return

    // Size in MB
    const size = pdfToUpload.size / (1024 * 1024)

    if (size > maxDocumentSize) {
      toast(text.sizeError[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    const type = pdfToUpload.type.split('/').pop()

    // Unwanted type
    if (type !== 'pdf') {
      this.props.setError(text.error[this.state.language], text.wrongFormat[this.state.language])
      return
    }

    this.setState({ pdfToUpload })
  }

  handleUpdateClick = () => {
    if (this.state.pdfToUpload) {
      this.setState({ loading: true })
      fetch(`${uri}/media/moodboard?id=${this.props.selectedUser.id}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/pdf',
          Authorization: this.props.token
        },
        body: this.state.pdfToUpload
      })
        .then(res => {
          if (!res.ok && res.status === 401) {
            this.props.setToken(null)
            return res.json().then((err) => Promise.reject(err)) // eslint-disable-line prefer-promise-reject-errors
          } else {
            return res
          }
        })
        .then(res => res.json())
        .then(moodboard => {
          this.props.setUserMediaAttribute(this.props.selectedUser.id, moodboard)
          this.setState({ loading: false, pdfToUpload: null })
          toast(text.sent[this.state.language], {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
        .catch(err => {
          console.log('Error when updating moodboard', err)
          this.setState({ loading: false, pdfToUpload: null })
          toast(text.someError[this.state.language], {
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
    }
  }

  handleDeleteClick = () => {
    this.setState({ loading: true })
    rp({
      uri: `${uri}/media/moodboard`,
      method: 'DELETE',
      headers: {
        Authorization: this.props.token
      },
      body: {
        userId: this.props.selectedUser.id
      },
      json: true
    })
      .then(res => {
        this.setState({ loading: false })
        this.props.setUserMediaAttribute(this.props.selectedUser.id, { moodboard: [] })
        toast(text.delete[this.state.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error when deleting moodboard', err)
        this.setState({ loading: false, pdfToUpload: null })
        toast(text.errorDelete[this.state.language], {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
  }

  render () {
    if (this.state.loading) {
      return (
        <div id='centralSpinnerHolder'>
          <Loader
            type='TailSpin'
            color='#000'
            height='50'
            width='50'
          />
        </div>
      )
    } else if (this.props.selectedUser.media.moodboard === 'uploading') {
      return (
        <div style={{ justifyContent: 'center', flexDirection: 'column', padding: 20 }}>
          <div style={{ marginBottom: 50 }}>{text.uploading[this.state.language]}</div>
          <div style={{ marginBottom: 50 }}>{text.delay[this.state.language]}</div>
        </div>
      )
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => this.setState({ page: index })
    }

    return (
      <form id='moodboardForm' onSubmit={(e) => e.preventDefault()}>

        <div style={{ width: '90%', margin: 'auto' }}>
          <Alert bsStyle='info'>
            <div style={{ textAlign: 'left', paddingHorizontal: 10 }}>
              <strong>{text.style[this.state.language]}</strong>
              <div>{text.styleMustPdf[this.state.language]}<a href='https://www.ilovepdf.com/pt/comprimir_pdf' target='_blank'>{text.clickHere[this.state.language]}</a>.</div>
            </div>
          </Alert>
        </div>
        {this.props.selectedUser.media.moodboard && this.props.selectedUser.media.moodboard.length
          ? <div>
            <h2 id='moodboardDownload'>{text.moodboardConfigurated[this.state.language]}</h2>
            <div style={{ margin: 30 }}>
              <Slider {...settings}>
                {this.props.selectedUser.media.moodboard.map((item, index) =>
                  <div key={index} className='sliderItem'>
                    <img src={item} id='sliderImage' alt={`Imagem ${index}`} />
                  </div>
                )}
              </Slider>
            </div>
            <h4>{text.changeMoodBoard[this.state.language]}</h4>
          </div>
          : <h2 id='moodboardNotSet'>{text.moodNotConfigurated[this.state.language]}</h2>}
        <FormGroup>
          <FormControl id='upload' onChange={this.handleUploadPdf} type='file' accept='*.pdf' />
        </FormGroup>
        <Button
          type='submit'
          id='formSubmit'
          bsStyle='primary'
          onClick={this.handleUpdateClick}
          disabled={!this.state.pdfToUpload}
        >{text.updateMood[this.state.language]}
        </Button>
        {this.props.selectedUser.media.moodboard && this.props.selectedUser.media.moodboard.length > 0 &&
          <Button bsStyle='danger' onClick={this.handleDeleteClick}>{text.delete[this.state.language]}</Button>}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer,
    ...state.usersReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(Moodboard)
