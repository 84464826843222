import React, { Component } from 'react'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import rp from 'request-promise-native'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'
import FileBrowser from 'react-keyed-file-browser'
import downloadjs from 'downloadjs'

import DriveItemDetail from './DriveItemDetail'
import * as actions from '../redux/actions'
import { uri, maxImageSize } from '../services/defaultProps'
import { selectedUser } from '../services/selectors'

// import FileBrowser, {Icons} from 'react-keyed-file-browser'

class NestedEditableDemo extends Component {
  constructor (props) {
    super(props)

    this.state = {
      imagesToUpload: [],
      loadingUpload: false
    }
  }

  mapFileAttributes = () => {
    return this.props.selectedUser.media.userDrive.map(file => {
      console.log('file', file)
      return {
        key: file.name || 'Arquivo',
        modified: moment(file.createdAt),
        size: file.size || 0
      }
    })
  }

  handleUpload = async (event) => {
    const imagesToUpload = []
    for (let i = 0; i < event.target.files.length; i++) {
      const imageToUpload = event.target.files[i]
      const type = imageToUpload.type.split('/').pop()
      // Size in MB
      const size = imageToUpload.size / (1024 * 1024)
      if (size > maxImageSize) {
        toast('text.sizeError[this.state.language]', {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT
        })
        return
      }
      // Unwanted type
      if (!['jpeg', 'png', 'pdf'].includes(type)) {
        this.props.setError('text.error[this.state.language]', 'text.wrongFormat[this.state.language]')
        return
      }

      imagesToUpload.push(imageToUpload)
    }

    this.setState({ imagesToUpload })
  }

  handleUpdateClick = async () => {
    if (this.state.imagesToUpload.length > 0) {
      this.setState({ loadingUpload: true })

      Promise.all(this.state.imagesToUpload.map(imageToUpload => {
        return fetch(`${uri}/media/drive?userId=${this.props.selectedUser.id}&name=${imageToUpload.name}`, {
          method: 'POST',
          headers: {
            'content-type': imageToUpload.type,
            Authorization: this.props.token
          },
          body: imageToUpload
        })
          .then(res => {
            if (!res.ok && res.status === 401) {
              this.props.setToken(null)
              return res.json().then((err) => Promise.reject(err)) // eslint-disable-line prefer-promise-reject-errors
            } else {
              return res
            }
          })
          .then(res => res.json())
          .then(userDrive => {
            this.props.setUserMediaAttribute(this.props.selectedUser.id, userDrive)
            toast('text.updatedSuc[this.state.language]', {
              type: toast.TYPE.INFO,
              position: toast.POSITION.BOTTOM_RIGHT
            })
          })
      }))
        .finally(() => this.setState({ loadingUpload: false }))
    }
  }

  handleDeleteFile = ({ key: fileKey }) => {
    const file = this.props.selectedUser.media.userDrive.find((file) => file.name === fileKey)

    if (file != null) {
      this.setState({ loadingUpload: true })

      return rp({
        uri: `${uri}/media/drive?id=${file.id}`,
        method: 'DELETE',
        headers: {
          authorization: this.props.token
        },
        json: true
      })
        .then(userDrive => {
          this.props.setUserMediaAttribute(this.props.selectedUser.id, userDrive)
          toast('text.updatedSuc[this.state.language]', {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.props.setToken(null)
          }
          throw err
        })
        .finally(() => this.setState({ loadingUpload: false }))
    }
  }

  onDownload = (file) => {
    const foundFile = this.props.selectedUser.media.userDrive.find(({ name }) => name === file.key)

    if (foundFile) {
      return rp({
        uri: `${uri}/media/drive/${foundFile.id}`,
        method: 'get',
        headers: {
          Authorization: this.props.token
        },
        json: true
      })
        .catch(err => {
          if (err.statusCode === 401) {
            this.props.setToken(null)
          }
          throw err
        })
        .then((res) => downloadjs(res.file))
    }
  }

  render () {
    return (
      <div>
        <FileBrowser
          files={this.mapFileAttributes()}
          detailRenderer={(props) => (<DriveItemDetail onDownload={this.onDownload} onDelete={this.handleDeleteFile} {...props} />)}
        />
        {
          this.state.loadingUpload
            ? <div id='centralSpinnerHolderWithLessPadding'>
              <Loader
                type='TailSpin'
                color='#000'
                height='50'
                width='50'
              />
              </div>
            : <form id='closetForm' onSubmit={(e) => e.preventDefault()}>
              <input type='file' accept='*' onChange={this.handleUpload} multiple />
              <Button
                type='submit'
                bsStyle='primary'
                onClick={this.handleUpdateClick}
                disabled={this.state.imagesToUpload.length === 0}
              > Upload
              </Button>
              </form>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.usersReducer,
    ...state.adminReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(NestedEditableDemo)
