export default
{
  sizeError: ['O arquivo enviado é maior que 10MB! Comprima o mesmo para que fique abaixo do limite.', 'The uploaded file is larger than 10mb! Please compress to make it below the limit.', 'El archivo enviado es mayor que 10MB! Comprima el mismo para que quede por debajo del límite.'],
  wrongFormat: ['Formato inválido! Utilize arquivos no formato .PDF', 'Invalid format! Use files in .PDF format!', 'Formato no válido! Utilice archivos en formato .PDF!'],
  error: ['Erro', 'Error', 'Error'],
  sent: ['Moodboard submetido! Em alguns minutos estará disponível', 'Moodboard submitted! It will be avaliable in a few minutes.', 'Moodboard sometido! En algunos minutos estará disponible'],
  someError: ['Algum erro ocorreu ao enviar um arquivo para o MoodBoard!', 'Some error occurred while sending the file to MoodBoard', '¡Algún error ocurrió al enviar un archivo al MoodBoard!'],
  delete: ['Moodboard excluído com sucesso!', 'Moodboard deleted successfully', 'Moodboard eliminado con éxito!'],
  errorDelete: ['Algum erro ocorreu ao deletar o moodboard', 'Some error occurred while deleting the moodboard', 'Algún error ocurrió al suprimir el moodboard'],
  uploading: ['Upload em andamento...', 'Uploading...', 'Carga en curso ...'],
  delay: ['Pode demorar de 5 a 10 minutos, dependendo do tamanho do arquivo e quantidade de páginas', 'It may take 5 to 10 min, depending on the file size', 'Puede tardar de 5 a 10 minutos, dependiendo del tamaño del archivo y la cantidad de páginas'],
  style: ['Aqui você irá colocar o Guia de Estilo criado para a sua cliente.', "Here you put the style's guide for your client", 'Aquí podrás poner la Guía de Estilo creada especialmente para tu cliente.'],
  styleMustPdf: ['O Guia deve estar em PDF e ter o peso de até 10MB. Se estiver maior, ', 'The Guide must be in PDF and size up to 10MB. If its bigger you can', 'La Guía debe estar en PDF y tener el peso de hasta 10MB. Si es más que esto,'],
  clickHere: ['clique aqui para comprimir', 'click here to compress.', 'haga un clic aquí para reducirlo.'],
  moodboardConfigurated: ['Moodboard já configurado', 'Moodboard already configured', 'Moodboard ya está configurado'],
  changeMoodBoard: ['Troque o Moodboard utilizando o botão abaixo.', 'Change MoodBoard clicking in the button below', 'Cambie el Moodboard utilizando el botón de abajo.'],
  moodNotConfigurated: ['Moodboard ainda não configurado, faça upload do Moodboard abaixo', 'MoodBoard is not configured yet, upload it below', 'Moodboard aún no configurado, cargue el Moodboard abajo'],
  updateMood: ['Atualizar Moodboard', 'Update Moodboard', 'Actualizar Moodboard'],
  delete: ['Deletar Moodboard', 'Delete Moodboard', 'Eliminar Moodboard']
}
