import React, { Component } from 'react'
import { ButtonToolbar, Panel, Form, FormControl, FormGroup, Col, Button, Modal, ControlLabel, Tabs, Tab, Checkbox,Radio, Image, HelpBlock, Table, Row, Accordion } from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import rp from 'request-promise-native'
import moment from 'moment'
import { connect } from 'react-redux'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'

import * as actions from '../redux/actions'
import { uri, uriV2, freeMonthCoupons } from '../services/defaultProps'
import { selectedUser } from '../services/selectors'

import text from '../constants/ConfigConsultant'
import imageHandler from '../services/imageHandler'
import { s3KeyToURI, resolvePlanName, plans } from '../services/helpers'

function centavosToReais(centavos){
  return (Number.isInteger(centavos) ? centavos : 0) / 100
}

function reaisToCentavos(reais){
  return (typeof reais ==='number' ? reais : 0) * 100
}

class ConfigTab extends Component {
  constructor (props) {
    super(props)

    this.state = {
      userId: this.props.userId,
      showModal: false,
      loading: false,
      loadingDelete: false,
      deleteConfirmText: '',
      language: 1,
      newClient: this.props.userData
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') - '0' })
  }

  handleModal = () => { this.setState({ showModal: !this.state.showModal }) }

  handleUpdate = () => {
    if (this.state.loading) return

    if (!this.state.newClient.name ||
      !this.state.newClient.streetName ||
      !this.state.newClient.phone) {
      toast(text.fillFields[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    const body = {
      ...this.state.newClient,
      birth: moment.utc(this.state.newClient.birth).format('YYYY-MM-DD'),
      phone: this.state.newClient.phone.replace(/\D+/g, ''),
      zipCode: this.state.newClient.zipCode.replace(/\D+/g, '')
    }

    this.setState({ loading: true })
    rp({
      uri: `${uri}/user?id=${this.props.selectedUser.id}`,
      method: 'PATCH',
      headers: {
        Authorization: this.props.token
      },
      body: body,
      json: true
    })
      .then(updatedUser => {
        this.props.setUserAttribute(this.props.selectedUser.id, updatedUser)
        this.setState({ loading: false })
        this.props.onUpdate(updatedUser)
        toast(text.updateSucess[this.state.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error when updating consultant config', err)
        this.setState({ loading: false })
        toast(text.someError[this.state.language], {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
  }

  handleDelete = () => {
    if (this.props.selectedUser.name.toLowerCase() !== this.state.deleteConfirmText.toLowerCase()) {
      toast(text.writeName[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    this.setState({ loadingDelete: true })
    rp({
      uri: `${uri}/user`,
      method: 'DELETE',
      headers: {
        Authorization: this.props.token
      },
      body: {
        userId: this.props.selectedUser.id
      },
      json: true
    })
      .then(() => {
        this.setState({ loading: false })
        this.props.removeUser(this.props.selectedUser.id)
        toast(text.deleteSucess[this.state.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
        setTimeout(() => this.props.history.push('/'), 500)
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error when deleting consultant', err)
        this.setState({ loading: false })
      })
  }

  handleFormAttribute = (e, attribute) => {
    const obj = this.state.newClient
    obj[attribute] = e.target.value
    this.setState({ obj })
  }

  handleFormPhone = (e) => {
    const x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/)

    this.setState({
      newClient: {
        ...this.state.newClient, phone: !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
      }
    })
  }

  handleFormZipCode = (e) => {
    const x = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/)

    this.setState({
      newClient: {
        ...this.state.newClient, zipCode: !x[2] ? x[1] : x[1] + '-' + x[2]
      }
    })
  }

  handlePlan = (e) => {
    this.setState({
      newClient: {
        ...this.state.newClient, plan: e.target.value
      }
    })
  }

  handleDeleteConfirmText = (e) => {
    this.setState({ deleteConfirmText: e.target.value })
  }

  render () {
    if (this.state.loading || !this.props.selectedUser || !this.state.newClient) {
      return (
        <div id='centralSpinnerHolder'>
          <Loader
            type='TailSpin'
            color='#000'
            height='50'
            width='50'
          />
        </div>
      )
    }

    return (
        <Form id='clientConfig' horizontal onSubmit={(e) => e.preventDefault()} style={{ padding: 20 }}>
          {this.state.loadingAddClient
            ? <div id='centralSpinnerHolder'>
              <Loader
                type='TailSpin'
                color='#000'
                height='50'
                width='50'
              />
            </div>
            : <div>
              <FormGroup controlId='addNome'>
                <ControlLabel>{text.nameForm[this.state.language]}</ControlLabel>
                <FormControl type='text' placeholder='Nome' onChange={(e) => this.handleFormAttribute(e, 'name')} value={this.state.newClient.name} />
              </FormGroup>
              <FormGroup controlId='addEmail'>
              <ControlLabel>{text.email[this.state.language]}</ControlLabel>
                <FormControl readOnly type='email' value={this.props.selectedUser.email} />
              </FormGroup>
              <ControlLabel>{text.adress[this.state.language]}</ControlLabel>{' '}
              <FormGroup controlId='addEndereco'>
                <Col sm={8}>
                  <FormControl type='text' placeholder={text.street[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'streetName')} value={this.state.newClient.streetName} />
                </Col>
                <Col sm={4}>
                  <FormControl type='text' placeholder={text.number[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'streetNumber')} value={this.state.newClient.streetNumber} />
                </Col>
              </FormGroup>
              <FormGroup controlId='addEndereco'>
                <Col sm={12}>
                  <FormControl type='text' placeholder={text.complement[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'complement')} value={this.state.newClient.complement} />
                </Col>
              </FormGroup>
              <FormGroup controlId='addEndereco'>
                <Col sm={5}>
                  <FormControl type='text' placeholder={text.city[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'city')} value={this.state.newClient.city} />
                </Col>
                <Col sm={5}>
                  <FormControl type='text' placeholder={text.zip[this.state.language]} onChange={this.handleFormZipCode} value={this.state.newClient.zipCode} />
                </Col>
                <Col sm={2}>
                  <FormGroup controlId='addEndereco' hidden={this.state.language === 0 ? 0 : 1}>
                    <FormControl componentClass='select' placeholder='Estado' onChange={(e) => this.handleFormAttribute(e, 'state')} value={this.state.newClient.state}>
                      <option value='AC'>AC</option>
                      <option value='AL'>AL</option>
                      <option value='AP'>AP</option>
                      <option value='AM'>AM</option>
                      <option value='BA'>BA</option>
                      <option value='CE'>CE</option>
                      <option value='DF'>DF</option>
                      <option value='ES'>ES</option>
                      <option value='GO'>GO</option>
                      <option value='MA'>MA</option>
                      <option value='MT'>MT</option>
                      <option value='MS'>MS</option>
                      <option value='MG'>MG</option>
                      <option value='PA'>PA</option>
                      <option value='PB'>PB</option>
                      <option value='PR'>PR</option>
                      <option value='PE'>PE</option>
                      <option value='PI'>PI</option>
                      <option value='RJ'>RJ</option>
                      <option value='RN'>RN</option>
                      <option value='RS'>RS</option>
                      <option value='RO'>RO</option>
                      <option value='RR'>RR</option>
                      <option value='SC'>SC</option>
                      <option value='SP'>SP</option>
                      <option value='SE'>SE</option>
                      <option value='TO'>TO</option>
                    </FormControl>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup controlId='addAniversario'>
                <ControlLabel>{text.birthday[this.state.language]}</ControlLabel><br />
                <DatePicker value={this.state.newClient.birth} onChange={(birth) => this.setState({ newClient: { ...this.state.newClient, birth } })} />
              </FormGroup>
              <FormGroup controlId='addCell'>
                <ControlLabel>{text.cell[this.state.language]}</ControlLabel>
                <FormControl type='text' placeholder='(99) 99999-9999' onChange={this.handleFormPhone} value={this.state.newClient.phone} />
              </FormGroup>
              <FormGroup controlId='setPlan'>
                <ControlLabel>{text.plan[this.state.language]}</ControlLabel>
                <FormControl componentClass='select' onChange={this.handlePlan} value={this.state.newClient.plan}>
                  <option value='normal'>Plano normal</option>
                  <option value='pro'>Plano coloração gold</option>
                </FormControl>
              </FormGroup>
              <ButtonToolbar>
                <Button bsStyle='danger' onClick={this.handleModal}>{text.deleteClient[this.state.language]}</Button>
                <Button bsStyle='primary' type='submit' onClick={this.handleUpdate}>{text.update[this.state.language]}</Button>
              </ButtonToolbar>
              <Modal show={this.state.showModal} onHide={this.handleModal}>
                <Modal.Header closeButton>
                  <Modal.Title>{text.deleteClient[this.state.language]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>{text.deleteConfirm[this.state.language]} <strong>{this.props.selectedUser.name}</strong> {text.deleteConfirm2[this.state.language]} </p>
                  <p>{text.enterName[this.state.language]}</p>
                  <FormGroup controlId='addCell'>
                    <FormControl type='text' placeholder={this.props.selectedUser.name} onChange={this.handleDeleteConfirmText} value={this.state.deleteConfirmText} />
                  </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                  {this.state.loadingDelete
                    ? <div id='centralSpinnerHolder'>
                      <Loader
                        type='TailSpin'
                        color='#000'
                        height='50'
                        width='50'
                      />
                    </div>
                    : <Button bsStyle='danger' onClick={this.handleDelete}>{text.deleteClient[this.state.language]}</Button>}
                </Modal.Footer>
              </Modal>
            </div>}
        </Form>
    )
  }
}

class StyleGameTab extends React.Component {
  constructor(props){
    super(props)
    this.inputFileRef = React.createRef();
    this.state= {
      userId: this.props.userId ? parseInt(this.props.userId) : undefined,
      loading: false,
      image: (this.props.styleGame.image && s3KeyToURI(this.props.styleGame.image)) || undefined,
      name: this.props.styleGame.name || '',
      personalColoringAvailable: this.props.styleGame.personalColoringAvailable || false,
      personalColoringPrice: centavosToReais(this.props.styleGame.personalColoringPrice) || 0 ,
      onlineConsultingAvailable: this.props.styleGame.onlineConsultingAvailable || false,
      onlineConsultingPrice: centavosToReais(this.props.styleGame.onlineConsultingPrice) || 0 ,
      moodboardAvailable: this.props.styleGame.moodboardAvailable || false,
      moodboardPrice: centavosToReais(this.props.styleGame.moodboardPrice) || 0 ,
      state: this.props.styleGame.state || '',
      city: this.props.styleGame.city || '',
      phone: this.props.styleGame.phone || '',
      instagram: this.props.styleGame.instagram || '',
      email: this.props.styleGame.email || '',
      website: this.props.styleGame.website || '',
      videoCode: this.props.styleGame.videoCode || '',
      description: this.props.styleGame.description || '',
      qualification: this.props.styleGame.qualification || '',
      active: Boolean(this.props.styleGame.active) || false
    }

  }

  handleUploadImageAction = () => {
    if( this.inputFileRef  && this.inputFileRef.current){
      this.inputFileRef.current.click()
    }
  }

  handleUploadImageChange = async (e) => {
    const image = await imageHandler(e.target.files[0])
    this.setState({image})
  }

  sanatizeFields({ loading, ...state}) {

    const image = state.image ? state.image.replace(/^data:.+;base64,/, ''): undefined
    const personalColoringPrice = reaisToCentavos(parseFloat(state.personalColoringPrice))
    const onlineConsultingPrice = reaisToCentavos(parseFloat(state.onlineConsultingPrice))
    const moodboardPrice = reaisToCentavos(parseFloat(state.moodboardPrice))
    const phone = state.phone.replace(/\D/g, '')

    return { ...state, image, personalColoringPrice,onlineConsultingPrice,moodboardPrice, phone}
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    const handleNewStyleGame = async (body) => rp({
        uri: `${uriV2}/user/game/profile`,
        method: 'POST',
        headers: {
          Authorization: this.props.token
        },
        body,
        json: true
      })

    const handleUpdateStyleGame = async (body) => rp({
        uri: `${uriV2}/user/${this.props.userId}/game/profile`,
        method: 'PATCH',
        headers: {
          Authorization: this.props.token
        },
        body,
        json: true
      })
    
    this.setState({loading:true})

    const fields = this.sanatizeFields(this.state)

    const isUpdate = Object.keys(this.props.styleGame).length > 0

    const action = async () => isUpdate? handleUpdateStyleGame(fields): handleNewStyleGame(fields)

    return action().then(() => {
        toast(text.successfullAction[this.props.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.error(err)
        toast(err.message, {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT
        })
      })
      .finally(()=> this.setState({loading:false}))
  }

  render () {
    if (this.state.loading) {
      return (
				<div id='centralSpinnerHolder'>
					<Loader
						type='TailSpin'
						color='#000'
						height='50'
						width='50'
					/>
				</div>
      )
    }

    return (
      <div style={{display: 'flex'}}>
      <Form style={{ margin: 16 }} onSubmit={this.handleSubmit}>
        <Col sm={2}>
          <input
            type='file'
            accept="image/*"
            ref={this.inputFileRef}
            onChange={this.handleUploadImageChange}
            style={{display:'none'}}
          />
          <div style={{ borderWidth: 1, borderColor: '#707070', borderStyle: 'solid', padding: 4, cursor:'pointer' }} onClick={this.handleUploadImageAction}>
            <Image src={this.state.image || '/upload-icon.svg'} rounded style={{padding: 4, width:'100%' }} />
            {!this.state.image && <p>{text.profilePicture[this.props.language]}</p>}
          </div>
        </Col>
        <Col sm={10}>
          <Col sm={6}>
            <FormGroup controlId={'addName'}>
                <ControlLabel>{text.nameForm[this.props.language]}</ControlLabel>
                <FormControl type='text' placeholder='Nome completo' value={this.state.name} onChange={event=> this.setState({name:event.target.value})} />
            </FormGroup>
            <FormGroup controlId={'addServices'}>
              <Col sm={12}>
              <ControlLabel>{text.services[this.props.language]}</ControlLabel>
              </Col>
              <Col sm={8}>
              <Checkbox checked={!!this.state.personalColoringAvailable} onChange={event=>this.setState({personalColoringAvailable:event.target.checked})}
              >{text.personalColoring[this.props.language]}</Checkbox>
              </Col>
              <Col sm={4}>
                <FormControl type='number' min='0' step='0.01' placeholder='R$ 00,00' value={this.state.personalColoringPrice} onChange={event=> this.setState({personalColoringPrice: event.target.value}) }
                />
              </Col>
              <Col sm={8}>
                <Checkbox checked={!!this.state.onlineConsultingAvailable} onChange={event=>this.setState({onlineConsultingAvailable:event.target.checked})}
                >{text.onlineImageConsulting[this.props.language]}</Checkbox>
              </Col>
              <Col sm={4}>
                <FormControl type='number' min='0' step='0.01' placeholder='R$ 00,00' value={this.state.onlineConsultingPrice} onChange={event=> this.setState({onlineConsultingPrice: event.target.value}) }
                />
              </Col>
              <Col sm={8}>
                <Checkbox  checked={!!this.state.moodboardAvailable} onChange={event=>this.setState({moodboardAvailable:event.target.checked})}
                >{text.moodboard[this.props.language]}</Checkbox>
              </Col>
              <Col sm={4}>
                <FormControl type='number' min='0' step='0.01' placeholder='R$ 00,00'  value={this.state.moodboardPrice} onChange={event=> this.setState({moodboardPrice: event.target.value}) } />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <Col sm={6}>
              <FormGroup controlId={'addState'}>
                <ControlLabel>{text.state[this.props.language]}</ControlLabel>
                <FormControl componentClass='select' value={this.state.state} onChange={event=> this.setState({state:event.target.value})}>
                  <option value='AC'>AC</option>
                  <option value='AL'>AL</option>
                  <option value='AP'>AP</option>
                  <option value='AM'>AM</option>
                  <option value='BA'>BA</option>
                  <option value='CE'>CE</option>
                  <option value='DF'>DF</option>
                  <option value='ES'>ES</option>
                  <option value='GO'>GO</option>
                  <option value='MA'>MA</option>
                  <option value='MT'>MT</option>
                  <option value='MS'>MS</option>
                  <option value='MG'>MG</option>
                  <option value='PA'>PA</option>
                  <option value='PB'>PB</option>
                  <option value='PR'>PR</option>
                  <option value='PE'>PE</option>
                  <option value='PI'>PI</option>
                  <option value='RJ'>RJ</option>
                  <option value='RN'>RN</option>
                  <option value='RS'>RS</option>
                  <option value='RO'>RO</option>
                  <option value='RR'>RR</option>
                  <option value='SC'>SC</option>
                  <option value='SP'>SP</option>
                  <option value='SE'>SE</option>
                  <option value='TO'>TO</option>
                </FormControl>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup controlId={'addCity'}>
              <ControlLabel>{text.city[this.props.language]}</ControlLabel>
              <FormControl type='text' placeholder='Cidade aqui' value={this.state.city} onChange={event=> this.setState({city:event.target.value})}/>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup controlId={'addPhone'}>
              <ControlLabel>{text.cell[this.props.language]}</ControlLabel>
              <FormControl type='text' placeholder='(xx) xxxxx-xxxx' value={this.state.phone} onChange={event => {
                const matches = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/)
                const phone = !matches[2] ? matches[1] : `(${matches[1]}) ${matches[2]}` + (matches[3] ? `-${matches[3]}` : '')

                this.setState({phone})}
                }/>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup controlId={'addInstagram'}>
              <ControlLabel>{text.instagram[this.props.language]}</ControlLabel>
              <FormControl type='text' placeholder='@instagram' value={this.state.instagram} onChange={event=> this.setState({instagram:event.target.value})} />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup controlId={'addEmail'}>
              <ControlLabel>{text.email[this.props.language]}</ControlLabel>
              <FormControl placeholder='E-mail aqui' value={this.state.email} onChange={event=> this.setState({email:event.target.value})} />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup controlId={'addSite'}>
              <ControlLabel>{text.site[this.props.language]}</ControlLabel>
              <FormControl placeholder='Site aqui' value={this.state.website} onChange={event=> this.setState({website:event.target.value})} />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup controlId={'addVideoCode'}>
              <ControlLabel>{text.videoCode[this.props.language]}</ControlLabel>
              <FormControl placeholder='Código aqui' value={this.state.videoCode} onChange={event=> this.setState({videoCode:event.target.value})} />
              <HelpBlock className='text-muted'>Inserir apenas a parte do código após o 'watch?v='. Ex: www.youtube.com/watch?v=InserirEsseCodigo</HelpBlock>
              </FormGroup>
            </Col>
          </Col>
          <Col sm={12}>
            <FormGroup controlId={'addDescription'}>
            <ControlLabel>{text.description[this.props.language]}</ControlLabel>
            <FormControl componentClass='textarea' rows={8} multiline placeholder='Inserir descrição da consultora aqui' value={this.state.description} onChange={event=> this.setState({description:event.target.value})} maxLength={1000} />
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup controlId={'addQualification'}>
            <ControlLabel>{text.qualification[this.props.language]}</ControlLabel>
            <FormControl componentClass='textarea' rows={8} multiline placeholder='Inserir formação da consultora aqui' value={this.state.qualification} onChange={event=> this.setState({qualification:event.target.value})} maxLength={1000} />
            </FormGroup>
          </Col>
          <Col sm={12}>
            <FormGroup controlId={'addActivate'} onChange={event => this.setState({active:'true' === event.target.value})}>
            <ControlLabel>{text.enabledConsultantView[this.props.language]}</ControlLabel>
            <Radio name='active' value={true} checked={this.state.active}>{text.yes[this.props.language]}</Radio>
            <Radio name='active' value={false} checked={!this.state.active}>{text.no[this.props.language]}</Radio>
            </FormGroup>
          </Col>
          <Col sm={10}></Col>
          <Col sm={2}>
            <ButtonToolbar>
              <Button bsSize='large' bsStyle='primary' type='submit'>{text.save[this.props.language]}</Button>
            </ButtonToolbar>
          </Col>
        </Col>
      </Form>
      </div>
    )
  }
}

class SubscriptionsTab extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      subscriptions: this.props.subscriptions ? this.props.subscriptions : [],
      isLoadingSubscriptions: false,
      subscriptionToCreate: '',
      subscriptionExtension: '',
      showModal: false,
      modalType: '',
      subscriptionToCreateCouponCode: ''
    }
  }

  resolveColor = (status, validUntilDay, nextBillingDay, planIdentifier) => {
    if (planIdentifier === 1 && status === 'awaitingCancel') {
      return '#A9D3FF'
    }
    if (status === 'active') {
      return '#B8E99C'
    }
    if (status === 'active' && validUntilDay && moment().diff(validUntilDay) < 0) {
      return '#EEA25A'
    }
    if (status === 'awaitingCancel' && nextBillingDay === null) {
      return '#B8E99C'
    }
    if (status === 'awaitingCancel') {
      return '#fff9c2'
    }
    if (status === 'cancelled') {
      return '#FFA5B2'
    }
  }

  resolveStatusName = (status, nextBillingDay, planIdentifier) => {
    if (status === 'active') {
      return 'Ativo'
    }
    if (status === 'awaitingCancel' && nextBillingDay === null && planIdentifier !== 1) {
      return 'Liberado Manualmente'
    }
    if (status === 'awaitingCancel') {
      return 'Aguardando Cancelamento'
    }
    if (status === 'cancelled') {
      return 'Cancelado'
    }
  }

  handleModal = () => { this.setState({ showModal: !this.state.showModal, modalType: '' }) }

  renderCreateSubscriptionModal = () => {
    return (
      <Modal show onHide={this.handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Deseja criar uma assinatura para {this.props.userData.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza de que deseja criar uma assinatura de {' '}
            <b>{plans.find(plan => plan.planId === parseInt(this.state.subscriptionToCreate)).duration}</b>
            {' '} do plano {' '}
            <b>{plans.find(plan => plan.planId === parseInt(this.state.subscriptionToCreate)).planName}</b>
            {this.state.subscriptionToCreateCouponCode !== '' && ` com o cupom ${this.state.subscriptionToCreateCouponCode}`}
            {' '} para {this.props.userData.name}? <br/>
            Isso irá cancelar <b>TODAS</b> as assinaturas anteriores dessa consultora.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle='success' onClick={this.handleCreateSubscriptionSubmit}>Criar Assinatura</Button>
          <Button bsStyle='primary' onClick={this.handleModal}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  resolveExtensionPeriodName = (period) => {
    if (period === 7) {
      return 'em 7 dias'
    }
    if (period === 1) {
      return 'em 1 mês'
    }
    if (period === 3) {
      return 'em 3 meses'
    }
    if (period === 6) {
      return 'em 6 meses'
    }
    if (period === 12) {
      return 'em 1 ano'
    }
    if (period === 999) {
      return 'até 28/03/2031'
    }
  }

  renderExtendSubscriptionModal = () => {
    return (
      <Modal show onHide={this.handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Deseja estender uma assinatura para {this.props.userData.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Tem certeza de que deseja estender a assinatura
            {' '} do plano {' '}
            <b>{resolvePlanName(this.state.subscriptions[0].planIdentifier)}</b> {' '}
            <b>{this.resolveExtensionPeriodName(parseInt(this.state.subscriptionExtension))}</b>
            {' '} para {this.props.userData.name}? <br/>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle='success' onClick={this.handleExtendSubscriptionSubmit}>Estender Assinatura</Button>
          <Button bsStyle='primary' onClick={this.handleModal}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    )
  }
  
  renderScheduleSubscriptionCancellationModal = () => {
    return (
      <Modal show onHide={this.handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Deseja agendar o cancelamento de uma assinatura para {this.props.userData.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Tem certeza de que deseja agendar o cancelamento da assinatura de {this.props.userData.name}? <br/>
            O cancelamento será realizado no dia seguinte à validade da assinatura.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle='success' onClick={this.handleScheduleSubscriptionCancellationSubmit}>Agendar Cancelamento</Button>
          <Button bsStyle='primary' onClick={this.handleModal}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderCancelSubscriptionModal = () => {
    return (
      <Modal show onHide={this.handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Deseja cancelar todas as assinaturas de {this.props.userData.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Tem certeza de que deseja cancelar o acesso de {this.props.userData.name} ao aplicativo? <br/>
            O efeito desta ação será imediato.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle='success' onClick={this.handleCancelationSubscriptionSubmit}>Cancelar Assinatura</Button>
          <Button bsStyle='primary' onClick={this.handleModal}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  resolveModalToRender = () => {
    if (this.state.showModal === true) {
      if (this.state.modalType === 'createSubscription') {
        return this.renderCreateSubscriptionModal()
      }
      if (this.state.modalType === 'extendSubscription') {
        return this.renderExtendSubscriptionModal()
      }
      if (this.state.modalType === 'scheduleSubscriptionCancellation') {
        return this.renderScheduleSubscriptionCancellationModal()
      }
      if (this.state.modalType === 'cancelSubscription') {
        return this.renderCancelSubscriptionModal()
      }

    }
  }

  handleCreateSubscriptionSubmit = async () => {
    this.setState({ isLoadingSubscriptions: true })
    this.handleModal()

    await rp({
      uri: `${uriV2}/subscription/admin/user/${this.props.userId}`,
      method: 'POST',
      headers: {
        Authorization: this.props.token
      },
      body: {
        planId: this.state.subscriptionToCreate,
        couponCode: this.state.subscriptionToCreateCouponCode ? this.state.subscriptionToCreateCouponCode : null
      },
      json: true
    })
      .then(data => {
        this.setState({ subscriptions: data })
      })
      .catch(err=> err.statusCode===404 && this.setState({ subscriptions: [] }))
      .finally(() => this.setState({ isLoadingSubscriptions: false }))
  }

  handleExtendSubscriptionSubmit = async () => {
    this.setState({ isLoadingSubscriptions: true })
    this.handleModal()

    await rp({
      uri: `${uriV2}/subscription/admin/user/${this.props.userId}/extend`,
      method: 'PATCH',
      headers: {
        Authorization: this.props.token
      },
      body: {
        extension: this.state.subscriptionExtension
      },
      json: true
    })
      .then(data => {
        this.setState({ subscriptions: data })
      })
      .catch(err=> err.statusCode===404 && this.setState({ subscriptions: [] }))
      .finally(() => this.setState({ isLoadingSubscriptions: false }))
  }

  handleScheduleSubscriptionCancellationSubmit = async () => {
    this.setState({ isLoadingSubscriptions: true })
    this.handleModal()

    await rp({
      uri: `${uriV2}/subscription/admin/user/${this.props.userId}/cancel/schedule`,
      method: 'PATCH',
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(data => {
        this.setState({ subscriptions: data })
      })
      .catch(err=> err.statusCode===404 && this.setState({ subscriptions: [] }))
      .finally(() => this.setState({ isLoadingSubscriptions: false }))
  }

  handleCancelationSubscriptionSubmit = async () => {
    this.setState({ isLoadingSubscriptions: true })
    this.handleModal()

    await rp({
      uri: `${uriV2}/subscription/admin/user/${this.props.userId}/cancel`,
      method: 'PATCH',
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(data => {
        this.setState({ subscriptions: data })
      })
      .catch(err=> err.statusCode===404 && this.setState({ subscriptions: [] }))
      .finally(() => this.setState({ isLoadingSubscriptions: false }))
  }

  handleFormAttribute = (e, attribute) => {
    const obj = this.state
    obj[attribute] = e.target.value
    this.setState({ obj })
  }

  render () {
    return (
      this.state.isLoadingSubscriptions ? 
        <div id='centralSpinnerHolder'>
          <Loader
            type='TailSpin'
            color='#000'
            height='50'
            width='50'
          />
        </div> :
      <div>
        <h4>Edição da última assinatura:</h4>

        <Row style={{marginRight: 16, marginLeft: 16, marginTop: 16}}>

          <Panel id='subscriptionPanel'>

            <Tabs defaultActiveKey={1} id='subscriptionTabs'>
              <Tab eventKey={1} title='Criar Assinatura'>
                <Row style={{marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8}}>
                  <p>Cria uma assinatura e cancela todas as outras anteriores da consultora. O plano define qual será o tempo liberado (Teste Grátis = 5 dias, trimestral = 3 meses, semestral = 6 meses e todas as outras = 1 mês).</p>
                  <Form style={{ margin: 16 }} onSubmit={this.handleCreateSubscriptionSubmit}>
                    <Col sm={8}>
                      <FormGroup controlId={'createSubscription'}>
                        <Col sm={6}>
                          <FormControl componentClass='select' onChange={(e) => this.handleFormAttribute(e, 'subscriptionToCreate')} value={this.state.subscriptionToCreate}>
                            <option value=''>Selecione o Plano</option>
                            {plans.map((plan) => {
                              return (
                                <option key={plan.planId} value={plan.planId}>{plan.planName}</option>
                              )
                            })}
                          </FormControl>
                        </Col>
                        <Col sm={6}>
                          <FormControl componentClass='select' onChange={(e) => this.handleFormAttribute(e, 'subscriptionToCreateCouponCode')} value={this.state.subscriptionToCreateCouponCode}>
                            <option value=''>Selecione o Cupom</option>
                            {freeMonthCoupons.map((coupon, index) => {
                              return (
                                <option key={index} value={coupon}>{coupon}</option>
                              )
                            })}
                          </FormControl>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <ButtonToolbar>
                        <Button 
                          bsStyle='success' 
                          onClick={() => {
                            if (this.state.subscriptionToCreate !== '') {
                              this.setState({ showModal: true, modalType: 'createSubscription' })
                            }
                          }}
                        >
                          Criar Assinatura</Button>
                      </ButtonToolbar>
                    </Col>
                    
                  </Form>
                </Row>
              </Tab>
              <Tab eventKey={2} title='Estender Assinatura'>
                <Row style={{marginRight: 16, marginLeft: 16, marginTop: 8}}>
                  <p>Estende a validade de uma assinatura de acordo com o período selecionado. Caso o pagamento seja integrado com o sistema, a assinatura terá a próxima cobrança atrasada de acordo com o período selecionado.</p>
                  <Form style={{ margin: 16 }} onSubmit={this.handleSubscriptionExtensionSubmit}>
                    <Col sm={6}>
                      <FormGroup controlId={'subscriptionExtension'}>
                        <FormControl componentClass='select' onChange={(e) => this.handleFormAttribute(e, 'subscriptionExtension')} value={this.state.subscriptionExtension}>
                          <option>Selecione o período</option>
                          <option value="7">7 dias</option>
                          <option value="1">1 mês</option>
                          <option value="3">3 meses</option>
                          <option value="6">6 meses</option>
                          <option value="12">1 ano</option>
                          <option value="999">até 28/03/2031</option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <ButtonToolbar>
                        <Button 
                          bsStyle='info'
                          onClick={() => {
                            if (this.state.subscriptionExtension !== '') {
                              this.setState({ showModal: true, modalType: 'extendSubscription' })
                            }
                          }}
                          
                        >Estender Validade</Button>
                      </ButtonToolbar>
                    </Col>
                  </Form>
                </Row>
              </Tab>
              <Tab eventKey={3} title='Cancelar Assinatura'>
                <Row style={{marginRight: 16, marginLeft: 16, marginTop: 16, marginBottom: 16}}>
                  <p>
                    <b>Cancelar Assinatura:</b> cancela imediatamente todas as assinaturas da consultora
                    <br/>
                    <b>Agendar Cancelamento:</b> muda o status da última assinatura para "Aguardando Cancelamento". A assinatura é cancelada no dia seguinte ao dia da validade.
                    </p>
                  <Col sm={6}>
                    <Button 
                      bsStyle='warning'
                      onClick={() => {
                        this.setState({ showModal: true, modalType: 'scheduleSubscriptionCancellation' })
                      }}
                    >
                      Agendar Cancelamento
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button 
                      bsStyle='danger'
                      onClick={() => {
                        this.setState({ showModal: true, modalType: 'cancelSubscription' })
                      }}
                    >
                      Cancelar Assinatura
                    </Button>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Panel>
          {this.resolveModalToRender()}
        </Row>
        <hr/>
        <div style={{ textAlign: 'left'}}>
          <div style={{ marginTop: 8, marginBottom: 8, display: 'flex', marginLeft: 16, marginRight: 16, }}>
            <span>Legendas</span>
          </div>
          <div>
            <Row style={{ display: 'flex', marginLeft: 16, marginRight: 16 }}>
              <Col sm={3}>
                <div style={{display: 'inline-flex'}}>
                  <div 
                    style={{
                      height: '20px',
                      width: '20px',
                      backgroundColor: '#B8E99C',
                      marginRight: 8
                    }}
                  /> 
                  <span>Ativo</span>
                </div>
              </Col>
              <Col sm={3}>
                <div style={{display: 'inline-flex'}}>
                  <div 
                    style={{
                      height: '20px',
                      width: '20px',
                      backgroundColor: '#fff9c2',
                      marginRight: 8
                    }}
                  /> 
                  <span>Aguardando Cancelamento</span>
                </div>
              </Col>
              <Col sm={3}>
                <div style={{display: 'inline-flex'}}>
                  <div 
                    style={{
                      height: '20px',
                      width: '20px',
                      backgroundColor: '#FFA5B2',
                      marginRight: 8
                    }}
                  /> 
                  <span>Cancelado</span>
                </div>
              </Col>
              <Col sm={3}>
                <div style={{display: 'inline-flex'}}>
                  <div 
                    style={{
                      height: '20px',
                      width: '20px',
                      backgroundColor: '#A9D3FF',
                      marginRight: 8
                    }}
                  /> 
                  <span>Teste</span>
                </div>
              </Col>
            </Row>
            <Row style={{ display: 'flex', marginLeft: 16, marginRight: 16 }}>
              <Col sm={3}>
                <div style={{display: 'inline-flex'}}>
                  <div 
                    style={{
                      height: '20px',
                      width: '20px',
                      backgroundColor: '#EEA25A',
                      marginRight: 8
                    }}
                  /> 
                  <span>Contatar equipe tecnologia</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <hr/>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Plano</th>
              <th>Status</th>
              <th>Próxima Cobrança</th>
              <th>Validade</th>
              <th>Data Criação</th>
              <th>Cupom</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.subscriptions.map((subscription, index) => (
                <tr key={index} style={{backgroundColor: this.resolveColor(subscription.status, subscription.validUntilDay, subscription.nextBillingDay, subscription.planIdentifier)}} onClick={() => this.props.history.push(`/consultant/${subscription.userId}`)}>
                  {/* <Link to='' className='nome'>{subscription.userId} - Criado: {moment(subscription.createdAt).format('DD/MM/YY')}</Link> */}
                  <td>{subscription.id}</td>
                  <td>{resolvePlanName(subscription.planIdentifier)}</td>
                  <td>{this.resolveStatusName(subscription.status, subscription.nextBillingDay, subscription.planIdentifier)}</td>
                  <td>{subscription.nextBillingDay && moment(subscription.nextBillingDay).format('DD/MM/YY')}</td>
                  <td>{subscription.validUntilDay && moment(subscription.validUntilDay).format('DD/MM/YY')}</td>
                  <td>{subscription.createdAt && moment(subscription.createdAt).format('DD/MM/YY')}</td>
                  <td>{subscription.couponCode}</td>
                </tr>
              )) 
            }
          </tbody>
        </Table>
      </div>
    )
  }
  
}

class ConsultantSettings extends React.Component {
	constructor (props) {
		super(props)

		this.state = { loading: true, userData: {}, language: 1 }
	}

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') - '0' })
  }

	componentDidMount () {
    const loadStyleGame = async () => rp({
      uri: `${uriV2}/user/${this.props.userId}/game/profile`,
      method: 'GET',
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(data => {
        this.setState({ styleGame: {...data} })
      })
      .catch(err=> err.statusCode===404 && this.setState({ styleGame: {} }))
    
    const loadUserSubscriptions = async () => rp({
      uri: `${uriV2}/subscription/admin/list/user/${this.props.userId}`,
      method: 'GET',
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(data => {
        this.setState({ subscriptions: data })
      })
      .catch(err=> err.statusCode===404 && this.setState({ subscriptions: [] }))
    // Load everything about this user!
    const loadUserData = async () => rp({
      uri: `${uri}/user?id=${this.props.userId}`,
      method: 'GET',
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(async user => {
        if (!user) {
          this.props.history.push('/')
          return
        }

        this.props.setSelectedUserId(user.id)

        const userPhone = (user.phone || '').replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/)
        const phoneString = !userPhone[2] ? userPhone[1] : '(' + userPhone[1] + ') ' + userPhone[2] + (userPhone[3] ? '-' + userPhone[3] : '')

        const userZipCode = (user.zipCode || '').replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/)
        const zipCodeString = !userZipCode[2] ? userZipCode[1] : userZipCode[1] + '-' + userZipCode[2]

        const birthDate = new Date(user.birth)
        const correctedBirthDate = new Date(birthDate.getTime() + birthDate.getTimezoneOffset() * 60000)
        this.setState({
          userData: {
            name: user.name,
            birth: correctedBirthDate,
            phone: phoneString,
            streetName: user.streetName,
            streetNumber: user.streetNumber || '',
            complement: user.complement || '',
            zipCode: zipCodeString,
            city: user.city || '',
            state: user.state,
            plan: user.plan
          }
        })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      

    Promise.all([loadStyleGame(),loadUserData(), loadUserSubscriptions()])
      .catch(err => {
        console.log('Error loading media', err)
        this.props.setError(text.loadError[this.state.language], text.someError[this.state.language])
      })
      .finally(()=> this.setState({ loading: false }))
  }
  render() {
    if ( this.state.loading ) {
      return (
				<div id='centralSpinnerHolder'>
					<Loader
						type='TailSpin'
						color='#000'
						height='50'
						width='50'
					/>
				</div>
      )
    }

    return (
      <Panel id='clientPanel'>
        <Panel.Heading>
          <h4>{this.state.userData.name}</h4>
        </Panel.Heading>
				<Tabs defaultActiveKey={3} id='consultantTabs'>
          <Tab eventKey={1} title='Informações de cadastro'>
            <ConfigTab {...this.props} userData={this.state.userData} />
          </Tab>
          <Tab eventKey={2} title='Perfil da consultora'>
            <StyleGameTab {...this.props} language={this.state.language} styleGame={this.state.styleGame}/>
          </Tab>
          <Tab eventKey={3} title='Assinaturas da consultora'>
            <SubscriptionsTab {...this.props} language={this.state.language} subscriptions={this.state.subscriptions} userData={this.state.userData}/>
          </Tab>
			  </Tabs>
			</Panel>
    )

  }
} 

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer,
    ...state.usersReducer,
    selectedUser: selectedUser(state)
  }
}
export default connect(mapStateToProps,actions)(ConsultantSettings) 
