export const setAdmin = (admin) => {
  return {
    type: 'SET_ADMIN',
    admin
  }
}

export const setToken = (token) => {
  return {
    type: 'SET_TOKEN',
    token: token
  }
}

export const logUser = (login) => {
  return {
    type: 'LOG_USER',
    login
  }
}

export const putUser = (user) => {
  console.log('HIEU')
  return {
    type: 'PUT_USER',
    user: user
  }
}

export const setUserAttribute = (userId, attribute) => {
  return {
    type: 'SET_USER_ATTRIBUTE',
    userId,
    attribute
  }
}

export const setUserMediaAttribute = (userId, attribute) => {
  return {
    type: 'SET_USER_MEDIA_ATTRIBUTE',
    userId,
    attribute
  }
}

export const setUsers = (users) => {
  return {
    type: 'SET_USERS',
    users
  }
}

export const setSubscriptions = (subscriptions, users, filters) => {
  return {
    type: 'SET_SUBSCRIPTIONS',
    subscriptions,
    users,
    filters
  }
}

export const setUsersFilter = (filter) => {
  return {
    type: 'SET_USERS_FILTER',
    filter
  }
}

export const setSelectedUserId = (selectedUserId) => {
  return {
    type: 'SET_SELECTED_USER_ID',
    selectedUserId
  }
}

export const removeUser = (userId) => {
  return {
    type: 'REMOVE_USER',
    userId
  }
}

export const setError = (title, message) => {
  if (!title || !message) {
    return {
      type: 'SET_ERROR',
      error: null
    }
  }

  return {
    type: 'SET_ERROR',
    error: {
      title,
      message
    }
  }
}

export const dismissError = () => {
  return {
    type: 'DISMISS_ERROR'
  }
}

export const setConsultantsFilter = (filter) => {
  return {
    type: 'SET_CONSULTANTS_FILTER',
    filter
  }
}
