
const adminReducer = (state = { token: null, user: {} }, action) => {
  switch (action.type) {
    case 'SET_ADMIN':
      return {
        ...state,
        admin: action.admin
      }
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.token
      }
    case 'LOG_USER':
      return {
        ...state,
        user: action.login.user,
        token: action.login.token
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.error
      }
    case 'DISMISS_ERROR':
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}

export default adminReducer
