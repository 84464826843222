export default
{
  fillFields: ['Preencha todos os campos obrigatórios para atualizar o cliente!', 'Fill all the required fields to update client.', 'Rellene todos los campos obligatorios para actualizar el cliente!'],
  updateSucess: ['Usuário atualizado com sucesso!', 'Client updated successfully!', 'Usuario actualizado con éxito!'],
  someError: ['Algum erro ocorreu ao atualizar os dados do cliente!', 'Some error occurred while updating client data!', 'Algún error ocurrió al actualizar los datos del cliente!'],
  wppError: ['Algum erro ocorreu ao enviar o convite para a cliente!', 'Some error occurred while sending invitation to client!', 'Se produjo un error al enviar la invitación al cliente!'],
  writeName: ['Digite o nome correto para excluir!', 'Enter the correct name to delete!', 'Escriba el nombre correcto para eliminar!'],
  deleteSucess: ['Usuário excluído com sucesso!', 'User deleted successfully!', 'Usuario eliminado con éxito!'],
  name: ['Nome', 'Name', 'Nombre'],
  adress: ['Endereço', 'Adress', 'Dirección'],
  street: ['Rua *', 'Street *', 'Calle *'],
  number: ['Número *', 'Number *', 'Número *'],
  complement: ['Complemento', 'Complement', 'Complement'],
  city: ['Cidade *', 'City *', 'Ciudad *'],
  state: ['Estado', 'State', 'Estado'],
  zip: ['CEP *', 'ZIP *', 'Código postal *'],
  birthday: ['Aniversário *', 'Birthday *', 'Cumpleaños *'],
  cell: ['Celular *', 'Phone *', 'Celular *'],
  deleteClient: ['Excluir Cliente', 'Delete Client', 'Eliminar Cliente'],
  deleteConfirm: ['Tem certeza que deseja excluir todos os dados do cliente', 'Are you sure you want to delete all the data of', '¿Está seguro de que desea eliminar todos los datos del cliente'],
  deleteConfirm2: ['permanentemente? Esta ação não pode ser desfeita!', 'permanently? This action can not be undone!', 'de forma permanente? ¡Esta acción no puede ser deshecha!'],
  enterName: ['Digite o nome do cliente para confirmar a exclusão', 'Enter the client name to confirm the deletion', 'Escriba el nombre del cliente para confirmar la eliminación'],
  update: ['Atualizar Cadastro', 'Update Profile', 'Actualizaciones'],
  sendInvite: ['Enviar Convite', 'Send Invitation', 'Enviar Invitación'],
  sendingInvite: ['Enviando...', 'Sending...', 'Enviando...'],
  national: ['Nacional', 'National', 'Nacional'],
  foreign: ['Estrangeiro', 'Foreign', 'Extranjero']
}
