import React from 'react'

class Detail extends React.Component {
  handleCloseClick = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.close()
  }

  handleDownloadClick = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.onDownload(this.props.file)
  }

  handleDeleteClick = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.onDelete(this.props.file).then(() => this.props.close())
  }

  render () {
    let name = this.props.file.key.split('/')
    name = name.length ? name[name.length - 1] : ''

    return (
      <div>
        <h3>{name}</h3>
        <a href='#' onClick={this.handleDownloadClick}>Baixar</a>
        <br />
        <a href='#' onClick={this.handleDeleteClick}>Deletar</a>
        <br />
        <a href='#' onClick={this.handleCloseClick}>Fechar</a>
      </div>
    )
  }
}

export default Detail
