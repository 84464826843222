export default
{
  userNFound: ['usuário não encontrado', 'user not found', 'usuario no encontrado'],
  missCredentials: ['email e/ou senha inválido!', 'wrong username and/or password', 'correo electrónico y / o contraseña no válida'],
  recoveryPassword: ['recuperação de senha enviada para o seu e-mail!', 'password recovery sent to your email account', 'la recuperación de la contraseña enviada a su correo electrónico!'],
  acessAccount: ['acesse sua conta', 'access your account', 'accede a su cuenta'],
  yourMail: ['seu email', 'your email', 'su e-mail'],
  yourPass: ['sua senha', 'your pass', 'su contraseña'],
  forgetPass: ['esqueceu sua senha?', 'forgot password?', '¿Olvidaste tu contraseña?'],
  forgotPass: ['esqueci minha senha', 'forgot password', 'olvide mi contraseña'],
  sent: ['Enviado!', 'Sent!', 'Enviado!'],
  recovery: ['Recuperar senha', 'Recovery Pass', 'Recuperar contraseña'],
  back: ['Voltar', 'Back', 'Volver']
}
