import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'

import { connect } from 'react-redux'
import { ButtonToolbar, Form, FormControl, FormGroup, ListGroupItem, ListGroup, Panel, Grid, Row, Col, Button, Modal, ControlLabel, Checkbox, Table, Tabs, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import rp from 'request-promise-native'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'

import * as EmailValidator from 'email-validator'

import DatePicker from 'react-date-picker'
import Interface from '../containers/Interface'
import * as actions from '../redux/actions'
import { filteredUsers, filteredConsultants } from '../services/selectors'
import { uri, auth, uriV2 } from '../services/defaultProps'

import text from '../constants/Lista'
import textColoring from '../constants/Coloracao'
import textPerfil from '../constants/Perfil'

import XLSX from 'xlsx'

import { resolvePlanName } from '../services/helpers'

class Lista extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showAddClient: false,
      newClient: {
        name: '',
        email: '',
        streetName: '',
        streetNumber: '',
        complement: '',
        zipCode: '',
        city: '',
        state: 'AC',
        birth: new Date(),
        phone: ''
      },
      errorFields: {
        email: null
      },
      loadingClientsList: false,
      loadingAddClient: false,
      showingConsultants: this.props.user.auth === auth.admin,
      language: 1,
      showModal: false,
      urlSrc: '',
      clientMode: 'national',
      selectedList: 'consultants',
      loadingSubscriptionsList: false,
      cancelledFilter: true,
      couponFilter: true,
      activeFilter: true,
      manualActiveFilter: true,
      testFilter: true,
      awaitingCancelFilter: true,
      sortField: 'createdAt',
      sortOrientation: 'DESC',
      subscriptions: []
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') - '0' })
  }

  componentDidMount () {
    const onboarding = localStorage.getItem('@mosty-app/onboarding')
    if (onboarding === null) {
      localStorage.setItem('@mosty-app/onboarding', true)
      this.setState({ showModal: true, urlSrc: 'https://www.youtube.com/embed/L16s7ViHzRQ' })
      console.log(onboarding)
    }

    this.setState({ loadingClientsList: true })
    this.setState({ loadingSubscriptionsList: true })
    rp({
      uri: `${uriV2}/user`,
      method: 'GET',
      headers: {
        Authorization: this.props.token
      },
      json: true
    })
      .then(users => {
        this.setState({ loadingClientsList: false })
        this.props.setUsers(users.sort((a, b) => a.name.localeCompare(b.name)))
        rp({
          uri: `${uriV2}/subscription/admin/list`,
          method: 'GET',
          headers: {
            Authorization: this.props.token
          },
          json: true
        })
          .then(subscriptions => {
            this.props.setSubscriptions(subscriptions, users)
            this.handleSubscriptionFilter(subscriptions)
            this.setState({ loadingSubscriptionsList: false })
          })
          .catch(err => {
            // if (err.statusCode === 401) {
            //   this.props.setToken(null)
            // }
            throw err
          })
          .catch(err => {
            console.log('Error loading subscriptions list', err)
            this.setState({ loadingSubscriptionsList: false })
          })
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error loading clients list', err)
        this.setState({ loadingClientsList: false })
      })
    
  }

  handleSearchChange = (e) => {
    this.props.setUsersFilter(e.target.value)
  }

  handleConsultantSearchChange = (e) => {
    this.props.setConsultantsFilter(e.target.value)
  }

  handleShowAddClient = (force = null) => {
    this.setState({ showAddClient: force !== null ? force : !this.state.showAddClient })
  }

  handleAddClient = () => {
    const { clientMode } = this.state

    if (this.state.loadingAddClient) return

    if (!this.state.newClient.name ||
      !this.state.newClient.email ||
      !this.state.newClient.streetName ||
      !this.state.newClient.streetNumber ||
      !this.state.newClient.city ||
      (clientMode === 'national' && !this.state.newClient.state) ||
      !this.state.newClient.zipCode ||
      !this.state.newClient.phone) {
      toast(text.fillFields[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    const { errorFields } = this.state

    if (!EmailValidator.validate(this.state.newClient.email)) {
      toast(text.emailInvalidError[this.state.language], {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_RIGHT
      })

      this.setState({ errorFields: { ...errorFields, email: 'error' } })
      return
    }

    const body = {
      ...this.state.newClient,
      birth: moment(this.state.newClient.birth).format('YYYY-MM-DD'),
      phone: this.state.newClient.phone.replace(/\D+/g, ''),
      zipCode: this.state.newClient.zipCode.replace(/\D+/g, ''),
      auth: this.state.showingConsultants ? auth.waitingConsultant : auth.unverified
    }

    this.setState({ loadingAddClient: true })
    rp({
      uri: `${uri}/user`,
      method: 'POST',
      headers: {
        Authorization: this.props.token
      },
      body: body,
      json: true
    })
      .then(user => {
        this.handleShowAddClient(false)
        this.props.putUser(user)
        this.setState({
          loadingAddClient: false,
          newClient: {
            name: '',
            email: '',
            streetName: '',
            streetNumber: '',
            complement: '',
            zipCode: '',
            city: '',
            state: 'AC',
            birth: new Date(),
            phone: ''
          }
        })
        toast(text.userAdded[this.state.language], {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT
        })
        const onboarding = localStorage.getItem('@mosty-app/first-client')
        if (onboarding === null) {
          localStorage.setItem('@mosty-app/first-client', true)
          this.setState({ showModal: true, urlSrc: 'https://www.youtube.com/embed/iPB4FcUA324' })
          console.log(onboarding)
        }
      })
      .catch(err => {
        if (err.statusCode === 401) {
          this.props.setToken(null)
        }
        throw err
      })
      .catch(err => {
        console.log('Error adding client', err)
        this.setState({ loadingAddClient: false })

        if (err.statusCode === 400) {
          if (err.error === 'Email invalid') {
            toast(text.emailInvalidError[this.state.language], {
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_RIGHT
            })

            this.setState({ errorFields: { ...errorFields, email: 'error' } })
          } else if (err.error === 'Email not exists') {
            toast(text.emailNotExistsError[this.state.language], {
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_RIGHT
            })

            this.setState({ errorFields: { ...errorFields, email: 'error' } })
          } else {
            toast(text.someError[this.state.language], {
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_RIGHT
            })
          }
        } else if (err.statusCode === 409) {
          toast(text.emailRegisteredError[this.state.language], {
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT
          })

          this.setState({ errorFields: { ...errorFields, email: 'error' } })
        } else {
          toast(text.someError[this.state.language], {
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        }
      })
  }

  setShowingConsultants = (bool) => {
    this.props.setUsersFilter('')
    this.props.setConsultantsFilter('')
    this.setState({ showingConsultants: bool })
  }

  handleFormAttribute = (e, attribute) => {
    const obj = this.state.newClient
    obj[attribute] = e.target.value
    if (attribute === 'email') {
      obj[attribute] = e.target.value.trim()
      this.setState({
        errorFields: {
          ...this.state.errorFields,
          email: null
        }
      })
    }
    this.setState({ obj })
  }

  handleFormPhone = (value) => {
    const { clientMode } = this.state

    if (clientMode === 'national') {
      const x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/)

      this.setState((state) => ({
        newClient: {
          ...state.newClient, phone: !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
        }
      }))
    } else {
      this.setState((state) => ({
        newClient: {
          ...state.newClient,
          phone: value
        }
      }))
    }
  }

  handleFormZipCode = (value) => {
    const { clientMode } = this.state

    if (clientMode === 'national') {
      const x = value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/)

      this.setState((state) => ({
        newClient: {
          ...state.newClient, zipCode: !x[2] ? x[1] : x[1] + '-' + x[2]
        }
      }))
    } else {
      this.setState((state) => ({
        newClient: {
          ...state.newClient,
          zipCode: value
        }
      }))
    }
    
  }

  handleClientMode(mode) {
    this.setState({
      clientMode: mode
    }, () => {
      this.handleFormZipCode(this.state.newClient.zipCode)
      this.handleFormPhone(this.state.newClient.phone)
    })
  }

  modalOnboarding = () => (
    <Modal show={this.state.showModal} centered onHide={() => this.handleCloseModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Seja Bem Vinda(o)!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className='video'
          style={{
            position: 'relative',
            paddingBottom: '56.25%' /* 16:9 */,
            paddingTop: 25,
            height: 0
          }}
        >
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
            src={this.state.urlSrc}
            frameBorder='0'
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <a target='_blak' href='https://api.whatsapp.com/send?phone=5511994516093&text=Ol%C3%A1%2C%20fiz%20o%20cadastro%20na%20plataforma%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20MOSTY'><img style={{ width: 28 }} src='whatsapp.svg' /></a>
          <a target='_blak' style={{ fontSize: 20, borderWidth: 1, borderStyle: 'solid', borderColor: '#215287', padding: 6, borderRadius: 4 }} variant='primary' href='https://www.youtube.com/watch?v=0FFUYvrlcXM&list=PL4VO5Ic8eMNlF73ohCvXofalvrAVU39vp&index=1'>
            Playlist Completa
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  )

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  handleExport () {
    // Export clients of a consultant if are logged in as consultant or export consultants if are logged in as admin

    const { language } = this.state
    const { user: admin, users } = this.props

    const options = [
      'name', 'email', 'street',
      'number', 'complement', 'city',
      'state', 'zip', 'birthday', 'cell',
      'coloring', 'silhouette'
    ]

    let table = (
      <table>
        <tr>
          {
            options.map((opt, i) => (
              <th key={i}>{text[opt][language].replace(' *', '')}</th>
            ))
          }
        </tr>
        {
          users.map((user, i) => (
            <tr key={i}>
              <td>{user.name}</td><td>{user.email}</td><td>{user.streetName}</td><td>{user.streetNumber}</td><td>{user.complement}</td>
              <td>{user.city}</td><td>{user.state}</td><td>{user.zipCode}</td><td>{moment(user.birth).format('DD/MM/YYYY')}</td>
              <td>{`(${user.phone.substring(0, 2)}) ${user.phone.substring(2, 7)}-${user.phone.substring(7, 11)}`}</td>
              <td>{user.personalColoring ? textColoring.seasons[this.state.language][user.personalColoring - 1] : ''}</td>
              <td>{user.silhouetteId ? textPerfil[`sil${user.silhouetteId}`][this.state.language] : ''}</td>
            </tr>
          ))
        }
      </table>
    )

    table = ReactDOMServer.renderToStaticMarkup(table)
    var workbook = XLSX.read(table, { type: 'string' })
    var filename = `${admin.auth === auth.admin ? `${text.consultant[language]}s` : `${text.cliente[language]}s`} - ${admin.name}.xlsx`
    XLSX.writeFile(workbook, filename)
  }


  resolveColor = (status, validUntilDay, nextBillingDay, planIdentifier) => {
    if (planIdentifier === 1 && status === 'awaitingCancel') {
      return '#A9D3FF'
    }
    if (status === 'active') {
      return '#B8E99C'
    }
    if (status === 'active' && validUntilDay && moment().diff(validUntilDay) < 0) {
      return '#EEA25A'
    }
    if (status === 'awaitingCancel' && nextBillingDay === null) {
      return '#B8E99C'
    }
    if (status === 'awaitingCancel') {
      return '#fff9c2'
    }
    if (status === 'cancelled') {
      return '#FFA5B2'
    }
  }

  resolveStatusName = (status, nextBillingDay, planIdentifier) => {
    if (status === 'active') {
      return 'Ativo'
    }
    if (status === 'awaitingCancel' && nextBillingDay === null && planIdentifier !== 1) {
      return 'Liberado Manualmente'
    }
    if (status === 'awaitingCancel') {
      return 'Aguardando Cancelamento'
    }
    if (status === 'cancelled') {
      return 'Cancelado'
    }
  }

  selectAllFilters = () => {
    this.setState({
      cancelledFilter: true,
      couponFilter: true,
      activeFilter: true,
      manualActiveFilter: true,
      testFilter: true,
      awaitingCancelFilter: true
    })
    
  }

  removeAllFilters = () => {
    this.setState({
      cancelledFilter: false,
      couponFilter: false,
      activeFilter: false,
      manualActiveFilter: false,
      testFilter: false,
      awaitingCancelFilter: false
    })
  }

  handleSubscriptionFilter = (subscriptions) => {
    this.setState({ loadingSubscriptionsList: true })
    const subs = subscriptions ? subscriptions : this.props.subscriptions
    const userSubs =  subs.map(subscription => {
      const subUser = this.props.users.find(user => user.id === subscription.userId)
      if (subUser) {
        subscription.consultantName = subUser.name
      }
      if (subscription.couponCode === null) {
        subscription.couponCode = ''
      }
      return subscription
    })

    const filteredSubscriptions = userSubs.filter(subscription => {
      if (this.state.cancelledFilter && subscription.status === 'cancelled') {
        return subscription
      }
      if (this.state.activeFilter && subscription.status === 'active' && subscription.nextBillingDay !== null) {
        return subscription
      }
      if (this.state.manualActiveFilter && subscription.status === 'awaitingCancel' && subscription.nextBillingDay === null && subscription.planIdentifier !== 1) {
        return subscription
      }
      if (this.state.testFilter && subscription.status === 'awaitingCancel' && subscription.planIdentifier === 1 ) {
        return subscription
      }
      if (this.state.awaitingCancelFilter && subscription.status === 'awaitingCancel' && subscription.planIdentifier !== 1 && subscription.nextBillingDay !== null){
        return subscription
      }
      if (this.state.couponFilter && subscription.couponCode !== '') {
        return subscription
      }
    })

    let sortedSubscriptions = []
    if (this.state.sortField === 'couponCode') {
      if (this.state.sortOrientation === 'ASC') {
        sortedSubscriptions = filteredSubscriptions.sort((a, b) => String(a[this.state.sortField]).localeCompare(String(b[this.state.sortField])))
      } else {
        sortedSubscriptions = filteredSubscriptions.sort((a, b) => String(b[this.state.sortField]).localeCompare(String(a[this.state.sortField])))
      }
    } else {
      if (this.state.sortOrientation === 'ASC') {
        sortedSubscriptions = filteredSubscriptions.sort((a, b) => {return new Date(a[this.state.sortField]) - new Date(b[this.state.sortField])})
      } else {
        sortedSubscriptions = filteredSubscriptions.sort((b, a) => {return new Date(a[this.state.sortField]) - new Date(b[this.state.sortField])})
      }
    }
    this.setState({ subscriptions: sortedSubscriptions })
    this.setState({ loadingSubscriptionsList: false })
  }



  renderSubscriptions = () => {
    return (
      <Row>
        <Col xs={12} sm={10} smOffset={1}>
          <Panel>
            <Panel.Heading>
              <div className='list-header'>
                <div className='list-filter'>
                  <div className='form'>
                    <FormGroup>
                      {/* <FormControl
                        type='text'
                        placeholder={text.searchClient[this.state.language]}
                        onChange={this.handleSubscriptionSearchChange}
                      /> */}
                      <Row>
                        <Col sm={4}>
                          <Checkbox checked={!!this.state.activeFilter} onChange={()=>this.setState({activeFilter: !this.state.activeFilter})}>Ativas (Wirecard)</Checkbox>
                          <Checkbox checked={!!this.state.testFilter} onChange={()=>this.setState({testFilter: !this.state.testFilter})}>Em teste</Checkbox>
                          <Checkbox checked={!!this.state.manualActiveFilter} onChange={()=>this.setState({manualActiveFilter: !this.state.manualActiveFilter})}>Liberadas Manualmente</Checkbox>
                          <Checkbox checked={!!this.state.awaitingCancelFilter} onChange={()=>this.setState({awaitingCancelFilter: !this.state.awaitingCancelFilter})}>Cancelamento Agendado</Checkbox>
                          <Checkbox checked={!!this.state.cancelledFilter} onChange={()=>this.setState({cancelledFilter: !this.state.cancelledFilter})}>Canceladas</Checkbox>
                          <Checkbox checked={!!this.state.couponFilter} onChange={()=>this.setState({couponFilter: !this.state.couponFilter})}>Cupom</Checkbox>
                          <Button 
                            bsStyle='success'
                            style={{margin: 4}}
                            onClick={() => this.selectAllFilters()}
                          >
                            Selecionar Todos Filtros
                          </Button>
                          <Button 
                            bsStyle='danger'
                            style={{margin: 4}}
                            onClick={() => this.removeAllFilters()}
                          >
                            Desmarcar Todos Filtros
                          </Button>
                        </Col>
                        <Col sm={3}>
                          Ordenar por:
                          <FormControl componentClass='select' onChange={(e) => this.setState({sortField: e.target.value})} value={this.state.sortField}>
                            <option value="createdAt">Data de Criação</option>
                            <option value="validUntilDay">Validade</option>
                            <option value="couponCode">Cupom</option>
                          </FormControl>
                        </Col>
                        <Col sm={3}>
                          Orientação:
                          <FormControl componentClass='select' onChange={(e) => this.setState({sortOrientation: e.target.value})} value={this.state.sortOrientation}>
                            <option value="ASC">Crescente</option>
                            <option value="DESC">Decrescente</option>
                          </FormControl>
                        </Col>
                        <Col sm={2} style={{marginTop: 16}}>
                          <Button 
                            bsStyle='info'
                            onClick={() => this.handleSubscriptionFilter()}
                          >
                            Filtrar
                          </Button>
                        </Col>
                      </Row>
                      <hr/>
                      
                      {/* {this.state.showingConsultants
                        ? <FormControl
                          type='text'
                          placeholder={text.searchConsultant[this.state.language]}
                          onChange={this.handleConsultantSearchChange}
                        />
                        : <FormControl
                          type='text'
                          placeholder={text.searchClient[this.state.language]}
                          onChange={this.handleSearchChange}
                        />} */}
                    </FormGroup>
                  </div>
                  <div className='alt'>
                    <div style={{ marginTop: 8, marginBottom: 8 }}>
                      <span>Legendas</span>
                    </div>
                    <div>
                      <Row>
                        <Col sm={3}>
                          <div style={{display: 'inline-flex'}}>
                            <div 
                              style={{
                                height: '20px',
                                width: '20px',
                                backgroundColor: '#B8E99C',
                                marginRight: 8
                              }}
                            /> 
                            <span>Ativo</span>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div style={{display: 'inline-flex'}}>
                            <div 
                              style={{
                                height: '20px',
                                width: '20px',
                                backgroundColor: '#fff9c2',
                                marginRight: 8
                              }}
                            /> 
                            <span>Aguardando Cancelamento</span>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div style={{display: 'inline-flex'}}>
                            <div 
                              style={{
                                height: '20px',
                                width: '20px',
                                backgroundColor: '#FFA5B2',
                                marginRight: 8
                              }}
                            /> 
                            <span>Cancelado</span>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div style={{display: 'inline-flex'}}>
                            <div 
                              style={{
                                height: '20px',
                                width: '20px',
                                backgroundColor: '#A9D3FF',
                                marginRight: 8
                              }}
                            /> 
                            <span>Teste</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={3}>
                          <div style={{display: 'inline-flex'}}>
                            <div 
                              style={{
                                height: '20px',
                                width: '20px',
                                backgroundColor: '#EEA25A',
                                marginRight: 8
                              }}
                            /> 
                            <span>Contatar equipe tecnologia</span>
                          </div>
                        </Col>
                      </Row>
                      <hr/>
                      <div style={{ marginTop: 8, marginBottom: 8 }}>
                        <h4>Selecione uma assinatura para abrir o perfil da consultora correspondente</h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='list-actions'>
                  <Button bsStyle='primary' style={{ marginBottom: '5px' }} onClick={() => this.handleShowAddClient(true)}>
                    <span className='glyphicon glyphicon-plus' aria-hidden='true' />&nbsp;
                    {text.add[this.state.language]} {this.state.showingConsultants ? text.consultant[this.state.language] : text.cliente[this.state.language]}
                  </Button>
                  <Button bsStyle='success' onClick={() => this.handleExport()}>
                    <span className='glyphicon glyphicon-export' aria-hidden='true' />&nbsp;
                    {text.export[this.state.language]} {this.state.showingConsultants ? text.consultant[this.state.language] : text.cliente[this.state.language]}s
                  </Button>
                </div> */}
              </div>
            </Panel.Heading>
            {(!this.state.loadingSubscriptionsList || (this.state.subscriptions && this.state.subscriptions.length))
              ? 
                this.state.subscriptions && this.state.subscriptions.length
                  ? <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Consultora</th>
                        <th>Validade</th>
                        <th>Plano</th>
                        <th>Status</th>
                        <th>Cupom</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.subscriptions.map((subscription, index) => (
                          <tr key={index} style={{cursor: 'pointer', backgroundColor: this.resolveColor(subscription.status, subscription.validUntilDay, subscription.nextBillingDay, subscription.planIdentifier)}} onClick={() => this.props.history.push(`/consultant/${subscription.userId}`)}>
                            {/* <Link to='' className='nome'>{subscription.userId} - Criado: {moment(subscription.createdAt).format('DD/MM/YY')}</Link> */}
                            <td>{subscription.id}</td>
                            <td>{subscription.consultantName}</td>
                            <td>{subscription.validUntilDay && moment(subscription.validUntilDay).format('DD/MM/YY')}</td>
                            <td>{resolvePlanName(subscription.planIdentifier)}</td>
                            <td>{this.resolveStatusName(subscription.status, subscription.nextBillingDay, subscription.planIdentifier)}</td>
                            <td>{subscription.couponCode}</td>
                          </tr>
                        )) 
                      }
                    </tbody>
                  </Table>
                    // this.props.filteredConsultants.map((consultant, index) => (
                    //   <ListGroupItem key={index} onClick={() => this.props.history.push(`/consultant/${consultant.id}`)}>
                    //     <Link to='' className='nome' style={{display:'inline-flex'}}>{consultant.name + (consultant.auth === auth.waitingConsultant ? text.nVerified[this.state.language] : '')} - Criado: {moment(consultant.createdAt).format('DD/MM/YY')}<div style={{textAlign:'right',flex:1}}><Checkbox checked={consultant.styleGameConsultantProfile && consultant.styleGameConsultantProfile.active} readOnly>Jogo de estilo</Checkbox></div></Link>
                    //   </ListGroupItem>
                    // 
                  : <ListGroupItem>Nenhuma assinatura encontrada</ListGroupItem>
              : <div id='centralSpinnerHolder'>
                <Loader
                  type='TailSpin'
                  color='#000'
                  height='50'
                  width='50'
                />
              </div>}
          </Panel>
        </Col>
      </Row>
    )
  }

  render () {
    const { clientMode } = this.state

    return (
      <Interface>
        <Grid>
          <Row>
            <Col xs={12} sm={10} smOffset={1}>
              <Button style={{cursor: this.state.selectedList==='subscriptions' ? 'pointer' : 'default'}} bsStyle={this.state.selectedList==='subscriptions' ? 'primary': 'light'} onClick={() => this.setState({selectedList: 'consultants'})}>
                Consultoras
              </Button>
              <Button style={{cursor: this.state.selectedList==='consultants' ? 'pointer' : 'default'}} bsStyle={this.state.selectedList==='consultants' ? 'primary': 'light'} onClick={() => this.setState({selectedList: 'subscriptions'})}>
                Assinaturas
              </Button>
            </Col>
          </Row>
          {this.state.selectedList === 'consultants' ? 
            <>
              <Row>
                <Col xs={12} sm={10} smOffset={1}>
                  <Panel>
                    <Panel.Heading>
                      <div className='list-header'>
                        <div className='list-filter'>
                          <div className='form'>
                            <FormGroup>
                              {this.state.showingConsultants
                                ? <FormControl
                                  type='text'
                                  placeholder={text.searchConsultant[this.state.language]}
                                  onChange={this.handleConsultantSearchChange}
                                />
                                : <FormControl
                                  type='text'
                                  placeholder={text.searchClient[this.state.language]}
                                  onChange={this.handleSearchChange}
                                />}
                            </FormGroup>
                          </div>
                          <div className='alt'>
                            <div style={{ height: 40 }}>
                              <span style={{ lineHeight: '40px' }}>{text.select[this.state.language] + (this.state.showingConsultants ? text.aConsultant[this.state.language] : text.clienteMin[this.state.language]) + text.interect[this.state.language]}</span>
                            </div>
                          </div>
                        </div>
                        <div className='list-actions'>
                          <Button bsStyle='primary' style={{ marginBottom: '5px' }} onClick={() => this.handleShowAddClient(true)}>
                            <span className='glyphicon glyphicon-plus' aria-hidden='true' />&nbsp;
                            {text.add[this.state.language]} {this.state.showingConsultants ? text.consultant[this.state.language] : text.cliente[this.state.language]}
                          </Button>
                          <Button bsStyle='success' onClick={() => this.handleExport()}>
                            <span className='glyphicon glyphicon-export' aria-hidden='true' />&nbsp;
                            {text.export[this.state.language]} {this.state.showingConsultants ? text.consultant[this.state.language] : text.cliente[this.state.language]}s
                          </Button>
                        </div>
                      </div>
                    </Panel.Heading>
                    {(!this.state.loadingClientsList || this.props.filteredUsers.length)
                      ? <ListGroup>
                        {this.props.filteredUsers.length

                          ? !this.state.showingConsultants ? this.props.filteredUsers.map((cliente, index) => (
                            <ListGroupItem key={index} onClick={() => this.props.history.push(`/user/${cliente.id}`)}>
                              <Link to='' className='nome'>{cliente.name + (cliente.auth === auth.unverified ? text.nVerified[this.state.language] : '')} - Criado: {moment(cliente.createdAt).format('DD/MM/YY')}</Link>
                            </ListGroupItem>
                          ))
                            : this.props.filteredConsultants.map((consultant, index) => (
                              <ListGroupItem key={index} onClick={() => this.props.history.push(`/consultant/${consultant.id}`)}>
                                <Link to='' className='nome' style={{display:'inline-flex'}}>{consultant.name + (consultant.auth === auth.waitingConsultant ? text.nVerified[this.state.language] : '')} - Criado: {moment(consultant.createdAt).format('DD/MM/YY')}<div style={{textAlign:'right',flex:1}}><Checkbox checked={consultant.styleGameConsultantProfile && consultant.styleGameConsultantProfile.active} readOnly>Jogo de estilo</Checkbox></div></Link>
                              </ListGroupItem>
                            ))
                          : <ListGroupItem>{text.noOne[this.state.language]}{this.state.showingConsultants ? text.conFound[this.state.language] : text.cltFound[this.state.language]}</ListGroupItem>}
                      </ListGroup>
                      : <div id='centralSpinnerHolder'>
                        <Loader
                          type='TailSpin'
                          color='#000'
                          height='50'
                          width='50'
                        />
                      </div>}
                  </Panel>
                  <Modal show={this.state.showAddClient} onHide={() => this.handleShowAddClient(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.state.showingConsultants ? (text.add[this.state.language] + ' ' + text.consultant[this.state.language]) : (text.add[this.state.language] + ' ' + text.cliente[this.state.language])}</Modal.Title>
                    </Modal.Header>
                    <Form horizontal onSubmit={(e) => e.preventDefault()} style={{ padding: 20 }}>
                      <Modal.Body>
                        {this.state.loadingAddClient
                          ? <div id='centralSpinnerHolder'>
                            <Loader
                              type='TailSpin'
                              color='#000'
                              height='50'
                              width='50'
                            />
                          </div>
                          : <div>
                            <FormGroup>
                              <ButtonToolbar>
                                <Button bsStyle="success" disabled={clientMode === 'national'} onClick={() => this.handleClientMode('national')}>
                                  {text.national[this.state.language]}
                                </Button>
                                <Button bsStyle="danger" disabled={clientMode === 'foreign'} onClick={() => this.handleClientMode('foreign')}>
                                  {text.foreign[this.state.language]}
                                </Button>
                              </ButtonToolbar>
                            </FormGroup>
                            <FormGroup controlId='addNome'>
                              <ControlLabel>{text.name[this.state.language]} *</ControlLabel>
                              <FormControl type='text' placeholder={text.name[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'name')} value={this.state.newClient.name} />
                            </FormGroup>
                            <FormGroup controlId='addEmail' validationState={this.state.errorFields.email}>
                              <ControlLabel>E-mail *</ControlLabel>
                              <FormControl type='email' placeholder='E-mail' onChange={(e) => this.handleFormAttribute(e, 'email')} value={this.state.newClient.email} />
                            </FormGroup>
                            <ControlLabel>{text.adress[this.state.language]} *</ControlLabel>{' '}
                            <FormGroup controlId='addEndereco'>
                              <Col sm={8}>
                                <FormControl type='text' placeholder={text.street[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'streetName')} value={this.state.newClient.streetName} />
                              </Col>
                              <Col sm={4}>
                                <FormControl type='text' placeholder={text.number[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'streetNumber')} value={this.state.newClient.streetNumber} />
                              </Col>
                            </FormGroup>
                            <FormGroup controlId='addEndereco'>
                              <Col sm={12}>
                                <FormControl type='text' placeholder={text.complement[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'complement')} value={this.state.newClient.complement} />
                              </Col>
                            </FormGroup>
                            <FormGroup controlId='addEndereco'>
                              <Col sm={5}>
                                <FormControl type='text' placeholder={text.city[this.state.language]} onChange={(e) => this.handleFormAttribute(e, 'city')} value={this.state.newClient.city} />
                              </Col>
                              <Col sm={5}>
                                <FormControl type='text' placeholder={text.zip[this.state.language]} onChange={(e) => this.handleFormZipCode(e.target.value)} value={this.state.newClient.zipCode} />
                              </Col>
                              <Col sm={2}>
                                {
                                  clientMode === 'foreign' ? 
                                  <FormGroup hidden={this.state.language === 0 ? 0 : 1}>
                                    <FormControl type='text' placeholder={text.state[this.state.language]}  onChange={(e) => this.handleFormAttribute(e, 'state')} value={this.state.newClient.state}/>
                                  </FormGroup> :
                                  <FormGroup controlId='addEndereco' hidden={this.state.language === 0 ? 0 : 1}>
                                    <FormControl componentClass='select' placeholder='Estado' onChange={(e) => this.handleFormAttribute(e, 'state')} value={this.state.newClient.state}>
                                      <option value='AC'>AC</option>
                                      <option value='AL'>AL</option>
                                      <option value='AP'>AP</option>
                                      <option value='AM'>AM</option>
                                      <option value='BA'>BA</option>
                                      <option value='CE'>CE</option>
                                      <option value='DF'>DF</option>
                                      <option value='ES'>ES</option>
                                      <option value='GO'>GO</option>
                                      <option value='MA'>MA</option>
                                      <option value='MT'>MT</option>
                                      <option value='MS'>MS</option>
                                      <option value='MG'>MG</option>
                                      <option value='PA'>PA</option>
                                      <option value='PB'>PB</option>
                                      <option value='PR'>PR</option>
                                      <option value='PE'>PE</option>
                                      <option value='PI'>PI</option>
                                      <option value='RJ'>RJ</option>
                                      <option value='RN'>RN</option>
                                      <option value='RS'>RS</option>
                                      <option value='RO'>RO</option>
                                      <option value='RR'>RR</option>
                                      <option value='SC'>SC</option>
                                      <option value='SP'>SP</option>
                                      <option value='SE'>SE</option>
                                      <option value='TO'>TO</option>
                                    </FormControl>
                                  </FormGroup>
                                }
                              </Col>
                            </FormGroup>
                            <FormGroup controlId='addAniversario'>
                              <ControlLabel>{text.birthday[this.state.language]}</ControlLabel><br />
                              <DatePicker form value={this.state.newClient.birth} onChange={(birth) => this.setState({ newClient: { ...this.state.newClient, birth } })} />
                            </FormGroup>
                            <FormGroup controlId='addCell'>
                              <ControlLabel>{text.cell[this.state.language]}</ControlLabel>
                              <FormControl type='text' placeholder='(99) 99999-9999' onChange={(e) => this.handleFormPhone(e.target.value)} value={this.state.newClient.phone} />
                            </FormGroup>
                          </div>}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button type='submit' bsStyle='primary' onClick={this.handleAddClient} disabled={this.state.loadingAddClient}>{text.add[this.state.language]}</Button>
                        <Button onClick={() => this.handleShowAddClient(false)}>{text.close[this.state.language]}</Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                  {this.modalOnboarding()}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={10} smOffset={1}>
                  <a target='_blank' href='https://www.youtube.com/watch?list=PL4VO5Ic8eMNlF73ohCvXofalvrAVU39vp&time_continue=5&v=0FFUYvrlcXM' style={{ width: 185, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5, border: '0.5px solid #ccc', borderRadius: 4 }}>
                    <img style={{ width: 28 }} src='youtube.svg' alt='Youtube' />
                    <span style={{ color: '#000' }}>Passos Iniciais Mosty</span>
                  </a>
                </Col>
              </Row>
            </>
          : this.renderSubscriptions() }
        </Grid>
      </Interface>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer,
    ...state.usersReducer,
    filteredUsers: filteredUsers(state),
    filteredConsultants: filteredConsultants(state)
  }
}

export default connect(mapStateToProps, actions)(Lista)
