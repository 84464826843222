export default
{
  someError: ['Algum erro ocorreu ao atualizar os dados do cliente!', 'Some error occurred while updating client data!', 'Algún error ocurrió al actualizar los datos del cliente!'],
  loadError: ['Erro ao carregar dados', 'Load Data Error', 'Error al cargar datos'],
  fillFields: ['Preencha todos os campos obrigatórios para atualizar a consultora!', 'Fill all the required fields to update consultant.', 'Rellene todos los campos obligatorios para actualizar la consultora!'],
  updateSucess: ['Consultora atualizado com sucesso!', 'Consultant updated successfully!', 'Consultora actualizada con éxito!'],
  writeName: ['Digite o nome correto para excluir!', 'Enter the correct name to delete!', 'Escriba el nombre correcto para eliminar!'],
  deleteSucess: ['Consultora excluído com sucesso!', 'Consultant deleted successfully!', 'Consultora eliminada con éxito!'],
  successfullAction: ['Ação realizada com sucesso', 'Successful action', 'Acción exitosa'],
  name: ['Nome', 'Name', 'Nombre'],
  nameForm: ['Nome *', 'Name *', 'Nombre *'],
  services: ['Serviços', 'Services', 'Servicios'],
  profilePicture: ['Foto de perfil', 'Profile picture', 'Foto de perfil'],
  personalColoring: ['Coloração pessoal', 'Personal coloring', 'Coloración personal'],
  onlineImageConsulting: ['Consultoria de imagem online', 'Online image consulting', 'Consultoría de imagen online'],
  moodboard: ['Guia de estilo', 'Style Guide', 'Guía de estilo'],
  state: ['Estado *', 'State *', 'Estado *'],
  instagram: ['Instagram', 'Instagram', 'Instagram'],
  email: ['E-mail *', 'E-mail *', 'E-mail *'],
  site: ['Site', 'Site', 'Site'],
  description: ['Descrição', 'Description', 'Descripción'],
  qualification: ['Formação', 'Qualification', 'Formación'],
  videoCode: ['Código do vídeo', 'Video code', 'Código de video'],
  enabledConsultantView: ['Ativar visualização da consultora', 'Enable consultant view', 'Habilitar vista de consultor'],
  yes: ['Sim', 'Yes', 'Si'],
  save: ['Salvar', 'Save', 'Salvar'],
  no: ['Não', 'No', 'No'],
  adress: ['Endereço', 'Adress', 'Dirección'],
  street: ['Rua *', 'Street *', 'Calle *'],
  number: ['Número *', 'Number *', 'Número *'],
  complement: ['Complemento', 'Complement', 'Complement'],
  city: ['Cidade *', 'City *', 'Ciudad *'],
  zip: ['CEP *', 'ZIP *', 'Código postal *'],
  birthday: ['Aniversário *', 'Birthday *', 'Cumpleaños *'],
  cell: ['Celular *', 'Phone *', 'Celular *'],
  plan: ['Plano *', 'Plan *', 'Plano *'],
  deleteClient: ['Excluir Consultora', 'Delete Consultant', 'Eliminar Consultora'],
  deleteConfirm: ['Tem certeza que deseja excluir todos os dados da consultora', 'Are you sure you want to delete all the data of', '¿Está seguro de que desea eliminar todos los datos de la consultora'],
  deleteConfirm2: ['permanentemente? Esta ação não pode ser desfeita!', 'permanently? This action can not be undone!', 'de forma permanente? ¡Esta acción no puede ser deshecha!'],
  enterName: ['Digite o nome da cliente para confirmar a exclusão', 'Enter the consultant name to confirm the deletion', 'Escriba el nombre de la consultora para confirmar la eliminación'],
  update: ['Atualizar Cadastro', 'Update Profile', 'Actualizaciones']
}
