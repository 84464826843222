export default
{
  sizeError: ['O arquivo enviado é maior que 10MB! Comprima o mesmo para que fique abaixo do limite.', 'The uploaded file is larger than 10mb! Please compress to make it below the limit.', 'El archivo enviado es mayor que 10MB! Comprima el mismo para que quede por debajo del límite.'],
  wrongFormat: ['Formato inválido! Utilize imagens em formato .JPG ou .PNG', 'Invalid format! Use images in .JPG or .PNG format'],
  error: ['Erro', 'Error', 'Error'],
  uploadError: ['Algum erro ocorreu ao fazer upload da imagem!', 'Some error occurred while uploading the image', '¡Algún error ocurrió al subir la imagen!'],
  updatedSuc: ['Perfil atualizada com sucesso!', 'Profile updated successfully', '¡Perfil actualizado con éxito!'],
  textError: ['Algum erro ocorreu ao enviar um texto para o perifl do cliente!', "Some error occurred while sending the profile's text", '¡Algún error ocurrió al enviar un texto a lo perfil!'],
  silError: ['Algum erro ocorreu ao enviar uma silhueta para o perfil do cliente!', 'Some error occurred while sending the silhouette for the client profile', '¡Algún error ocurrió al enviar una silueta al perfil del cliente!'],
  noImage: ['Perfil Sem Imagem', 'Profile without image', 'Perfil sin imagen'],
  chooseImage: ['Escolha um arquivo de Imagem para o perfil do Cliente', 'Choose a image for the client profile', 'Seleccione un archivo de imagen para el perfil del cliente'],
  resolution: ['Importante: Opte por imagens na resolução 720x1280', 'Important: Choose images at 720x1280 resolution ', 'Importante: Opte por imágenes en la resolución 720x1280'],
  currentText: ['Texto Atual:', 'Current Text:', 'Texto Actual:'],
  text: ['Texto Para o Perfil', 'Text for the profile', 'Texto para el perfil'],
  profileExample: [`Nome: ...
    Data de Nascimento: ...`, `Name:...
    Birthday:...`, `Nombre:...
    Cumpleaños:...`],
  sil1: ['Silhueta Triângulo ou Pêra', 'Triangle Silhouette or Pear', 'Silueta Triángulo o Pêra'],
  sil2: ['Silhueta oval', 'Oval Silhouette', 'Silueta ovalada'],
  sil3: ['Silhueta triângulo invertido', 'Inverted triangle silhouette', 'Silueta triángulo invertido'],
  sil4: ['Silhueta retângulo', 'Rectangle Silhouette', 'Silueta rectángulo'],
  sil5: ['Silhueta ampulheta', 'Hourglass Silhouette', 'Silueta de reloj de arena'],
  alreadySelected: ['já selecionada', 'already selected', 'ya seleccionada'],
  select: ['Selecionar', 'Select', 'Seleccionar'],
  desc1: ['Esse espaço é direcionado para você (Consultora de Imagem) incluir qualquer informação relevante sobre a sua cliente.', 'This space is directed to you (Image Consultant) to include any relevant information about your client.', 'Este espacio está dirigido a ti (consultor de imagen) para añadir cualquier información importante sobre tu cliente.'],
  desc2: ['Existem duas opções:', 'There are two options:', 'Hay dos opciones:'],
  desc3: ['Pode ser através de uma imagem na vertical (ponto positivo é poder incluir o template/marca da sua empresa)', 'It can be through a vertical image (positive point is to be able to include the template/brand of your company)', 'Puede ser a través de una imagen en posición vertical (el punto positivo es que podrás añadir plantilla / marca de tu empresa)'],
  desc4: ['Escrever apenas um texto (método mais rápido)', 'Write only a text (fastest way)', 'Escribir sólo un texto (método más rápido)'],
  desc5: ['Sugestões para este espaço:', 'Suggestions for this space:', 'Sugerencias para este espacio:'],
  desc6: ['Falar sobre o tipo de silhueta da cliente;', "Talk about the client's Silhouette", 'Que hables sobre el tipo de silueta de tu cliente'],
  desc7: ['Falar sobre as características do rosto da cliente;', "Talk about the characteristics of the client's face;", 'Que hables sobre los rasgos del rostro de tu cliente'],
  desc8: ['Deixar claro o objetivo da consultoria ou algo importante para a cliente que será resolvido;', 'Make clear the purpose of the consultancy or something important to the client that will be solved;', 'Dejar claro el objetivo de la consultoría o algo importante para el cliente que será solucionado por ti.'],
  image: ['Imagem', 'Image', 'Imagen'],
  text: ['Texto', 'Text', 'Texto'],
  silhouette: ['Silhueta', 'Silhouette', 'Silueta'],
  update: ['Atualizar Perfil', 'Update Profile', 'Actualizar Perfil'],
  confim: ['Confirmar', 'Confirm', 'Confirmar'],
  deleteConfirm: ['Tem certeza que deseja excluir essa foto? Esta ação não pode ser desfeita.', 'Are you sure you want to delete this picture? This action can not be undone.', '¿Está seguro de que desea eliminar esta foto? Esta acción no se puede deshacer.'],
  delete: ['Excluir Imagem', 'Delete Image', 'Eliminar Imagen'],
  deleteSuc: ['Imagem excluída com sucesso!', 'Image deleted successfully!', '¡Imagen eliminada con éxito!']
}
