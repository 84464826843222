import React, { Component } from 'react'
import rp from 'request-promise-native'
import { connect } from 'react-redux'
import { FormControl, ControlLabel, HelpBlock, Modal, ToggleButton, ToggleButtonGroup, Alert, FormGroup, Button } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'
import 'react-image-lightbox/style.css'
import Slider from 'react-slick'

import * as actions from '../redux/actions'
import { uri, maxImageSize } from '../services/defaultProps'
import { selectedUser } from '../services/selectors'

import text from '../constants/Perfil'

const silhouettePictures = [
  {
    id: 1,
    desc: text.sil1,
    image: '/temp/silhouette1.jpeg'
  },
  {
    id: 2,
    desc: text.sil2,
    image: '/temp/silhouette2.jpeg'
  },
  {
    id: 3,
    desc: text.sil3,
    image: '/temp/silhouette3.jpeg'
  },
  {
    id: 4,
    desc: text.sil4,
    image: '/temp/silhouette4.jpeg'
  },
  {
    id: 5,
    desc: text.sil5,
    image: '/temp/silhouette5.jpeg'
  }
]

const decideSelectedType = (media) => {
  if (!media) return 'image'

  if (media.profileImage) return 'image'

  if (media.profileText) return 'text'

  return 'silhouette'
}

class Perfil extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLightBoxOpen: 0,
      error: 0,
      page: 0,
      selectedType: decideSelectedType(this.props.selectedUser.media),
      imagesToUpload: null,
      loadingUpload: false,
      deletingImage: null,
      textToUpload: '',
      silhouetteToUpload: this.props.selectedUser.media.silhouetteId != null ? this.props.selectedUser.media.silhouetteId : silhouettePictures[0].id,
      loading: false,
      language: 1
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') })
  }

  alertError = () => {
    switch (this.state.error) {
      case 1:
        return (
          <Alert bsStyle='danger' onDismiss={() => this.setState({ error: 0 })}>
            <p>Faça o Upload do arquivo para Atualizar o Perfil do Usuário! (Error Message Test)</p>
          </Alert>
        )
    }
  }

  showError = () => { this.setState({ error: 1 }) }

  handleUploadImage = (event) => {
    const imagesToUpload = []
    for (let i = 0; i < event.target.files.length; i++) {
      const imageToUpload = event.target.files[i]
      const type = imageToUpload.type.split('/').pop()
      // Size in MB
      const size = imageToUpload.size / (1024 * 1024)
      if (size > maxImageSize) {
        toast(text.sizeError[this.state.language], {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT
        })
        return
      }
      // Unwanted type
      if (!['jpeg', 'png'].includes(type)) {
        this.props.setError(text.error[this.state.language], text.wrongFormat[this.state.language])
        return
      }

      imagesToUpload.push(imageToUpload)
    }

    this.setState({ imagesToUpload })
  }

  handleUpdateClick = () => {
    switch (this.state.selectedType) {
      case 'image':
        if (this.state.imagesToUpload.length > 0) {
          this.setState({ loadingUpload: true })

          Promise.all(this.state.imagesToUpload.map(imageToUpload => {
            return fetch(`${uri}/media/profile/image?id=${this.props.selectedUser.id}`, {
              method: 'POST',
              headers: {
                'content-type': imageToUpload.type,
                Authorization: this.props.token
              },
              body: imageToUpload
            })
              .then(res => {
                if (!res.ok && res.status === 401) {
                  this.props.setToken(null)
                  return res.json().then((err) => Promise.reject(err)) // eslint-disable-line prefer-promise-reject-errors
                } else {
                  return res
                }
              })
              .then(res => res.json())
              .then(userProfile => {
                this.props.setUserMediaAttribute(this.props.selectedUser.id, userProfile)
                toast(text.updatedSuc[this.state.language], {
                  type: toast.TYPE.INFO,
                  position: toast.POSITION.BOTTOM_RIGHT
                })
              })
          }))
            .then(() => {
              this.setState({ loadingUpload: false, imagesToUpload: [] })
            })
            .catch(err => {
              console.log('Error posting perfil image', err)
              this.setState({ loadingUpload: false, imagesToUpload: [] })
              toast(text.uploadError[this.state.language], {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
        }
        break
      case 'text':
        if (this.state.textToUpload) {
          this.setState({ loading: true })
          rp({
            uri: `${uri}/media/profile/text?id=${this.props.selectedUser.id}`,
            method: 'POST',
            headers: {
              Authorization: this.props.token
            },
            body: {
              text: this.state.textToUpload
            },
            json: true
          })
            .then(profile => {
              this.props.setUserMediaAttribute(this.props.selectedUser.id, profile)
              this.setState({ textToUpload: '', imagesToUpload: null, loading: false })
              toast(text.updatedSuc[this.state.language], {
                type: toast.TYPE.INFO,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
            .catch(err => {
              if (err.statusCode === 401) {
                this.props.setToken(null)
              }
              throw err
            })
            .catch(err => {
              console.log('Error updating profile text', err)
              this.setState({ loading: false })
              toast(text.textError[this.state.language], {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
        }
        break

      case 'silhouette':
        if (this.state.silhouetteToUpload) {
          this.setState({ loading: true })
          rp({
            uri: `${uri}/media/profile/silhouette?id=${this.props.selectedUser.id}`,
            method: 'POST',
            headers: {
              Authorization: this.props.token
            },
            body: {
              silhouetteId: this.state.silhouetteToUpload
            },
            json: true
          })
            .then(profile => {
              this.props.setUserMediaAttribute(this.props.selectedUser.id, profile)
              this.setState({ textToUpload: '', imagesToUpload: null, loading: false })
              toast(text.updatedSuc[this.state.language], {
                type: toast.TYPE.INFO,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
            .catch(err => {
              if (err.statusCode === 401) {
                this.props.setToken(null)
              }
              throw err
            })
            .catch(err => {
              console.log('Error updating profile text', err)
              this.setState({ loading: false })
              toast(text.silError[this.state.language], {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
        }

        break
    }
  }

  handleDeleteClick = () => {
    if (this.state.deletingImage) {
      this.setState({ loadingDelete: true })
      rp({
        uri: `${uri}/media/profile/image`,
        method: 'DELETE',
        headers: {
          Authorization: this.props.token
        },
        body: {
          id: this.state.deletingImage
        },
        json: true
      })
        .then(() => {
          this.props.setUserMediaAttribute(this.props.selectedUser.id, {
            userProfile: this.props.selectedUser.media.userProfile.filter(profileImage => profileImage.id !== this.state.deletingImage)
          })
          this.setState({ loadingDelete: false, deletingImage: null })
          toast(text.deleteSuc[this.state.language], {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.props.setToken(null)
          }
          throw err
        })
        .catch(err => {
          console.log('Error deleting profile image', err)
          this.setState({ loadingDelete: false, deletingImage: null })
        })
    }
  }

  handleTextChange = (e) => {
    this.setState({ textToUpload: e.target.value })
  }

  handleModalDel = (id) => {
    this.setState({ deletingImage: id })
  }

  imageOrText = () => {
    if (!this.props.selectedUser.media) return <div />
    switch (this.state.selectedType) {
      case 'image':
        const profilePictures = this.props.selectedUser.media.userProfile
        const settings = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (index) => this.setState({ page: index })
        }
        return <div id='perfilImage'>
          <div>
            <Slider {...settings}>
              {profilePictures && profilePictures.length ? profilePictures.map((item, index) => <div key={index} className='sliderItem'>
                <img src={item.image} id='sliderImage' alt={`Imagem ${index}`} /> <br />
                <Button bsStyle='danger' bsSize='small' className='delFoto' onClick={() => this.handleModalDel(item.id)}>Deletar Foto</Button>
              </div>)
                : <div><h3>{text.noImage[this.state.language]}</h3></div>}
            </Slider>
            {profilePictures && profilePictures.length ? <p id='page'>Foto {this.state.page + 1} de {profilePictures.length}</p> : ''}
            <Modal show={this.state.deletingImage !== null} onHide={() => this.handleModalDel(null)}>
              <Modal.Header closeButton>
                <Modal.Title>{text.confim[this.state.language]}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.loadingDelete
                  ? <div id='centralSpinnerHolderWithLessPadding'>
                    <Loader
                      type='TailSpin'
                      color='#000'
                      height='50'
                      width='50'
                    />
                  </div>
                  : <p>{text.deleteConfirm[this.state.language]}</p>}
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle='danger' onClick={this.handleDeleteClick}>{text.delete[this.state.language]}</Button>
              </Modal.Footer>
            </Modal>
            {this.state.loadingUpload
              ? <div id='centralSpinnerHolderWithLessPadding'>
                <Loader
                  type='TailSpin'
                  color='#000'
                  height='50'
                  width='50'
                />
              </div>
              : <div style={{ display: 'inline-block' }}>
                <ControlLabel>{text.chooseImage[this.state.language]}</ControlLabel>
                <input style={{ maxWidth: 180, margin: '25px auto' }} type='file' accept='image/*' onChange={this.handleUploadImage} multiple />
                <HelpBlock>{text.resolution[this.state.language]}</HelpBlock>
              </div>}
          </div>
        </div>
      case 'text':
        return <div id='perfilText'>
          {this.props.selectedUser.media.profileText ? <div id='currentText'><h4>{text.currentText[this.state.language]}</h4><br />{this.props.selectedUser.media.profileText}</div> : ''}
          <FormGroup controlId='formControlsTextarea'>
            <ControlLabel id='textAreaLabel'>{text.text[this.state.language]}</ControlLabel>
            <FormControl className='textArea' componentClass='textarea' placeholder={text.profileExample[this.state.language]} onChange={this.handleTextChange} value={this.state.textToUpload} />
          </FormGroup>
        </div>
      case 'silhouette':
        return <div id='perfilText'>
          <Slider {...{
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: silhouettePictures.findIndex((silhouette) => silhouette.id === this.props.selectedUser.media.silhouetteId),
            afterChange: (index) => this.setState({ silhouetteToUpload: silhouettePictures[index].id })
          }}
          >
            {silhouettePictures.map((item, index) => <div key={index} className='sliderItem'>
              {this.props.selectedUser.media.silhouetteId === item.id
                ? <h3>{item.desc[this.state.language]} {text.alreadySelected[this.state.language]}</h3>
                : <h3>{text.select[this.state.language]} {item.desc[this.state.language]}</h3>}
              <img src={item.image} id='sliderImage' alt={`Imagem ${index}`} /> <br />
            </div>)}
          </Slider>
        </div>
    }
  }

  render () {
    if (this.state.loading) {
      return (
        <div id='centralSpinnerHolder'>
          <Loader
            type='TailSpin'
            color='#000'
            height='50'
            width='50'
          />
        </div>
      )
    }

    const willChangeSilhouette = this.state.silhouetteToUpload !== this.props.selectedUser.media.silhouetteId
    const silhouetteToUploadObject = silhouettePictures.find((silhouette) => silhouette.id === this.state.silhouetteToUpload)

    return (
      <form className='clientForm' onSubmit={(e) => e.preventDefault()}>
        <div style={{ width: '90%', margin: 'auto' }}>
          <Alert bsStyle='info'>
            <div style={{ textAlign: 'left', paddingHorizontal: 10 }}>
              <strong>{text.desc1[this.state.language]}</strong>
              <div>{text.desc2[this.state.language]}</div>
              <ol>
                <li>{text.desc3[this.state.language]}</li>
                <li>{text.desc4[this.state.language]}</li>
              </ol>

              {text.desc5[this.state.language]}
              <ul>
                <li>{text.desc6[this.state.language]}</li>
                <li>{text.desc7[this.state.language]}</li>
                <li>{text.desc8[this.state.language]}</li>
              </ul>
            </div>
          </Alert>
        </div>
        <FormGroup id='radioTextOrImage'>
          <ToggleButtonGroup type='radio' name='textOrImage' defaultValue={this.state.selectedType} onChange={(selectedType) => this.setState({ selectedType })}>
            <ToggleButton value='image'>{text.image[this.state.language]}</ToggleButton>
            <ToggleButton value='text'>{text.text[this.state.language]}</ToggleButton>
            <ToggleButton value='silhouette'>{text.silhouette[this.state.language]}</ToggleButton>
          </ToggleButtonGroup>
        </FormGroup>
        {this.props.selectedUser.media &&
          <div>
            {this.imageOrText()}
            {this.state.isLightBoxOpen
              ? <Lightbox mainSrc={this.props.selectedUser.media.profileImage} onCloseRequest={() => this.setState({ isLightBoxOpen: 0 })} />
              : ''}
            {this.alertError()}
          </div>}
        <Button
          type='submit'
          id='formSubmit'
          bsStyle='primary'
          onClick={this.handleUpdateClick}
          disabled={
            this.state.selectedType === 'image' ? !this.state.imagesToUpload
              : this.state.selectedType === 'text' ? !this.state.textToUpload
                : this.state.selectedType === 'silhouette' && !willChangeSilhouette
          }
        >{this.state.selectedType !== 'silhouette'
            ? text.update[this.state.language]
            : willChangeSilhouette
              ? `${text.select[this.state.language]} ${silhouetteToUploadObject.desc[this.state.language]}`
              : `${silhouetteToUploadObject.desc[this.state.language]} ${text.alreadySelected[this.state.language]}`}
        </Button>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer,
    ...state.usersReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(Perfil)
