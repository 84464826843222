export default
{
  sizeError: ['O arquivo enviado é maior que 10MB! Comprima o mesmo para que fique abaixo do limite.', 'The uploaded file is larger than 10mb! Please compress to make it below the limit.', 'El archivo enviado es mayor que 10MB! Comprima el mismo para que quede por debajo del límite.'],
  wrongFormat: ['Formato inválido! Utilize imagens em formato .JPG ou .PNG', 'Invalid format! Use images in .JPG or .PNG format'],
  error: ['Erro', 'Error', 'Error'],
  uploadError: ['Algum erro ocorreu ao fazer upload da imagem!', 'Some error occurred while uploading the image', '¡Algún error ocurrió al subir la imagen!'],
  updatedSuc: ['Lista de Compras atualizada com sucesso!', 'Shopping List updated successfully', '¡Listado de Compras actualizado con éxito!'],
  deleteError: ['Algum erro ocorreu ao deletar a imagem da Lista de Compras!', 'Something wrent wrong while deleting the shopping list image', '¡Algún error ocurrió al borrar una imagen de la lista de compras!'],
  textError: ['Algum erro ocorreu ao enviar um texto para a lista de compras!', 'Some error occurred while sending the shopping list text', '¡Algún error ocurrió al enviar un texto a la lista de compras!'],
  noPhoto: ['Não há fotos na Lista de Compras do Cliente', "There are no photo in the client's shopping list", 'No hay fotos en el listado de compras del Cliente'],
  delete: ['Excluir Imagem', 'Delete Image', 'Eliminar Imagen'],
  currentText: ['Texto Atual:', 'Current Text:', 'Texto Actual:'],
  text: ['Texto Para a Lista de Compras', 'Text for the shopping list', 'Texto a la lista de compras'],
  listExample: [`Camisa branca: ...
    Bota: ...`, `White shirt...
    Boots`, `Camisa blanca:
    Bota:`],
  updateList: ['Atualizar Lista de Compras', 'Update Shopping List', 'Actualizar Listado de Compras'],
  desc1: ['Esse espaço é direcionado para que coloque a lista com as sugestões de peças que a sua cliente deverá adquirir.', 'This space is directed to place the list with the suggestions of pieces that your client should acquire.', 'Este espacio está dirigido para que se pueda tener el listado con las sugerencias de prendas que tu cliente deberá adquirir.'],
  desc2: ['Existem duas opções:', 'There are two options:', 'Hay dos opciones:'],
  desc3: ['Pode ser através de uma imagem na vertical (ponto positivo para este formato é poder incluir a marca da sua empresa)', 'It can be through a vertical image (positive point for this format is to be able to include the brand of your company)', 'Puede ser a través de una imagen en vertical (punto positivo para este formato es poder incluir la marca de su empresa)'],
  desc4: ['Escrever apenas um texto (método mais rápido)', 'Write only a text (fastest way)', 'Escribir sólo un texto (método más rápido)'],
  image: ['Imagem', 'Image', 'Imagen'],
  text: ['Texto', 'Text', 'Texto'],
  choose: ['Escolha entre Uma Imagem OU Texto OU Multiplas Imagens para a Lista de Compras do Cliente', "Choose between a Image OR Text OR Multiple Images for the client's shopping list.", 'Elija entre una imagen u otra o varias imágenes para el listado de compras del cliente'],
  confirm: ['Confirmar', 'Confirm', 'Confirmar'],
  confirmRemotion: ['Tem certeza que deseja excluir essa foto? Esta ação não pode ser desfeita.', 'Are you sure you want to delete this picture? This action can not be undone', '¿Está seguro de que desea eliminar esta foto? Esta acción no se puede deshacer.'],
  deleteImage: ['Excluir Imagem', 'Delete Image', 'Eliminar Imagen']
}
