import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

export const requiresAuthentication = (MyComponent) => {
  class AuthenticationInterface extends Component {
    render () {
      return (
        <div>
          {!this.props.token
            ? <Redirect to='/login' />
            : <MyComponent {...this.props} />}
        </div>
      )
    }
  }

  const mapStateToProps = (state) => ({
    token: state.adminReducer.token
  })

  return connect(mapStateToProps)(AuthenticationInterface)
}

export const noAuthentication = (MyComponent) => {
  class AuthenticationInterface extends Component {
    render () {
      return (
        <div>
          {!this.props.token
            ? <MyComponent {...this.props} />
            : <Redirect to='/home' />}
        </div>
      )
    }
  }

  const mapStateToProps = (state) => ({
    token: state.adminReducer.token
  })

  return connect(mapStateToProps)(AuthenticationInterface)
}
