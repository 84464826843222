import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { requiresAuthentication, noAuthentication } from './AuthInterface'

import Login from '../containers/Login'
import Lista from '../components/Lista'
import Cliente from '../components/Cliente'
import Consultants from '../components/Consultants'

class Router extends Component {
  render () {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route path='/login' component={noAuthentication(Login)} />
            <Route path='/user/:id' component={requiresAuthentication(Cliente)} />
            <Route path='/consultant/:id' component={requiresAuthentication(Consultants)} />
            <Route path='/' component={requiresAuthentication(Lista)} />
            <Route component={Login} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default Router
