import jo from 'jpeg-autorotate'
import Pica from 'pica'

const resizerMode = {
    js: true,
    wasm: true,
    cib: true,
    ww: true
  }

  const opts = []

  Object.keys(resizerMode).forEach(function (k) {
    if (resizerMode[k]) opts.push(k)
  })

  const pica = Pica({
    features: opts
  })

async function base64ToImage(base64,width,height){
  const newImage = new Image()
  newImage.src = base64

  if(width){
    newImage.width=width
  }

  if(height){
    newImage.heigh=height
  }

  return new Promise((resolve, reject) => {
    newImage.addEventListener('load', () => resolve(newImage))
    newImage.addEventListener('error', err => reject(err))
  })
}

async function blobToBase64 (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

function base64MimeType( encoded) {
  if (typeof encoded !== 'string') {
    return null
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)

  if (mime && mime.length) {
    return mime[1]
  } else {
    return null
  }
}

async function resizeAndCompress(image,newDimensions){
  const resizedImage = document.createElement('canvas')

  resizedImage['width'] = newDimensions.width
  resizedImage['height'] = newDimensions.height

  return pica.resize(image, resizedImage)
    .then(canvasToJpegBase64)
}

async function canvasToJpegBase64 (canvas) {
  const blob = await pica.toBlob(canvas, 'image/jpeg')
  return blobToBase64(blob)
}

async function autoRotate (image) {
    const type = base64MimeType(image.src)

    if (type !== 'image/jpeg') {
      return image
    }

    const fileBuffer = Buffer.from(
      image.src.replace(/^data:.+;base64,/, ''),
      'base64'
    )
    return jo
      .rotate(fileBuffer, { quality: 100 })
      .then(({ buffer, dimensions }) => {
        const decoded = 'data:image/jpeg;base64,' + buffer.toString('base64')
        return base64ToImage(decoded,dimensions.width,dimensions.height)
      })
      .catch(() => {
        return image
      })
}

function resolveDimensions (dimensions) {
    const aspectRatio = dimensions.width / dimensions.height
    if (aspectRatio >= 1) {
      const width = Math.min(dimensions.width, 768)
      const height = width / aspectRatio
      return { width, height }
    } else {
      const height = Math.min(dimensions.height, 768)
      const width = height * aspectRatio
      return { width, height }
    }
  }


async function imageHandler(image){
  
  return blobToBase64(image).then(base64ToImage).then(autoRotate).then(image=> {
    const { width, height } = image

    const newDimensions = resolveDimensions({ width, height })
    return resizeAndCompress(image,newDimensions)
  })
}

export default imageHandler
