import React, { Component } from 'react'
import { connect } from 'react-redux'
import rp from 'request-promise-native'
import jwt from 'jsonwebtoken'
import Loader from 'react-loader-spinner'

import { uri, auth } from '../services/defaultProps'
import * as actions from '../redux/actions'

import { FormControl, FormGroup, Button } from 'react-bootstrap'
import detectBrowserLanguage from 'detect-browser-language'
import text from '../constants/Login'

class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      fetchingLogin: false,
      fetchingReset: false,
      error: null,
      esqueceu: false,
      enviado: 0,
      language: 1 // english == 1
    }
  }

  setSpanish = () => {
    this.setState({ language: 2 })
    localStorage.setItem('language', '2')
  }

  setPt = () => {
    this.setState({ language: 0 })
    localStorage.setItem('language', '0')
  }

  setEn = () => {
    this.setState({ language: 1 })
    localStorage.setItem('language', '1')
  }

  componentDidMount () {
    if (!localStorage.getItem('language')) {
      if (detectBrowserLanguage() === 'pt-BR' || detectBrowserLanguage() === 'pt-PT' || detectBrowserLanguage() === 'pt') {
        this.setState({ language: 0 })
        localStorage.setItem('language', '0')
      }
      if (detectBrowserLanguage().slice(0, 1) === 'es') {
        this.setState({ language: 2 })
        localStorage.setItem('language', '2')
      }
    } else {
      this.setState({ language: localStorage.getItem('language') - '0' })
    }
  }

  handleEmail = (event) => this.setState({ email: event.target.value })
  handlePassword = (event) => this.setState({ password: event.target.value })
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.requestLogin()
    }
  }

  componentWillUnmount () {
    this.setState({ fetchingLogin: false })
  }

  requestLogin = () => {
    this.setState({ fetchingLogin: true })

    this.setState({ error: null })
    rp({
      uri: `${uri}/auth/login`,
      method: 'POST',
      body: {
        email: this.state.email,
        password: this.state.password
      },
      json: true
    }).then(async (loginResponse) => {
      const token = loginResponse.token.split(' ').pop()
      const userJwt = jwt.decode(token)
      if (userJwt.auth === auth.admin || userJwt.auth === auth.consultant) {
        this.props.logUser(loginResponse)
      } else {
        throw new Error(403)
      }
    })
      .catch(err => {
        console.log('Error on login', err)
        this.setState({ fetchingLogin: false, error: err.statusCode })
      })
  }

  requestPasswordReset = () => {
    if (!this.state.email) {
      this.setState({ error: 401 })
      return
    }

    this.setState({ fetchingReset: true, error: null })

    rp({
      uri: `${uri}/auth/reset/request`,
      method: 'POST',
      body: {
        email: this.state.email
      },
      json: true
    })
      .then(() => {
        this.setState({ fetchingReset: false, error: -1 })
      })
      .catch(err => {
        console.log('Error on reset password', err)
        this.setState({ fetchingReset: false, error: err.statusCode })
      })
  }

  showError = () => {
    switch (this.state.error) {
      case 400:
        return (<p style={{ margin: 10, color: '#000' }}><strong>{text.userNFound[this.state.language]}</strong></p>)
      case 401:
        return (<p style={{ margin: 10, color: '#000' }}><strong>{text.missCredentials[this.state.language]}</strong></p>)
      case 404:
        return (<p style={{ margin: 10, color: '#000' }}><strong>{text.userNFound[this.state.language]}</strong></p>)
      case -1:
        return (<p style={{ margin: 10, color: '#000' }}><strong>{text.recoveryPassword[this.state.language]}</strong></p>)
      default:
        return <div />
    }
  }

  render () {
    return (
      <div id='login'>
        <div id='loginlogo'><img src='/menulogo.png' alt='logo' /></div>
        <div id='form'>
          {!this.state.esqueceu ? <form id='loginForm' onSubmit={(e) => e.preventDefault()}>
            <span id='lg-title'>{text.acessAccount[this.state.language]}<br /></span>
            <FormGroup>
              <FormControl type='text' id='email' placeholder={text.yourMail[this.state.language]} onChange={this.handleEmail} value={this.state.email} onKeyPress={this.handleKeyPress} />
              <FormControl type='password' id='senha' placeholder={text.yourPass[this.state.language]} onChange={this.handlePassword} value={this.state.password} onKeyPress={this.handleKeyPress} /><br />
            </FormGroup>
            <a id='forgot' onClick={() => this.setState({ esqueceu: !this.state.esqueceu })}>{text.forgetPass[this.state.language]}<br /></a>
            {this.state.fetchingLogin
              ? <div id='centralSpinnerHolderWithLessPadding'>
                <Loader
                  type='TailSpin'
                  color='#000'
                  height='35'
                  width='35'
                />
              </div>
              : <input type='submit' id='submit' value='login' onClick={this.requestLogin} />}
            {this.showError()}
            <div id='lang_selection'>
              <div id='lang_flag' onClick={this.setPt}><img src='/iconfinder_flag-brazil_748057.png' alt='Portguês' /></div>
              <div id='lang_flag' onClick={this.setEn}><img src='/iconfinder_flag-united-kingdom_748024.png' alt='English' /></div>
              <div id='lang_flag' onClick={this.setSpanish}><img src='/iconfinder_flag-spain_748120.png' alt='Español' /></div>
            </div>
          </form>
            : <form id='loginForm' onSubmit={(e) => e.preventDefault()}>
              <span id='lg-title'>{text.forgotPass[this.state.language]}<br /></span>
              <FormGroup>
                <FormControl type='text' id='email' placeholder={text.yourMail[this.state.language]} onChange={this.handleEmail} value={this.state.email} onKeyPress={this.handleKeyPress} />
              </FormGroup>
              {this.state.enviado ? <p style={{ color: 'green' }}><strong>{text.sent[this.state.language]}</strong></p> : ''}

              {this.state.fetchingReset
                ? <div id='centralSpinnerHolderWithLessPadding'>
                  <Loader
                    type='TailSpin'
                    color='#000'
                    height='35'
                    width='35'
                  />
                </div>
                : <div>
                  <Button style={{ margin: 10 }} type='submit' onClick={this.requestPasswordReset}>{text.recovery[this.state.language]}</Button>
                  <Button style={{ margin: 10 }} type='submit' onClick={() => this.setState({ esqueceu: false, error: null })}>{text.back[this.state.language]}</Button>
                </div>}
              {this.showError()}
            </form>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer
  }
}

export default connect(mapStateToProps, actions)(Login)
