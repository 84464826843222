import React, { Component } from 'react'

import Calendar from 'react-calendar'
import { FormGroup, ControlLabel, FormControl, Button, ButtonToolbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import rp from 'request-promise-native'
import TimePicker from 'react-times'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'
import 'react-times/css/material/default.css'

import * as actions from '../redux/actions'
import { selectedUser } from '../services/selectors'
import { uri } from '../services/defaultProps'
import text from '../constants/Agenda'

class Agenda extends Component {
  constructor (props) {
    super(props)
    this.state = {
      scheduling: false,
      loading: false,
      currentTime: this.props.selectedUser.agenda
        ? moment(this.props.selectedUser.agenda.time).format('HH:mm')
        : moment().format('HH:mm'),
      currentDate: this.props.selectedUser.agenda
        ? moment(this.props.selectedUser.agenda.time).toDate()
        : new Date(),
      agendaPlace: this.props.selectedUser.agenda
        ? this.props.selectedUser.agenda.place
        : '',
      language: 1
    }
  }

  txt = (a) => text[a][this.state.language]

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') })
  }

    handleDate = (currentDate) => {
      this.setState({ currentDate })
    }

    onTimeChange = (newTime) => {
      this.setState({ currentTime: `${newTime.hour}:${newTime.minute}` })
    }

    onFocusChange = () => {

    }

    handleScheduling = (scheduling = true) => {
      this.setState({ scheduling })
    }

    handleUpdateAgenda = () => {
      const newDate = moment(`${moment(this.state.currentDate).format('YYYY-MM-DD')} ${this.state.currentTime}:00`).toISOString()

      this.setState({ loading: true })
      rp({
        uri: `${uri}/agenda`,
        method: 'PUT',
        headers: {
          Authorization: this.props.token
        },
        body: {
          userId: this.props.selectedUser.id,
          place: this.state.agendaPlace,
          time: newDate
        },
        json: true
      })
        .then(agenda => {
          this.props.setUserAttribute(this.props.selectedUser.id, { agenda })
          this.setState({ loading: false })
          toast(text.scheduleSucess[this.state.language], {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.props.setToken(null)
          }
          throw err
        })
        .catch(err => {
          console.log('Error when putting user agenda', err)
          this.setState({ loading: false })
          toast(text.errorCreate[this.state.language], {
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
    }

    handleDeleteAgenda = () => {
      if (!this.props.selectedUser.agenda) {
        this.handleScheduling(false)
        return
      }
      this.setState({ loading: true })
      rp({
        uri: `${uri}/agenda`,
        method: 'DELETE',
        headers: {
          Authorization: this.props.token
        },
        body: {
          userId: this.props.selectedUser.id
        },
        json: true
      })
        .then(res => {
          this.setState({
            currentTime: moment().format('HH:mm'),
            currentDate: new Date(),
            agendaPlace: '',
            loading: false
          })
          this.props.setUserAttribute(this.props.selectedUser.id, { agenda: null })
          toast(text.updateSucess[this.state.language], {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
        .catch(err => {
          console.log('Error when deleting user agenda', err)
          this.setState({ loading: false })
          this.props.setError('Erro', text.deleteError[this.state.language])
        })
    }

    render () {
      if (this.state.loading) {
        return (
          <div id='centralSpinnerHolder'>
            <Loader
              type='TailSpin'
              color='#000'
              height='50'
              width='50'
            />
          </div>
        )
      }

      return (
        <form id='agendaForm' onSubmit={(e) => e.preventDefault()}>
          {(this.props.selectedUser.agenda || this.state.scheduling)
            ? <div>
              <h3>{text.schedMeeting[this.state.language]}</h3>
              <FormGroup id='local'>
                <ControlLabel>{text.place[this.state.language]}</ControlLabel>
                <FormControl
                  type='input' placeholder=''
                  onChange={(e) => this.setState({ agendaPlace: e.target.value })}
                  value={this.state.agendaPlace}
                />
              </FormGroup>
              <FormGroup id='local'>
                <ControlLabel>{text.time[this.state.language]}</ControlLabel>
                <TimePicker
                  onFocusChange={this.onFocusChange}
                  onTimeChange={this.onTimeChange}
                  time={this.state.currentTime}
                />
              </FormGroup>
              <ControlLabel>{text.date[this.state.language]}</ControlLabel>
              <Calendar id='calendar' onChange={this.handleDate} value={this.state.currentDate} />
              <ButtonToolbar id='submitAgenda'>
                <Button bsStyle='primary' type='submit' onClick={this.handleUpdateAgenda}>{!this.props.selectedUser.agenda ? text.schedule[this.state.language] : text.update[this.state.language]} {text.nxtMeeting[this.state.language]} </Button>
                <Button bsStyle='danger' onClick={this.handleDeleteAgenda}>{text.cancel[this.state.language]}</Button>
              </ButtonToolbar>
            </div>
            : <div>
              <h3>{this.txt('notScheduled')}</h3>
              <ButtonToolbar id='submitAgenda'>
                <Button bsStyle='primary' type='submit' onClick={this.handleScheduling}>{this.txt('scheduleMeeting')}</Button>
              </ButtonToolbar>
            </div>}
        </form>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer,
    ...state.usersReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(Agenda)
