import React, { Component } from 'react'
import rp from 'request-promise-native'
import { connect } from 'react-redux'
import { Button, ButtonToolbar, Alert, FormGroup, HelpBlock, ToggleButtonGroup, Modal, ControlLabel, ToggleButton } from 'react-bootstrap'
import LightBox from 'react-image-lightbox'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'
import Slider from 'react-slick'

import { selectedUser } from '../services/selectors'
import * as actions from '../redux/actions'
import { uri, maxImageSize } from '../services/defaultProps'

import text from '../constants/Coloracao'

const images = [
  [['/temp/primaveraquente.jpeg', '/cores/en-warmspring.jpeg', '/cores/es-hotspring.jpeg'], ['/temp/primavera.jpeg', '/cores/en-spring.jpeg', '/cores/es-spring.jpeg'], ['/temp/primaveraquente_color.jpeg', '/temp/primaveraquente_color.jpeg', '/temp/primaveraquente_color.jpeg']],
  [['/temp/primaverabrilhante.jpeg', '/cores/en-brightspring.jpeg', '/cores/es-brightspring.jpeg'], ['/temp/primavera.jpeg', '/cores/en-spring.jpeg', '/cores/es-spring.jpeg'], ['/temp/primaverabrilhante_color.jpeg', '/temp/primaverabrilhante_color.jpeg', '/temp/primaverabrilhante_color.jpeg']],
  [['/temp/primaveraclara.jpeg', '/cores/en-lightspring.jpeg', '/cores/es-lightspring.jpeg'], ['/temp/primavera.jpeg', '/cores/en-spring.jpeg', '/cores/es-spring.jpeg'], ['/temp/primaveraclara_color.jpeg', '/temp/primaveraclara_color.jpeg', '/temp/primaveraclara_color.jpeg']],
  [['/temp/outonosuave.jpeg', '/cores/en-softfall.jpeg', '/cores/es-softfall.jpeg'], ['/temp/outono.jpeg', '/cores/en-fall.jpeg', '/cores/es-fall.jpeg'], ['/temp/outonosuave_color.jpeg', '/temp/outonosuave_color.jpeg', '/temp/outonosuave_color.jpeg']],
  [['/temp/outonoescuro.jpeg', '/cores/en-darkfall.jpeg', '/cores/es-darkfall.jpeg'], ['/temp/outono.jpeg', '/cores/en-fall.jpeg', '/cores/es-fall.jpeg'], ['/temp/outonoescuro_color.jpeg', '/temp/outonoescuro_color.jpeg', '/temp/outonoescuro_color.jpeg']],
  [['/temp/outonoquente.jpeg', '/cores/en-hotfall.jpeg', '/cores/es-hotfall.jpeg'], ['/temp/outono.jpeg', '/cores/en-fall.jpeg', '/cores/es-fall.jpeg'], ['/temp/outonoquente_color.jpeg', '/temp/outonoquente_color.jpeg', '/temp/outonoquente_color.jpeg']],
  [['/temp/invernobrilhante.jpeg', '/cores/en-brightwinter.jpeg', '/cores/es-brightwinter.jpeg'], ['/temp/inverno.jpeg', '/cores/en-winter.jpeg', '/cores/es-winter.jpeg'], ['/temp/invernobrilhante_color.jpeg', '/temp/invernobrilhante_color.jpeg', '/temp/invernobrilhante_color.jpeg']],
  [['/temp/invernofrio.jpeg', '/cores/en-coldwinter.jpeg', '/cores/es-coldwinter.jpeg'], ['/temp/inverno.jpeg', '/cores/en-winter.jpeg', '/cores/es-winter.jpeg'], ['/temp/invernofrio_color.jpeg', '/temp/invernofrio_color.jpeg', '/temp/invernofrio_color.jpeg']],
  [['/temp/invernoescuro.jpeg', '/cores/en-darkfall.jpeg', '/cores/es-darkfall.jpeg'], ['/temp/inverno.jpeg', '/cores/en-winter.jpeg', '/cores/es-winter.jpeg'], ['/temp/invernoescuro_color.jpeg', '/temp/invernoescuro_color.jpeg', '/temp/invernoescuro_color.jpeg']],
  [['/temp/veraofrio.jpeg', '/cores/en-coldsummer.jpeg', '/cores/es-coldsummer.jpeg'], ['/temp/verao.jpeg', '/cores/en-summer.jpeg', '/cores/es-summer.jpeg'], ['/temp/veraofrio_color.jpeg', '/temp/veraofrio_color.jpeg', '/temp/veraofrio_color.jpeg']],
  [['/temp/veraoclaro.jpeg', '/cores/en-lightsummer.jpeg', '/cores/es-lightsummer.jpeg'], ['/temp/verao.jpeg', '/cores/en-summer.jpeg', '/cores/es-summer.jpeg'], ['/temp/veraoclaro_color.jpeg', '/temp/veraoclaro_color.jpeg', '/temp/veraoclaro_color.jpeg']],
  [['/temp/veraosuave.jpeg', '/cores/en-softsummer.jpeg', '/cores/es-softsummer.jpeg'], ['/temp/verao.jpeg', '/cores/en-summer.jpeg', '/cores/es-summer.jpeg'], ['/temp/veraosuave_color.jpeg', '/temp/veraosuave_color.jpeg', '/temp/veraosuave_color.jpeg']]
]

class Coloracao extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLightBoxOpen: false,
      photoIndex: 0,
      loading: false,
      currentPersonalColoring: this.props.selectedUser.personalColoring,
      newSelectedColoring: this.props.selectedUser.personalColoring,
      isSelectingNewColoring: this.props.selectedUser.personalColoring === null && (this.props.selectedUser.media.userPersonalColor === null || this.props.selectedUser.media.userPersonalColor.length === 0),
      language: 1,
      selectedType: 'collection',
      page: 0,
      imagesToUpload: null,
      loadingUpload: false,
      deletingImage: null
    }
  }

  componentWillMount () {
    this.setState({ language: localStorage.getItem('language') - '0' })
  }

  handleUpdateColoring = () => {
    switch (this.state.selectedType) {
      case 'collection':
        const personalColoring = this.state.newSelectedColoring
        if (personalColoring >= 1 && personalColoring <= 12) {
          this.setState({ loading: true })
          rp({
            uri: `${uri}/media/personalcoloring/assign`,
            method: 'POST',
            headers: {
              Authorization: this.props.token
            },
            body: {
              userId: this.props.selectedUser.id,
              personalColoring: personalColoring
            },
            json: true
          })
            .then(personalColoring => {
              this.props.setUserAttribute(this.props.selectedUser.id, personalColoring)

              this.props.setUserMediaAttribute(this.props.selectedUser.id, {
                userPersonalColor: []
              })
              this.setState({
                currentPersonalColoring: personalColoring.personalColoring,
                newSelectedColoring: personalColoring.personalColoring,
                isSelectingNewColoring: false,
                loading: false
              })
              toast(text.updatedSuc[this.state.language], {
                type: toast.TYPE.INFO,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
            .catch(err => {
              if (err.statusCode === 401) {
                this.props.setToken(null)
              }
              throw err
            })
            .catch(err => {
              console.log('Error updating personal coloring', err)
              this.setState({ loading: false })
              toast(text.updateError[this.state.language], {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
        }
        break
      case 'img':
        console.log('imageee')
        if (this.state.imagesToUpload.length > 0) {
          this.setState({ loadingUpload: true })

          Promise.all(this.state.imagesToUpload.map(imageToUpload => {
            return fetch(`${uri}/media/personalcoloring/image?id=${this.props.selectedUser.id}`, {
              method: 'POST',
              headers: {
                'content-type': imageToUpload.type,
                Authorization: this.props.token
              },
              body: imageToUpload
            })
              .then(res => {
                if (!res.ok && res.status === 401) {
                  this.props.setToken(null)
                  return res.json().then((err) => Promise.reject(err)) // eslint-disable-line prefer-promise-reject-errors
                } else {
                  return res
                }
              })
              .then(res => res.json())
              .then(userPersonalColor => {
                this.props.setUserMediaAttribute(this.props.selectedUser.id, userPersonalColor)
                this.props.setUserAttribute(this.props.selectedUser.id, { personalColoring: null })
                toast(text.updatedSuc[this.state.language], {
                  type: toast.TYPE.INFO,
                  position: toast.POSITION.BOTTOM_RIGHT
                })
              })
          }))
            .then(() => {
              this.setState({ loadingUpload: false, imagesToUpload: [], isSelectingNewColoring: false, currentPersonalColoring: null })
            })
            .catch(err => {
              console.log('Error posting personal color image', err)
              this.setState({ loadingUpload: false, imagesToUpload: [] })
              toast(text.uploadError[this.state.language], {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_RIGHT
              })
            })
        }
        break
    }
  }

  renderMenu = () => (
    <FormGroup id='radioTextOrImage'>
      <ToggleButtonGroup type='radio' name='textOrImage' defaultValue={this.state.selectedType} onChange={(selectedType) => this.setState({ selectedType })}>
        <ToggleButton value='collection'>{text.colection[this.state.language]}</ToggleButton>
        <ToggleButton value='img'>{text.imgs[this.state.language]}</ToggleButton>
      </ToggleButtonGroup>
    </FormGroup>
  )

  handleModalDel = (id) => {
    this.setState({ deletingImage: id })
  }

  handleDeleteClick = () => {
    if (this.state.deletingImage) {
      this.setState({ loadingDelete: true })
      rp({
        uri: `${uri}/media/personalcoloring/image`,
        method: 'DELETE',
        headers: {
          Authorization: this.props.token
        },
        body: {
          id: this.state.deletingImage
        },
        json: true
      })
        .then(() => {
          this.props.setUserMediaAttribute(this.props.selectedUser.id, {
            userPersonalColor: this.props.selectedUser.media.userPersonalColor.filter(personalColorImage => personalColorImage.id !== this.state.deletingImage)
          })
          this.setState({ loadingDelete: false, deletingImage: null })
          toast(text.deleteSuc[this.state.language], {
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.props.setToken(null)
          }
          throw err
        })
        .catch(err => {
          console.log('Error deleting personal color image', err)
          this.setState({ loadingDelete: false, deletingImage: null })
        })
    }
  }

  handleUploadImage = (event) => {
    const imagesToUpload = []
    for (let i = 0; i < event.target.files.length; i++) {
      const imageToUpload = event.target.files[i]
      const type = imageToUpload.type.split('/').pop()
      // Size in MB
      const size = imageToUpload.size / (1024 * 1024)
      if (size > maxImageSize) {
        toast(text.sizeError[this.state.language], {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT
        })
        return
      }
      // Unwanted type
      if (!['jpeg', 'png'].includes(type)) {
        this.props.setError(text.error[this.state.language], text.wrongFormat[this.state.language])
        return
      }

      imagesToUpload.push(imageToUpload)
    }

    this.setState({ imagesToUpload })
  }

  collectionOrUpload = () => {
    switch (this.state.selectedType) {
      case 'collection':
        return <div id='colorOptions'>
          {
            text.seasons[this.state.language].map((coloring, index) => {
              const coloringId = index + 1
              const coloringNames = coloring.split(' ')
              return [
                <input
                  id={`radio_${coloringId}`}
                  key={coloringId}
                  type='radio'
                  name='coloracao'
                  value={coloringId}
                  className='coloracaoRadio'
                  defaultChecked={coloringId === this.state.newSelectedColoring}
                  onClick={() => this.setState({ newSelectedColoring: coloringId })}
                />,
                <label key={coloringId + text.seasons[this.state.language].length} htmlFor={`radio_${coloringId}`}>
                  <div className='coloracaoOpt' id={`coloring${coloringId}`}>
                    <span>{coloringNames[0]} <br /> {coloringNames[1]}</span>
                  </div>
                </label>
              ]
            })
          }
          <Button
            type='submit'
            id='formSubmit'
            bsStyle='primary'
            onClick={this.handleUpdateColoring}
            disabled={!(this.state.newSelectedColoring >= 1 && this.state.newSelectedColoring <= 12)}
          >{text.updateColor[this.state.language]}
          </Button>
        </div>
      case 'img':
        const personalColorPic = this.props.selectedUser.media.userPersonalColor
        const settings = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (index) => this.setState({ page: index })
        }
        return <div id='personalColorImage'>
          <div>
            <Slider {...settings}>
              {personalColorPic && personalColorPic.length ? personalColorPic.map((item, index) => <div key={index} className='sliderItem'>
                <img src={item.image} id='sliderImage' alt={`Imagem ${index}`} /> <br />
                <Button bsStyle='danger' bsSize='small' className='delFoto' onClick={() => this.handleModalDel(item.id)}>Deletar Foto</Button>
                                                                                                   </div>)
                : <div><h3>{text.noImage[this.state.language]}</h3></div>}
            </Slider>
            {personalColorPic && personalColorPic.length ? <p id='page'>Foto {this.state.page + 1} de {personalColorPic.length}</p> : ''}
            <Modal show={this.state.deletingImage !== null} onHide={() => this.handleModalDel(null)}>
              <Modal.Header closeButton>
                <Modal.Title>{text.confim[this.state.language]}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.loadingDelete
                  ? <div id='centralSpinnerHolderWithLessPadding'>
                    <Loader
                      type='TailSpin'
                      color='#000'
                      height='50'
                      width='50'
                    />
                    </div>
                  : <p>{text.deleteConfirm[this.state.language]}</p>}
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle='danger' onClick={this.handleDeleteClick}>{text.delete[this.state.language]}</Button>
              </Modal.Footer>
            </Modal>
            {this.state.loadingUpload
              ? <div id='centralSpinnerHolderWithLessPadding'>
                <Loader
                  type='TailSpin'
                  color='#000'
                  height='50'
                  width='50'
                />
                </div>
              : <div style={{ display: 'inline-block' }}>
                <ControlLabel>{text.chooseImage[this.state.language]}</ControlLabel>
                <input style={{ maxWidth: 180, margin: '25px auto' }} type='file' accept='image/*' onChange={this.handleUploadImage} multiple />
                <HelpBlock>{text.resolution[this.state.language]}</HelpBlock>
                </div>}
          </div>
          <Button
            type='submit'
            id='formSubmit'
            bsStyle='primary'
            onClick={this.handleUpdateColoring}
            disabled={!(this.state.imagesToUpload)}
          >{text.updateColor[this.state.language]}
          </Button>
        </div>
    }
  }

  render () {
    if (this.state.loading) {
      return (
        <div id='centralSpinnerHolder'>
          <Loader
            type='TailSpin'
            color='#000'
            height='50'
            width='50'
          />
        </div>
      )
    }

    if (this.state.isSelectingNewColoring) {
      return (
        <form className='clientForm' onSubmit={(e) => e.preventDefault()}>
          <div style={{ width: '90%', margin: 'auto' }}>
            <Alert bsStyle='info'>
              <div style={{ textAlign: 'left', paddingHorizontal: 10 }}>
                <div> {text.descriptio[this.state.language]}
                </div>
              </div>
            </Alert>
          </div>
          {this.renderMenu()}
          {this.collectionOrUpload()}
        </form>
      )
    } else {
      const { photoIndex } = this.state
      const selectedImage = this.props.selectedUser.personalColoring ? images[this.state.currentPersonalColoring - 1] : null
      const personalColorPic = this.props.selectedUser.media.userPersonalColor
      return (
        <div>
          <h3 id='coloracaoAtual'>{text.currentColor[this.state.language] + ' ' + (selectedImage ? text.seasons[this.state.language][this.state.currentPersonalColoring - 1] : text.imgsSelected[this.state.language])}</h3>
          <ButtonToolbar style={this.state.language === 2 ? { width: '335px', margin: '0px auto 40px auto' } : { width: '280px', margin: '0px auto 40px auto' }}>
            <Button onClick={() => this.setState({ isLightBoxOpen: true })}>{text.seeColoring[this.state.language]}</Button>
            {this.state.isLightBoxOpen
              ? selectedImage
                ? <LightBox
                  mainSrc={selectedImage[photoIndex][this.state.language]}
                  nextSrc={selectedImage[(photoIndex + 1) % selectedImage.length][this.state.language]}
                  prevSrc={selectedImage[(photoIndex + selectedImage.length - 1) % selectedImage.length][this.state.language]}
                  onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex - 1) % selectedImage.length
                    })}
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % selectedImage.length
                    })}
                />
                : <LightBox
                  mainSrc={personalColorPic[photoIndex].image}
                  nextSrc={personalColorPic[(photoIndex + 1) % personalColorPic.length].image}
                  prevSrc={personalColorPic[(photoIndex + personalColorPic.length - 1) % personalColorPic.length].image}
                  onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex - 1) % personalColorPic.length
                    })}
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % personalColorPic.length
                    })}
                /> : ''}
            <Button bsStyle='primary' onClick={() => this.setState({ isSelectingNewColoring: true })}>{text.changeColoring[this.state.language]}</Button>
          </ButtonToolbar>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.adminReducer,
    ...state.usersReducer,
    selectedUser: selectedUser(state)
  }
}

export default connect(mapStateToProps, actions)(Coloracao)
